<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { RouterView, useRoute, useRouter } from "vue-router";
import NavigationDrawer from "@/containers/NavigationDrawer.vue";

import { useAuthStore } from "@/stores/auth";
import { useAccountsStore } from "@/stores/accounts";
import { useActivitiesStore } from "@/stores/activities";
import { useTransactionsStore } from "@/stores/transactions";
import { useMovementsStore } from "@/stores/movements";
import { useBudgetsStore } from "@/stores/budgets";
import { useSettingsStore } from "@/stores/settings";
import { useContactsStore } from "@/stores/contacts";

import { clearStorage } from "@/features/storage";
import { connectDb } from "@/features/indexeddb";
import { useProjectsStore } from "./stores/projects";

const FULLSCREEN_ROUTES = [
  "login",
  "signup",
  "callback",
  "onboarding",
  "not-found",
];

const route = useRoute();
const router = useRouter();

const { settings } = storeToRefs(useSettingsStore());

const loading = ref(true);

const loadAuth = async () => {
  const authStore = useAuthStore();
  const user = await authStore.loadUser();

  await router.isReady();

  if (!user) {
    if (!["login", "signup"].includes(route.name as string)) {
      await router.replace({ name: "login" });
    }
    await clearStorage();
  } else {
    await useSettingsStore().load();
    if (!settings.value.onboarded) {
      await router.replace({ name: "onboarding" });
    } else {
      if (
        ["login", "signup", "callback", "onboarding"].includes(
          route.name as string
        )
      ) {
        await router.replace({ name: "dashboard" });
      }
    }

    await connectDb();
    await Promise.all([
      useContactsStore().load(),
      useAccountsStore().load(),
      useActivitiesStore().load(),
      useTransactionsStore().load(),
      useMovementsStore().load(),
      useBudgetsStore().load(),
      useProjectsStore().load(),
    ]);
  }

  loading.value = false;
};

loadAuth();
</script>

<template>
  <Transition
    enter-active-class="transition-opacity duration-150"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-75 absolute"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div v-if="!loading" class="flex h-full w-full">
      <NavigationDrawer
        v-if="!FULLSCREEN_ROUTES.includes(route.name as string)"
      />

      <main
        class="flex-1 bg-white text-slate-800 flex flex-col w-full min-w-0 relative overflow-y-auto"
      >
        <RouterView v-slot="{ Component }">
          <component :is="Component" />
        </RouterView>
      </main>
    </div>
    <div
      v-else
      class="flex h-full w-full items-center justify-center text-slate-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        stroke="currentColor"
      >
        <g
          fill="none"
          fill-rule="evenodd"
          transform="translate(1 1)"
          stroke-width="2"
        >
          <circle cx="22" cy="22" r="6" stroke-opacity="0">
            <animate
              attributeName="r"
              begin="1.5s"
              dur="3s"
              values="6;22"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="1.5s"
              dur="3s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="1.5s"
              dur="3s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="6" stroke-opacity="0">
            <animate
              attributeName="r"
              begin="3s"
              dur="3s"
              values="6;22"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="3s"
              dur="3s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="3s"
              dur="3s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="8">
            <animate
              attributeName="r"
              begin="0s"
              dur="1.5s"
              values="6;1;2;3;4;5;6"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
    </div>
  </Transition>
</template>
