<script setup lang="ts">
import { useProjectsStore } from "@/stores/projects";
import { computed } from "vue";

const projectsStore = useProjectsStore();

const props = defineProps<{
  projectId: number;
}>();

const project = computed(() => projectsStore.getProjectById(props.projectId));
</script>

<template>
  <div v-if="project" class="flex items-center">
    <TDatePicker v-slot="{ open, value }" v-model="project.start_date">
      <div
        class="flex items-center px-2 rounded w-24"
        :class="open ? 'bg-slate-100' : 'hover:bg-slate-100'"
      >
        <i class="mdi mr-2 mt-1 text-slate-600 mdi-calendar" />
        <div
          class="text-sm whitespace-nowrap"
          :class="value ? 'text-slate-600' : 'text-slate-400'"
        >
          {{ value?.format("MMM DD") ?? "Start date" }}
        </div>

        <i
          v-if="open"
          class="mdi mdi-close text-slate-400 hover:text-slate-600 ml-2 mt-0.5"
          @click.prevent="
            project!.start_date = null;
            project!.end_date = null;
          "
        />
      </div>
    </TDatePicker>

    <i class="mdi mdi-arrow-right-thin text-slate-400 mt-1 mx-1" />

    <TDatePicker
      v-slot="{ open, value, disabled }"
      v-model="project.end_date"
      :disabled="project.start_date === null"
    >
      <div
        class="flex items-center px-2 rounded w-24"
        :class="[
          open ? 'bg-slate-100' : '',
          disabled ? '' : 'hover:bg-slate-100',
        ]"
      >
        <i
          class="mdi mr-2 mt-1 mdi-calendar"
          :class="disabled ? 'text-slate-300' : 'text-slate-600'"
        />
        <div
          class="text-sm whitespace-nowrap"
          :class="{
            'text-slate-600': value,
            'text-slate-400': !disabled && !value,
            'text-slate-300': disabled,
          }"
        >
          {{ value?.format("MMM DD") ?? "End date" }}
        </div>

        <i
          v-if="open"
          class="mdi mdi-close text-slate-400 hover:text-slate-600 ml-2 mt-0.5"
          @click.prevent="project!.end_date = null"
        />
      </div>
    </TDatePicker>
  </div>
</template>
