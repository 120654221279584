<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useMotion } from "@vueuse/motion";

import { useViewsStore } from "@/stores/views";
import { useProjectsStore } from "@/stores/projects";
import { useActivitiesStore } from "@/stores/activities";
import { useHeadStore } from "@/stores/head";

import ActivitiesTable from "@/containers/activities/ActivitiesTable.vue";
import FilterActivitiesButton from "@/containers/activities/filters/FilterActivitiesButton.vue";
import SearchBar from "@/containers/SearchBar.vue";
import ProjectActivitiesSummary from "@/containers/projects/ProjectActivitiesSummary.vue";
import ProjectDates from "@/containers/projects/ProjectDates.vue";
import AddAndEditProjectModal from "@/containers/projects/AddAndEditProjectModal.vue";
import ExportActivitiesButton from "@/containers/activities/ExportActivitiesButton.vue";

import ShowTransactionsButton from "@/components/activities/ShowTransactionsButton.vue";

const router = useRouter();
const route = useRoute();

const viewsStore = useViewsStore();

const projectsStore = useProjectsStore();
const { viewFilters } = storeToRefs(projectsStore);

const activitiesStore = useActivitiesStore();
const { activitiesData, focusedActivity } = storeToRefs(activitiesStore);

const showEditModal = ref(false);

const project = computed(() => {
  return projectsStore.getProjectById(parseInt(route.params.id as string));
});

watch(
  () => route.params.period,
  () => {
    if (route.name !== "project") return;

    if (!project.value) {
      router.replace({ name: "projects" });
      return;
    }

    useHeadStore().updateTitle(project.value.name);
  },
  { immediate: true }
);

const activityView = computed(() => {
  if (!project.value) return;
  return viewsStore.getActivityView(`project-${project.value.id}-page`);
});

const projectActivitiesData = computed(() => {
  if (!project.value) return;
  return activitiesData.value.filter(
    (activityData) => activityData.activity.project === project.value!.id
  );
});

const handleProjectMenuClick = (event: string) => {
  if (event === "delete") {
    projectsStore.deleteProject(project.value!.id);
    router.push({ name: "projects" });
  } else if (event === "edit") {
    showEditModal.value = true;
  }
};

const resetFilters = () => {
  viewFilters.value = {
    category: null,
    subcategory: null,
    activityType: null,
  };
};

onBeforeUnmount(() => {
  resetFilters();
});

// Drawer

const drawerElement = ref<HTMLElement>();
const isDrawerOpen = ref(window.innerWidth > 420);

const toggleDrawer = () => {
  if (isDrawerOpen.value) {
    useMotion(drawerElement, {
      initial: {
        marginLeft: 0,
        x: 0,
      },
      enter: {
        marginLeft: -460,
        x: 460,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });

    setTimeout(() => {
      isDrawerOpen.value = false;
    }, 100);
  } else {
    isDrawerOpen.value = true;
    useMotion(drawerElement, {
      initial: {
        marginLeft: -460,
        x: 460,
      },
      enter: {
        marginLeft: 0,
        x: 0,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });
  }
};
</script>

<template>
  <template v-if="project">
    <header
      class="h-14 border-b border-b-gray-200 pl-14 pr-4 lg:pl-8 flex items-center"
    >
      <div class="text-sm mr-3">{{ project.emoji }}</div>
      <div class="text-sm font-medium truncate mr-4">
        {{ project.name }}
      </div>

      <div class="flex-1 sm:hidden" />
      <template v-if="activityView!.filters.length === 0">
        <FilterActivitiesButton :view-id="activityView!.id" class="mx-2" />
        <ExportActivitiesButton
          class="hidden sm:block"
          :view-id="activityView!.id"
          :activities-data="projectActivitiesData!"
        />
      </template>

      <div class="flex-1 hidden sm:block" />
      <SearchBar />

      <ShowTransactionsButton />

      <div class="mx-3 w-[1px] bg-gray-200 h-6 hidden sm:block" />

      <TTooltip text="Show period side panel">
        <button
          type="button"
          class="ml-2 sm:ml-0 inline-flex items-center justify-center transition text-slate-600 hover:text-slate-700 hover:bg-slate-100 rounded h-7 w-7"
          :class="isDrawerOpen ? 'bg-slate-100 text-slate-900' : 'bg-white'"
          @click="toggleDrawer"
        >
          <i class="mdi mdi-view-split-vertical text-lg" />
        </button>
      </TTooltip>
    </header>

    <div class="flex flex-1 w-full overflow-x-hidden">
      <ActivitiesTable
        class="min-w-0"
        :activities-data="projectActivitiesData!"
        :category-filter="viewFilters.category"
        :subcategory-filter="viewFilters.subcategory"
        :activity-type-filter="viewFilters.activityType"
        :view-id="activityView!.id"
        hide-project
      />

      <div
        v-if="isDrawerOpen"
        ref="drawerElement"
        class="relative flex flex-col items-center w-full shrink-0 sm:max-w-[420px] h-full border-l shadow-xl overflow-y-auto pb-10"
      >
        <div class="border-b p-6 w-full flex items-center">
          <div class="text-lg text-slate-700 font-bold flex items-center">
            <TEmojiPicker
              v-model="project.emoji"
              class="mr-3"
              placeholder="mdi-book-multiple"
            />
            {{ project.name }}
          </div>

          <div class="flex-1" />
          <TMenu
            class="mr-2 sm:mr-0"
            :items="[
              { value: 'edit', text: 'Edit project', icon: 'pencil' },
              { value: 'delete', text: 'Delete project', icon: 'delete' },
            ]"
            @click:item="handleProjectMenuClick($event)"
          />
        </div>

        <div class="border-b p-6 w-full">
          <div class="flex items-center justify-between">
            <div class="text-slate-400 text-sm font-medium">Dates</div>
            <ProjectDates :project-id="project.id" />
          </div>
        </div>

        <ProjectActivitiesSummary
          :project-activities-data="projectActivitiesData!"
        />
      </div>
    </div>

    <AddAndEditProjectModal v-model="showEditModal" :project-id="project.id" />
  </template>
</template>
