<script setup lang="ts">
import { ref } from "vue";
import type { Dayjs } from "dayjs";
import { storeToRefs } from "pinia";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from "@headlessui/vue";

import { useBudgetsStore } from "@/stores/budgets";
import { ACTIVITY_TYPES_NAME, useActivitiesStore } from "@/stores/activities";
import { ActivityType } from "@/types/activities";

const { addCategoryBudget, addActivityTypeBudget } = useBudgetsStore();

const activitiesStore = useActivitiesStore();
const { categories } = storeToRefs(activitiesStore);

const addNewBudgetDialog = ref({
  show: false,
  step: 0,
  budgetType: undefined as "activityType" | "category" | undefined,
  value: undefined as number | undefined,
  category: undefined as number | undefined,
  activityType: undefined as ActivityType | undefined,
  period: undefined as Dayjs | undefined,
  accumulating: false,
});

const resetAddNewBudgetDialog = () => {
  addNewBudgetDialog.value = {
    show: false,
    step: 0,
    budgetType: undefined,
    value: undefined,
    category: undefined,
    activityType: undefined,
    period: undefined,
    accumulating: false,
  };
};

const addNewBudget = async () => {
  if (addNewBudgetDialog.value.value === undefined) return;
  else if (addNewBudgetDialog.value.period === undefined) return;

  if (addNewBudgetDialog.value.budgetType === "category") {
    if (addNewBudgetDialog.value.category === undefined) return;

    await addCategoryBudget(
      addNewBudgetDialog.value.category,
      addNewBudgetDialog.value.value,
      addNewBudgetDialog.value.period.month(),
      addNewBudgetDialog.value.period.year(),
      addNewBudgetDialog.value.accumulating
    );
  } else if (addNewBudgetDialog.value.budgetType === "activityType") {
    if (addNewBudgetDialog.value.activityType === undefined) return;

    await addActivityTypeBudget(
      addNewBudgetDialog.value.activityType,
      addNewBudgetDialog.value.value,
      addNewBudgetDialog.value.period.month(),
      addNewBudgetDialog.value.period.year(),
      addNewBudgetDialog.value.accumulating
    );
  }

  resetAddNewBudgetDialog();
};

const open = () => {
  addNewBudgetDialog.value.show = true;
};
</script>

<template>
  <button
    type="button"
    class="inline-flex items-center justify-center transition text-slate-600 hover:text-slate-900 bg-white hover:bg-slate-100 border border-gray-300 rounded px-2.5 h-7"
    @click="open"
  >
    <i class="mdi mdi-plus text-base" />
    <span class="text-slate-700 ml-2 font-medium text-xs mr-2">
      Add budget
    </span>
  </button>

  <TransitionRoot appear :show="addNewBudgetDialog.show" as="template">
    <Dialog as="div" class="relative z-50" @close="resetAddNewBudgetDialog">
      <TransitionChild
        as="template"
        enter="duration-100 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-100 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 backdrop-blur-sm bg-slate-100/20" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-100 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-lg transition-all border"
            >
              <DialogTitle as="div" class="flex border-b pb-4 pt-5 px-8">
                <div class="text-slate-800 font-medium">Add a new budget</div>
                <div class="flex-1" />
                <button
                  class="inline-flex items-center justify-center w-6 h-6 transition text-slate-500 hover:text-slate-900 min-w-6 shrink-0"
                  @click="resetAddNewBudgetDialog"
                >
                  <i class="mdi mdi-close text-lg" />
                </button>
              </DialogTitle>

              <template v-if="addNewBudgetDialog.step === 0">
                <div class="px-8 py-6">
                  <div class="text-slate-600 text-sm mb-4">
                    Choose a type of budget you want to create.
                  </div>
                  <RadioGroup v-model="addNewBudgetDialog.budgetType">
                    <RadioGroupLabel class="sr-only">
                      Budget type
                    </RadioGroupLabel>
                    <div class="space-y-2">
                      <RadioGroupOption
                        v-for="budgetType in [
                          {
                            name: 'Activity Type',
                            value: 'activityType',
                            description:
                              'Create a budget for Expenses, Revenues or Investments. This allows you to dispatch your activities into any category freely.',
                          },
                          {
                            name: 'Category',
                            value: 'category',
                            description:
                              'Create a budget for a category to keep a precise target of what you spend / ingest inside some categories accross periods.',
                          },
                        ]"
                        :key="budgetType.value"
                        v-slot="{ active, checked }"
                        as="template"
                        :value="budgetType.value"
                      >
                        <div
                          :class="[
                            active || checked
                              ? 'border-indigo-300'
                              : 'border-gray-200',
                          ]"
                          class="flex rounded px-5 py-4 focus:outline-none border transition-colors"
                        >
                          <div class="flex w-full items-center justify-between">
                            <div>
                              <RadioGroupLabel
                                as="div"
                                class="font-medium text-sm"
                                :class="
                                  checked ? 'text-indigo-500' : 'text-slate-700'
                                "
                              >
                                {{ budgetType.name }}
                              </RadioGroupLabel>
                              <RadioGroupDescription
                                as="div"
                                class="text-slate-500 mt-2 text-xs"
                              >
                                {{ budgetType.description }}
                              </RadioGroupDescription>
                            </div>
                          </div>
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                </div>

                <div class="border-t px-8 py-4 flex justify-end">
                  <TBtn
                    :disabled="addNewBudgetDialog.budgetType === undefined"
                    @click="addNewBudgetDialog.step += 1"
                  >
                    Next
                  </TBtn>
                </div>
              </template>
              <template v-else-if="addNewBudgetDialog.step === 1">
                <div class="px-8 py-6">
                  <div
                    v-if="addNewBudgetDialog.budgetType === 'category'"
                    class="flex flex-col sm:flex-row sm:items-center text-sm"
                  >
                    <div class="text-sm text-slate-400">Category</div>
                    <div class="flex-1" />
                    <TSelect
                      v-model="addNewBudgetDialog.category"
                      :items="categories"
                      item-value="id"
                      item-text="name"
                      class="w-full sm:w-56 mt-2 sm:mt-0"
                    />
                  </div>
                  <div
                    v-else-if="addNewBudgetDialog.budgetType === 'activityType'"
                    class="flex flex-col sm:flex-row sm:items-center text-sm"
                  >
                    <div class="text-sm text-slate-400">Activity type</div>
                    <div class="flex-1" />
                    <TSelect
                      v-model="addNewBudgetDialog.activityType"
                      :items="[
                        {
                          value: ActivityType.REVENUE,
                          text: ACTIVITY_TYPES_NAME[ActivityType.REVENUE],
                        },
                        {
                          value: ActivityType.EXPENSE,
                          text: ACTIVITY_TYPES_NAME[ActivityType.EXPENSE],
                        },
                        {
                          value: ActivityType.INVESTMENT,
                          text: ACTIVITY_TYPES_NAME[ActivityType.INVESTMENT],
                        },
                      ]"
                      class="w-full sm:w-56 mt-2 sm:mt-0"
                    />
                  </div>

                  <div
                    class="mt-4 flex flex-col sm:flex-row sm:items-center text-sm"
                  >
                    <div class="text-sm text-slate-400">Amount</div>
                    <div class="flex-1" />
                    <TAmountInput
                      v-model="addNewBudgetDialog.value"
                      class="w-full sm:w-56 mt-2 sm:mt-0"
                    />
                  </div>

                  <div
                    class="mt-4 flex flex-col sm:flex-row sm:items-center text-sm"
                  >
                    <div class="text-sm text-slate-400">Period</div>
                    <div class="flex-1" />
                    <TPeriodPicker
                      v-model="addNewBudgetDialog.period"
                      class="w-full sm:w-56 mt-2 sm:mt-0"
                    />
                  </div>

                  <div
                    class="mt-4 flex flex-col sm:flex-row sm:items-center text-sm py-2"
                  >
                    <div class="text-sm text-slate-400">Accumulating</div>
                    <div class="flex-1" />
                    <input
                      v-model="addNewBudgetDialog.accumulating"
                      name="budget-check-accumulating"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:outline-none mt-2 sm:mt-0"
                    />
                  </div>
                </div>

                <div class="border-t px-8 py-4 flex justify-end">
                  <TBtn
                    :disabled="
                      addNewBudgetDialog.value === undefined ||
                      addNewBudgetDialog.period === undefined ||
                      (addNewBudgetDialog.budgetType === 'activityType' &&
                        addNewBudgetDialog.activityType === undefined) ||
                      (addNewBudgetDialog.budgetType === 'category' &&
                        addNewBudgetDialog.category === undefined)
                    "
                    @click="addNewBudget"
                  >
                    Create budget
                  </TBtn>
                </div>
              </template>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
