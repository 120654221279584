<script setup lang="ts">
import dayjs from "dayjs";
import { computed, ref } from "vue";

import { useMovementsStore } from "@/stores/movements";

import LinkMovementButton from "@/containers/movements/LinkMovementButton.vue";

import AccountLabel from "@/components/AccountLabel.vue";
import TAmountInput from "@/components/designSystem/TAmountInput.vue";

import { getCurrencyFormatter } from "@/utils/currency";

import {
  getActivityMovementsReconciliated,
  getActivityMovementsReconciliatedByAccount,
} from "@/features/activities/reconciliation";
import type { MovementWithLink } from "@/types/movements";
import type { ActivityData } from "@/types/activities";

const movementsStore = useMovementsStore();

const props = defineProps<{ activityData: ActivityData }>();

const showMovements = ref(true);
const movementsAmountInputs = ref<
  Record<number, InstanceType<typeof TAmountInput> | null>
>({});

const movementsReconciliatedByAccount = computed(() => {
  return getActivityMovementsReconciliatedByAccount(
    props.activityData.activity
  );
});

const handleMovementMenuClick = (
  movementWithLink: MovementWithLink,
  event: string
) => {
  if (event === "unlink") {
    movementsStore.deleteMovementActivity(
      movementWithLink.movementActivityId,
      false
    );
  } else if (event === "resetAmount") {
    const movementActivity = movementsStore.getMovementActivityById(
      movementWithLink.movementActivityId
    );
    if (!movementActivity) return;

    movementActivity.amount = movementWithLink.amount;
  } else if (event === "editAmount") {
    movementsAmountInputs.value[movementWithLink.movementActivityId]?.click();
  }
};

const updateAmountLinked = (movementActivityId: number, newAmount: number) => {
  const movementActivity =
    movementsStore.getMovementActivityById(movementActivityId);
  if (!movementActivity) return;

  movementActivity.amount = newAmount;
};
</script>
<template>
  <div class="border-b py-6 px-4 sm:px-8">
    <div class="flex items-center">
      <div
        class="-ml-2 text-sm font-medium text-slate-800 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
        @click="showMovements = !showMovements"
      >
        <i
          v-if="!getActivityMovementsReconciliated(activityData.activity)"
          class="mdi mdi-progress-helper text-xl text-orange-300 mr-2"
        />
        <i v-else class="mdi mdi-check-circle text-xl text-emerald-400 mr-2" />
        Movements
        <i
          class="mdi ml-2"
          :class="showMovements ? 'mdi-menu-down' : 'mdi-menu-up'"
        />
      </div>
    </div>

    <div v-show="showMovements">
      <div
        v-for="movementsReconciliatedOfAccount in movementsReconciliatedByAccount"
        :key="movementsReconciliatedOfAccount.account"
        class="mt-4 mb-2 border rounded py-4 -mx-3 px-3 sm:-mx-4 sm:px-4"
      >
        <div class="flex items-center">
          <AccountLabel
            :account-id="movementsReconciliatedOfAccount.account"
            class="text-sm ml-0.5"
          />

          <div class="flex-1" />

          <div
            class="whitespace-nowrap ml-3 font-medium text-xs mr-4"
            :class="
              !movementsReconciliatedOfAccount.reconcilied
                ? 'text-red-400'
                : 'text-slate-400'
            "
          >
            {{
              getCurrencyFormatter().format(
                movementsReconciliatedOfAccount.movementTotal
              )
            }}
            /
            {{
              getCurrencyFormatter().format(
                movementsReconciliatedOfAccount.transactionTotal
              )
            }}
          </div>

          <LinkMovementButton
            :activity-data="activityData"
            :account="movementsReconciliatedOfAccount.account"
          />
        </div>

        <div class="mt-4 mb-2">
          <template
            v-for="movement in movementsReconciliatedOfAccount.movements"
            :key="movement.id"
          >
            <hr class="border-t border-t-gray-200 -mx-2 px-2" />

            <div
              class="h-10 flex items-center justify-center text-sm hover:bg-slate-50 -mx-2 px-2"
            >
              <div class="hidden sm:block text-slate-600 w-16 mr-4 shrink-0">
                {{ dayjs(movement.date).format("DD/MM/YYYY") }}
              </div>
              <div
                class="ml-1 text-slate-700 text-ellipsis overflow-hidden whitespace-nowrap"
              >
                {{ movement.name }}
                <span
                  v-if="movement.amountLinked !== movement.amount"
                  class="text-xs text-slate-500 ml-2 whitespace-nowrap"
                >
                  ({{ getCurrencyFormatter().format(movement.amount) }})
                </span>
              </div>

              <div class="flex-1" />

              <TAmountInput
                :ref="
                    (el) =>
                      (movementsAmountInputs[movement.movementActivityId] = el as InstanceType<typeof TAmountInput>)
                  "
                :model-value="movement.amountLinked"
                borderless
                class="mr-4"
                @update:model-value="
                  updateAmountLinked(movement.movementActivityId, $event)
                "
              />

              <TMenu
                :items="[
                  {
                    value: 'editAmount',
                    text: 'Modify amount linked',
                    icon: 'book-edit',
                  },
                  {
                    value: 'resetAmount',
                    text: 'Reset amount linked',
                    icon: 'book-refresh',
                  },
                  {
                    value: 'unlink',
                    text: 'Unlink',
                    icon: 'link-variant-off',
                  },
                ]"
                @click:item="handleMovementMenuClick(movement, $event)"
              />
            </div>
          </template>
          <div
            v-if="movementsReconciliatedOfAccount.movements.length === 0"
            class="mt-4 text-sm text-slate-400"
          >
            No movement added for this account.
          </div>
          <hr v-else class="border-t border-t-gray-200 -mx-2 px-2" />
        </div>
      </div>

      <div
        v-if="movementsReconciliatedByAccount!.length === 0"
        class="mt-4 text-sm text-slate-400"
      >
        No movement needed for this activity.
      </div>
    </div>
  </div>
</template>
