import type dayjs from "dayjs";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import {
  addRecord,
  deleteRecord,
  deleteRecordLocally,
  fetchRecords,
} from "@/features/storage";
import { TABLES } from "@/features/indexeddb";

import type { Movement, MovementActivity } from "@/types/movements";
import { useAuthStore } from "./auth";

export const useMovementsStore = defineStore("movements", () => {
  const movements = ref<Movement[]>([]);
  const movementsActivities = ref<MovementActivity[]>([]);
  const focusedMovement = ref<number | null>(null);

  const load = async () => {
    await Promise.all([
      fetchRecords(movements, TABLES.MOVEMENTS),
      fetchRecords(movementsActivities, TABLES.MOVEMENTS_ACTIVITIES),
    ]);
  };

  const getMovementById = (movementId: number): Movement | undefined => {
    return movements.value.find((m) => m.id === movementId);
  };

  const getMovementActivityById = (
    movementActivityId: number
  ): MovementActivity | undefined => {
    return movementsActivities.value.find((ma) => ma.id === movementActivityId);
  };

  const movementsActivitiesByActivity = computed(() => {
    return movementsActivities.value.reduce(
      (movementsActivitiesByActivity, movementActivity) => {
        if (!movementsActivitiesByActivity[movementActivity.activity]) {
          movementsActivitiesByActivity[movementActivity.activity] = [
            movementActivity,
          ];
        } else {
          movementsActivitiesByActivity[movementActivity.activity].push(
            movementActivity
          );
        }

        return movementsActivitiesByActivity;
      },
      {} as Record<number, MovementActivity[]>
    );
  });

  const movementsActivitiesByMovement = computed(() => {
    return movementsActivities.value.reduce(
      (movementsActivitiesByMovement, movementActivity) => {
        if (!movementsActivitiesByMovement[movementActivity.movement]) {
          movementsActivitiesByMovement[movementActivity.movement] = [
            movementActivity,
          ];
        } else {
          movementsActivitiesByMovement[movementActivity.movement].push(
            movementActivity
          );
        }

        return movementsActivitiesByMovement;
      },
      {} as Record<number, MovementActivity[]>
    );
  });

  const getMovementsActivitiesByActivity = (
    activityId: number
  ): MovementActivity[] => {
    return movementsActivitiesByActivity.value[activityId] ?? [];
  };

  const getMovementsActivitiesByMovement = (
    movementId: number
  ): MovementActivity[] => {
    return movementsActivitiesByMovement.value[movementId] ?? [];
  };

  const movementsReconciliationStatusByMovement = computed<
    Record<number, "incomplete" | "completed">
  >(() => {
    const movementsReconciliationStatusByMovement: Record<
      number,
      "incomplete" | "completed"
    > = {};

    movements.value.forEach((movement) => {
      const movementsActivities = getMovementsActivitiesByMovement(movement.id);
      movementsReconciliationStatusByMovement[movement.id] =
        movementsActivities.reduce((sum, ma) => sum + ma.amount, 0) ===
        movement.amount
          ? "completed"
          : "incomplete";
    });

    return movementsReconciliationStatusByMovement;
  });

  const getMovementReconciliationStatus = (
    movementId: number
  ): "incomplete" | "completed" => {
    return (
      movementsReconciliationStatusByMovement.value[movementId] ?? "incomplete"
    );
  };

  const addNewMovement = async (
    date: dayjs.Dayjs,
    amount: number,
    account: number,
    name: string
  ): Promise<Movement> => {
    const { user } = useAuthStore();

    const newMovement: Movement = await addRecord(
      {
        user: user!.id,
        date,
        amount,
        account,
        name,
      },
      TABLES.MOVEMENTS
    );

    movements.value.push(newMovement);
    return newMovement;
  };

  const deleteMovement = async (movementId: number) => {
    const movement = getMovementById(movementId);
    if (!movement) return;

    getMovementsActivitiesByMovement(movementId).forEach((movementActivity) => {
      deleteMovementActivity(movementActivity.id, true);
    });

    movements.value.splice(movements.value.indexOf(movement), 1);
    await deleteRecord(movementId, TABLES.MOVEMENTS);
  };

  const addNewMovementActivity = async (
    activity: number,
    movement: number,
    amount: number
  ): Promise<MovementActivity> => {
    const { user } = useAuthStore();

    const newMovementActivity: MovementActivity = await addRecord(
      {
        user: user!.id,
        activity,
        movement,
        amount,
      },
      TABLES.MOVEMENTS_ACTIVITIES
    );

    movementsActivities.value.push(newMovementActivity);
    return newMovementActivity;
  };

  const deleteMovementActivity = (
    movementActivityId: number,
    localOnly: boolean
  ) => {
    const movementActivity = getMovementActivityById(movementActivityId);
    if (!movementActivity) return;

    movementsActivities.value.splice(
      movementsActivities.value.indexOf(movementActivity),
      1
    );

    if (localOnly) {
      deleteRecordLocally(movementActivity.id, TABLES.MOVEMENTS_ACTIVITIES);
    } else {
      deleteRecord(movementActivity.id, TABLES.MOVEMENTS_ACTIVITIES);
    }
  };

  return {
    load,

    movements,

    getMovementById,
    getMovementActivityById,
    getMovementsActivitiesByActivity,
    getMovementsActivitiesByMovement,
    getMovementReconciliationStatus,

    addNewMovement,
    deleteMovement,

    focusedMovement,

    addNewMovementActivity,
    deleteMovementActivity,
  };
});
