import { defineStore } from "pinia";
import { ref } from "vue";
import type { User } from "@supabase/supabase-js";
import Cookies from "js-cookie";

import { supabase } from "@/lib/supabase";

import { clearStorage } from "@/features/storage";

export const useAuthStore = defineStore("auth", () => {
  const user = ref<User | undefined>();

  const loadUser = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (!data.session) {
      user.value = undefined;
      Cookies.remove("loggedIn");
    } else {
      user.value = data.session.user;
      Cookies.set("loggedIn", "1", { expires: 365 });
    }

    return user.value;
  };

  const logout = async () => {
    await supabase.auth.signOut();
    await clearStorage();

    Cookies.remove("loggedIn");
    window.location.href = "/";
  };

  return {
    user,

    loadUser,
    logout,
  };
});
