import { defineStore } from "pinia";
import { computed, ref } from "vue";

import {
  addRecord,
  deleteRecord,
  deleteRecordLocally,
  fetchRecords,
} from "@/features/storage";
import { TABLES } from "@/features/indexeddb";

import type { Transaction } from "@/types/transactions";

import { useAuthStore } from "./auth";

export const useTransactionsStore = defineStore("transactions", () => {
  const transactions = ref<Transaction[]>([]);

  const load = async () => {
    await fetchRecords(transactions, TABLES.TRANSACTIONS);
  };

  const getTransactionById = (
    transactionId: number
  ): Transaction | undefined => {
    return transactions.value.find((t) => t.id === transactionId);
  };

  const transactionsByActivity = computed(() => {
    return transactions.value.reduce((transactionsByActivity, transaction) => {
      if (!transactionsByActivity[transaction.activity]) {
        transactionsByActivity[transaction.activity] = [transaction];
      } else {
        transactionsByActivity[transaction.activity].push(transaction);
      }

      return transactionsByActivity;
    }, {} as Record<number, Transaction[]>);
  });

  const getTransactionsByActivity = (activityId: number): Transaction[] => {
    return transactionsByActivity.value[activityId] ?? [];
  };

  const addNewTransaction = async (
    amount: number,
    fromAccount: number,
    toAccount: number,
    activityId: number
  ): Promise<Transaction> => {
    const { user } = useAuthStore();

    const newTransaction: Transaction = await addRecord(
      {
        user: user!.id,
        amount,
        fromAccount,
        toAccount,
        activity: activityId,
      },
      TABLES.TRANSACTIONS
    );

    transactions.value.push(newTransaction);
    return newTransaction;
  };

  const deleteTransaction = async (
    transactionId: number,
    localOnly: boolean
  ) => {
    const transaction = getTransactionById(transactionId);
    if (!transaction) return;

    transactions.value.splice(transactions.value.indexOf(transaction), 1);
    if (!localOnly) {
      await deleteRecord(transactionId, TABLES.TRANSACTIONS);
    } else {
      await deleteRecordLocally(transactionId, TABLES.TRANSACTIONS);
    }
  };

  const deleteTransactionsFromActivity = async (
    activityId: number,
    localOnly: boolean
  ) => {
    await Promise.all(
      transactions.value
        .filter((t) => t.activity === activityId)
        .map((t) => deleteTransaction(t.id, localOnly))
    );
  };

  return {
    load,

    transactions,

    getTransactionById,
    getTransactionsByActivity,

    addNewTransaction,
    deleteTransaction,
    deleteTransactionsFromActivity,
  };
});
