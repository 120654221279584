<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useBudgetsStore } from "@/stores/budgets";
import { usePeriodsStore } from "@/stores/periods";
import {
  ACTIVITY_TYPES_COLOR,
  ACTIVITY_TYPES_NAME,
  useActivitiesStore,
} from "@/stores/activities";

import BudgetGauge from "@/components/budget/BudgetGauge.vue";

import { getCurrencyFormatter } from "@/utils/currency";

import { ActivityType } from "@/types/activities";
import type { Budget } from "@/types/budgets";

const props = defineProps<{
  activityType: ActivityType;
  month: number;
  year: number;
}>();

const activitiesStore = useActivitiesStore();
const { categories } = storeToRefs(activitiesStore);

const budgetStore = useBudgetsStore();
const { budgets, planning, focusedBudget } = storeToRefs(budgetStore);

const periodsStore = usePeriodsStore();
const { periodsActivityData, periodsBudgetData } = storeToRefs(periodsStore);

const budget = computed<Budget | undefined>(() => {
  return budgets.value.find(
    (b) =>
      b.type === props.activityType &&
      b.year === props.year &&
      b.month === props.month
  );
});

const dataKey = computed<"revenue" | "expense" | "investment">(() => {
  if (props.activityType === ActivityType.REVENUE) return "revenue";
  else if (props.activityType === ActivityType.EXPENSE) return "expense";
  else return "investment";
});

const periodActivityTypeValue = computed<number>(() => {
  const periodData = periodsActivityData.value.find(
    (p) => p.month === props.month && p.year === props.year
  );
  if (!periodData) return 0;
  return periodData[dataKey.value];
});

const periodBudgetActivityType = computed(() => {
  if (!planning.value) {
    const periodData = periodsBudgetData.value.find(
      (p) => p.month === props.month && p.year === props.year
    );
    if (!periodData) return 0;
    return periodData[dataKey.value];
  } else {
    if (budget.value) return budget.value.value;
    else {
      return budgets.value
        .filter(
          (b) =>
            b.year === props.year &&
            b.month === props.month &&
            b.category !== null &&
            categories.value
              .filter((c) => c.type === props.activityType)
              .map((c) => c.id)
              .includes(b.category)
        )
        .reduce((acc, b) => acc + b.value, 0);
    }
  }
});
</script>

<template>
  <div
    class="h-10 flex items-center justify-end gap-2 text-sm pr-2 sm:pr-6 border-b border-b-gray-300 transition-colors"
    :class="[
      focusedBudget === budget?.id
        ? 'bg-indigo-50 border-l-4 border-l-indigo-400 pl-4 sm:pl-6'
        : 'hover:bg-slate-50 pl-5 sm:pl-7',
    ]"
    @click="() => (budget ? (focusedBudget = budget.id) : undefined)"
  >
    <div class="flex items-center ml-0.5">
      <div
        class="h-2.5 w-2.5 rounded-xl shrink-0 mr-3"
        :class="`bg-${ACTIVITY_TYPES_COLOR[activityType]}-300`"
      />
      <span class="text-sm font-medium text-slate-700">
        {{ ACTIVITY_TYPES_NAME[activityType] }}
      </span>
    </div>

    <div class="flex-1" />

    <div
      class="whitespace-nowrap text-sm text-right w-24 px-4 text-slate-500 h-full flex items-center justify-end font-medium"
    >
      {{ getCurrencyFormatter().format(periodActivityTypeValue) }}
    </div>

    <div class="w-[1px] bg-slate-200 h-7" />

    <div
      class="whitespace-nowrap text-sm text-right w-24 rounded px-2 text-slate-700 font-medium mr-3"
    >
      {{ getCurrencyFormatter().format(periodBudgetActivityType) }}
    </div>

    <BudgetGauge
      :budget="periodBudgetActivityType"
      :value="periodActivityTypeValue"
      :activity-type="activityType"
    />
  </div>
</template>
