<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useMovementsStore } from "@/stores/movements";
import { useHeadStore } from "@/stores/head";

import AddMovementButton from "@/containers/movements/AddMovementButton.vue";
import ImportMovementsButton from "@/containers/movements/ImportMovementsButton.vue";
import MovementsTable from "@/containers/movements/MovementsTable.vue";
import SearchBar from "@/containers/SearchBar.vue";

const route = useRoute();
const router = useRouter();

const headStore = useHeadStore();

const movementsStore = useMovementsStore();
const { movements, focusedMovement } = storeToRefs(movementsStore);

const viewLoaded = ref(false);

const loadRouteParams = async () => {
  await router.isReady();

  if (
    route.params.id !== null &&
    route.params.id !== undefined &&
    route.params.id !== "" &&
    route.params.id !== "tolink"
  ) {
    const movement = movementsStore.getMovementById(
      parseInt(route.params.id as string)
    );
    if (!movement) return;

    focusedMovement.value = movement.id;
  }

  if (route.params.id !== "tolink") {
    headStore.updateTitle("Movements");
  } else {
    headStore.updateTitle("Movements to link");
  }

  viewLoaded.value = true;
};

loadRouteParams();

watch(
  () => route.params.id,
  () => {
    if (route.name === "movements") loadRouteParams();
  }
);

const movementsToShow = computed(() => {
  return movements.value.filter((movement) => {
    if (route.params.id === "tolink") {
      return (
        movementsStore.getMovementReconciliationStatus(movement.id) ===
        "incomplete"
      );
    }
    return true;
  });
});
</script>

<template>
  <header
    class="h-14 border-b border-b-gray-200 pl-14 pr-4 lg:pl-8 flex items-center"
  >
    <div class="text-sm font-medium">Movements</div>
    <div class="flex-1" />

    <SearchBar />
    <ImportMovementsButton />
    <AddMovementButton class="ml-2" />
  </header>

  <MovementsTable :movements="movementsToShow" grouping="period" />
</template>
