<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "vue-router";

import NormalNavigation from "@/containers/navigation/NormalNavigation.vue";
import SettingsNavigation from "@/containers/navigation/SettingsNavigation.vue";

const route = useRoute();

const openMobile = ref(false);
</script>

<template>
  <button
    class="absolute lg:hidden z-30 top-3 left-3 h-8 w-8 flex items-center justify-center"
    @click="openMobile = !openMobile"
  >
    <i class="mdi mdi-menu text-slate-800 text-xl" />
  </button>

  <Transition
    enter-class="duration-100 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="duration-100 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="openMobile"
      class="fixed inset-0 backdrop-blur-sm bg-slate-100/20 z-10 lg:hidden"
      @click="openMobile = false"
    />
  </Transition>

  <div
    class="w-56 h-full border-r absolute px-4 z-20 bg-white lg:static lg:shadow-none transition-transform duration-200 lg:translate-x-0 lg:transition-none flex flex-col overflow-y-auto min-h-0"
    :class="{ '-translate-x-56 ': !openMobile, 'shadow-2xl': openMobile }"
  >
    <SettingsNavigation
      v-if="route.path.includes('settings')"
      :open-mobile="openMobile"
      @close="openMobile = false"
    />
    <NormalNavigation
      v-else
      :open-mobile="openMobile"
      @close="openMobile = false"
      @route-click="openMobile = false"
    />
  </div>
</template>
