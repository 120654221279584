import dayjs from "dayjs";
import _ from "lodash";

import { useTransactionsStore } from "@/stores/transactions";
import { useAccountsStore } from "@/stores/accounts";
import { useMovementsStore } from "@/stores/movements";
import { useActivitiesStore } from "@/stores/activities";
import { useAuthStore } from "@/stores/auth";

import {
  type Activity,
  ActivityType,
  type ActivityStatus,
} from "@/types/activities";
import { AccountType } from "@/types/accounts";
import type { MovementWithLink } from "@/types/movements";
import { useContactsStore } from "@/stores/contacts";

export const getActivityStatus = (activity: Activity): ActivityStatus => {
  if (activity.date > dayjs()) {
    return "scheduled";
  }

  if (
    !getActivityMovementsReconciliated(activity) ||
    !getActivityLiabilitiesReconciliated(activity)
  ) {
    return "incomplete";
  } else {
    return "completed";
  }
};

export const getActivityTransactionsReconciliationSum = (
  activityType: ActivityType,
  transactions: {
    fromAccount: number | undefined;
    toAccount: number | undefined;
    amount: number;
  }[]
): number => {
  const accountsStore = useAccountsStore();

  return _.round(
    transactions.reduce((s, transaction) => {
      let amountTakenIntoAccount = 0;
      if (activityType === ActivityType.NEUTRAL) {
        amountTakenIntoAccount = transaction.amount;
      } else {
        if (transaction.fromAccount === undefined) return s;
        const fromAccount = accountsStore.getAccountById(
          transaction.fromAccount
        );
        if (!fromAccount) return s;

        if (
          activityType === ActivityType.EXPENSE &&
          fromAccount.type === AccountType.EXPENSE
        ) {
          amountTakenIntoAccount += transaction.amount * -1;
        } else if (
          activityType === ActivityType.REVENUE &&
          fromAccount.type === AccountType.REVENUE
        ) {
          amountTakenIntoAccount += transaction.amount;
        } else if (
          activityType === ActivityType.INVESTMENT &&
          fromAccount.type === AccountType.INVESTMENT_ACCOUNT
        ) {
          amountTakenIntoAccount += transaction.amount * -1;
        }

        if (transaction.toAccount === undefined) return s;
        const toAccount = accountsStore.getAccountById(transaction.toAccount);
        if (!toAccount) return s;

        if (
          activityType === ActivityType.EXPENSE &&
          toAccount.type === AccountType.EXPENSE
        ) {
          amountTakenIntoAccount += transaction.amount;
        } else if (
          activityType === ActivityType.REVENUE &&
          toAccount.type === AccountType.REVENUE
        ) {
          amountTakenIntoAccount += transaction.amount * -1;
        } else if (
          activityType === ActivityType.INVESTMENT &&
          toAccount.type === AccountType.INVESTMENT_ACCOUNT
        ) {
          amountTakenIntoAccount += transaction.amount;
        }
      }

      return s + amountTakenIntoAccount;
    }, 0),
    2
  );
};

export const getActivityMovementsByAccount = (activity: Activity) => {
  const { getMovementById, getMovementsActivitiesByActivity } =
    useMovementsStore();
  const movementsActivities = getMovementsActivitiesByActivity(activity.id);

  return movementsActivities.reduce(
    (movementsByAccount, movementActivity) => {
      const movement = getMovementById(movementActivity.movement);
      if (!movement) return movementsByAccount;

      let movementsOfAccount = movementsByAccount.find(
        (mvb) => mvb.account === movement.account
      );
      if (!movementsOfAccount) {
        movementsOfAccount = {
          account: movement.account,
          total: 0,
          movements: [],
        };
        movementsByAccount.push(movementsOfAccount);
      }

      movementsOfAccount.total += movementActivity.amount;
      movementsOfAccount.movements.push({
        ...movement,
        movementActivityId: movementActivity.id,
        amountLinked: movementActivity.amount,
      });

      return movementsByAccount;
    },
    [] as {
      account: number;
      total: number;
      movements: MovementWithLink[];
    }[]
  );
};

export const getActivityTransactionsSumByAccount = (activity: Activity) => {
  const { user } = useAuthStore();

  const { getTransactionsByActivity } = useTransactionsStore();
  const transactions = getTransactionsByActivity(activity.id);

  const { getAccountById } = useAccountsStore();

  return transactions.reduce((transactionsSumByAccount, transaction) => {
    if (transaction.user !== user!.id) return transactionsSumByAccount;

    const fromAccount = getAccountById(transaction.fromAccount);
    if (!fromAccount) return transactionsSumByAccount;

    const toAccount = getAccountById(transaction.toAccount);
    if (!toAccount) return transactionsSumByAccount;

    let transactionsSumOfFromAccount = transactionsSumByAccount.find(
      (tvb) => tvb.account === transaction.fromAccount
    );
    if (!transactionsSumOfFromAccount) {
      transactionsSumOfFromAccount = {
        account: transaction.fromAccount,
        total: 0,
      };
      transactionsSumByAccount.push(transactionsSumOfFromAccount);
    }
    transactionsSumOfFromAccount.total += transaction.amount * -1;

    let transactionsSumOfToAccount = transactionsSumByAccount.find(
      (tvb) => tvb.account === transaction.toAccount
    );
    if (!transactionsSumOfToAccount) {
      transactionsSumOfToAccount = {
        account: transaction.toAccount,
        total: 0,
      };
      transactionsSumByAccount.push(transactionsSumOfToAccount);
    }
    transactionsSumOfToAccount.total += transaction.amount;

    return transactionsSumByAccount;
  }, [] as { account: number; total: number }[]);
};

export const getActivityMovementsReconciliatedByAccount = (
  activity: Activity
) => {
  const movementsByAccount = getActivityMovementsByAccount(activity);
  const transactionsSumByAccount =
    getActivityTransactionsSumByAccount(activity);

  const { accounts } = useAccountsStore();

  return accounts.reduce(
    (movementsReconciliatedByAccount, account) => {
      if (!account.movements) {
        return movementsReconciliatedByAccount;
      }

      const transactionsSumOfAccount = transactionsSumByAccount.find(
        (tsba) => tsba.account === account.id
      );

      const movementsReconciliatedOfAccount = {
        account: account.id,
        reconcilied: false,
        transactionTotal: transactionsSumOfAccount?.total ?? 0,
        movementTotal: 0,
        movements: [] as MovementWithLink[],
      };

      const movementsOfAccount = movementsByAccount.find(
        (mvb) => mvb.account === account.id
      );
      if (movementsOfAccount) {
        movementsReconciliatedOfAccount.movementTotal =
          movementsOfAccount.total;
        movementsReconciliatedOfAccount.movements =
          movementsOfAccount.movements;
      }

      if (
        !transactionsSumOfAccount &&
        movementsReconciliatedOfAccount.movements.length === 0
      ) {
        return movementsReconciliatedByAccount;
      }

      if (
        _.round(movementsReconciliatedOfAccount.transactionTotal, 2) ===
          _.round(movementsReconciliatedOfAccount.movementTotal, 2) &&
        movementsReconciliatedOfAccount.movements.length > 0
      ) {
        movementsReconciliatedOfAccount.reconcilied = true;
      }

      movementsReconciliatedByAccount.push(movementsReconciliatedOfAccount);
      return movementsReconciliatedByAccount;
    },
    [] as {
      account: number;
      reconcilied: boolean;
      transactionTotal: number;
      movementTotal: number;
      movements: MovementWithLink[];
    }[]
  );
};

export const getActivityMovementsReconciliated = (
  activity: Activity
): boolean => {
  const movementsReconciliatedByAccount =
    getActivityMovementsReconciliatedByAccount(activity);
  return movementsReconciliatedByAccount.every((mrba) => mrba.reconcilied);
};

export const getActivitySharingLiabilitiesNeededByAccount = (
  sharingId: number
) => {
  const { user } = useAuthStore();

  const { activitiesSharing } = useActivitiesStore();

  const othersSharing = activitiesSharing.filter(
    (as) => as.sharing === sharingId && as.user !== user!.id
  );

  const sumOfLiabilitiesNeededByAccount: {
    account: number;
    necessary: number;
  }[] = [];

  othersSharing.forEach((as) => {
    if (as.activity === undefined) return;

    const { contacts } = useContactsStore();
    const { getTransactionsByActivity } = useTransactionsStore();

    const transactions = getTransactionsByActivity(as.activity);
    transactions.forEach((transaction) => {
      const contactFromAccount = contacts.find(
        (c) => c.liabilityFromAccount === transaction.fromAccount
      );
      if (
        contactFromAccount &&
        contactFromAccount.liabilityToAccount !== null
      ) {
        let sumOfLiabilities = sumOfLiabilitiesNeededByAccount.find(
          (solba) => solba.account === contactFromAccount.liabilityToAccount
        );
        if (!sumOfLiabilities) {
          sumOfLiabilities = {
            account: contactFromAccount.liabilityToAccount,
            necessary: 0,
          };
          sumOfLiabilitiesNeededByAccount.push(sumOfLiabilities);
        }
        sumOfLiabilities.necessary += transaction.amount;
      }

      const contactToAccount = contacts.find(
        (c) => c.liabilityFromAccount === transaction.toAccount
      );
      if (contactToAccount && contactToAccount.liabilityToAccount !== null) {
        let sumOfLiabilities = sumOfLiabilitiesNeededByAccount.find(
          (solba) => solba.account === contactToAccount.liabilityToAccount
        );
        if (!sumOfLiabilities) {
          sumOfLiabilities = {
            account: contactToAccount.liabilityToAccount,
            necessary: 0,
          };
          sumOfLiabilitiesNeededByAccount.push(sumOfLiabilities);
        }
        sumOfLiabilities.necessary += transaction.amount * -1;
      }
    });
  });

  return sumOfLiabilitiesNeededByAccount;
};

export const getActivityLiabilitiesByAccount = (activity: Activity) => {
  const { user } = useAuthStore();

  const { activitiesSharing } = useActivitiesStore();
  const mySharing = activitiesSharing.find(
    (as) => as.activity === activity.id && as.user === user!.id
  );
  if (!mySharing) return [];

  const activityTransactionsSumByAccount =
    getActivityTransactionsSumByAccount(activity);

  const sumOfLiabilitiesByAccount: {
    account: number;
    total: number;
    necessary: number;
  }[] = getActivitySharingLiabilitiesNeededByAccount(mySharing.sharing).map(
    (as) => {
      return {
        ...as,
        total:
          activityTransactionsSumByAccount.find((s) => s.account === as.account)
            ?.total ?? 0,
      };
    }
  );

  return sumOfLiabilitiesByAccount;
};

export const getActivityLiabilitiesReconciliated = (activity: Activity) => {
  return getActivityLiabilitiesByAccount(activity).every(
    (l) => _.round(l.total, 2) === _.round(l.necessary, 2)
  );
};
