<script setup lang="ts">
import { ref } from "vue";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import AccountSelect from "@/components/accounts/AccountSelect.vue";

import { useTransactionsStore } from "@/stores/transactions";

import type { ActivityData } from "@/types/activities";

const transactionsStore = useTransactionsStore();

const props = defineProps<{
  activityData: ActivityData;
}>();

const addNewTransactionDialog = ref({
  show: false,
  amount: undefined as number | undefined,
  fromAccount: undefined as number | undefined,
  toAccount: undefined as number | undefined,
});

const resetAddNewTransactionDialog = () => {
  addNewTransactionDialog.value = {
    show: false,
    amount: undefined,
    fromAccount: undefined,
    toAccount: undefined,
  };
};

const addNewTransaction = async () => {
  if (addNewTransactionDialog.value.amount === undefined) return;
  else if (addNewTransactionDialog.value.fromAccount === undefined) return;
  else if (addNewTransactionDialog.value.toAccount === undefined) return;

  await transactionsStore.addNewTransaction(
    addNewTransactionDialog.value.amount,
    addNewTransactionDialog.value.fromAccount,
    addNewTransactionDialog.value.toAccount,
    props.activityData.id
  );

  resetAddNewTransactionDialog();
};

const open = () => {
  addNewTransactionDialog.value.show = true;
};
</script>

<template>
  <TTooltip text="Add a transaction">
    <button
      type="button"
      class="inline-flex items-center justify-center w-6 h-6 transition text-slate-600 hover:text-slate-900 bg-white hover:bg-slate-100 border border-gray-300 rounded-md"
      @click="open"
    >
      <i class="mdi mdi-plus text-base" />
    </button>
  </TTooltip>

  <TransitionRoot appear :show="addNewTransactionDialog.show" as="template">
    <Dialog
      as="div"
      class="relative z-50"
      @close="resetAddNewTransactionDialog"
    >
      <TransitionChild
        as="template"
        enter="duration-100 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-100 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 backdrop-blur-sm bg-slate-100/20" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-100 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-lg transition-all border"
            >
              <DialogTitle as="div" class="flex border-b pb-4 pt-5 px-8">
                <div class="text-slate-800 font-medium">
                  Add a new transaction
                </div>
                <div class="flex-1" />
                <button
                  class="inline-flex items-center justify-center w-6 h-6 transition text-slate-500 hover:text-slate-900 min-w-6 shrink-0"
                  @click="resetAddNewTransactionDialog"
                >
                  <i class="mdi mdi-close text-lg" />
                </button>
              </DialogTitle>

              <div class="px-8 py-6">
                <div class="flex flex-col sm:flex-row sm:items-center text-sm">
                  <div class="text-sm text-slate-400">Amount</div>
                  <div class="flex-1" />
                  <TAmountInput
                    v-model="addNewTransactionDialog.amount"
                    class="w-full sm:w-56 mt-2 sm:mt-0"
                  />
                </div>

                <div
                  class="mt-4 flex flex-col sm:flex-row sm:items-center text-sm"
                >
                  <div class="text-sm text-slate-400">From account</div>
                  <div class="flex-1" />
                  <AccountSelect
                    v-model="addNewTransactionDialog.fromAccount"
                    class="w-full sm:w-56 mt-2 sm:mt-0"
                  />
                </div>

                <div
                  class="mt-4 flex flex-col sm:flex-row sm:items-center text-sm"
                >
                  <div class="text-sm text-slate-400">To account</div>
                  <div class="flex-1" />
                  <AccountSelect
                    v-model="addNewTransactionDialog.toAccount"
                    class="w-full sm:w-56 mt-2 sm:mt-0"
                  />
                </div>
              </div>

              <div class="border-t px-8 py-4 flex justify-end">
                <TBtn @click="addNewTransaction"> Create transaction </TBtn>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
