<script setup lang="ts">
import { useAuthStore } from "@/stores/auth";

const { user } = useAuthStore();
</script>

<template>
  <div class="h-full w-full flex flex-col p-8">
    <div class="w-full flex items-center">
      <div v-if="user">
        <div class="text-sm text-slate-400">Logged in as:</div>
        <div class="text-sm text-slate-600 mt-1 font-medium">
          {{ user.email }}
        </div>
      </div>
      <div class="flex-1" />
      <RouterLink
        type="button"
        class="inline-flex items-center justify-center transition text-slate-600 hover:text-slate-900 bg-white hover:bg-slate-100 rounded px-3 h-10"
        to="/"
      >
        <i class="mdi mdi-arrow-left text-base" />
        <span class="text-slate-700 ml-2 font-medium text-xs mb-0.5">
          Go back to Banqr
        </span>
      </RouterLink>
    </div>
    <div class="flex-1 flex flex-col items-center justify-center">
      <div class="text-7xl font-medium text-slate-800">404</div>
      <div class="text-2xl font-medium text-slate-700 mt-4">
        This page does not exist
      </div>
    </div>
  </div>
</template>
