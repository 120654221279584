<script setup lang="ts">
import { computed, ref } from "vue";

import AccountLabel from "@/components/AccountLabel.vue";

import {
  getActivityLiabilitiesByAccount,
  getActivityLiabilitiesReconciliated,
} from "@/features/activities/reconciliation";

import type { ActivityData } from "@/types/activities";

import { getCurrencyFormatter } from "@/utils/currency";

const props = defineProps<{ activityData: ActivityData }>();

const showLiabilities = ref(true);

const activityLiabilitiesReconciliated = computed(() => {
  return getActivityLiabilitiesReconciliated(props.activityData.activity);
});

const activityLiabilitiesByAccount = computed(() => {
  return getActivityLiabilitiesByAccount(props.activityData.activity);
});
</script>

<template>
  <div
    v-if="activityLiabilitiesByAccount.length > 0"
    class="border-b py-6 px-4 sm:px-8"
  >
    <div class="flex items-center">
      <div
        class="-ml-2 text-sm font-medium text-slate-800 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
        @click="showLiabilities = !showLiabilities"
      >
        <i
          v-if="!activityLiabilitiesReconciliated"
          class="mdi mdi-progress-helper text-xl text-orange-300 mr-2"
        />
        <i v-else class="mdi mdi-check-circle text-xl text-emerald-400 mr-2" />
        Liabilities
        <i
          class="mdi ml-2"
          :class="showLiabilities ? 'mdi-menu-down' : 'mdi-menu-up'"
        />
      </div>
    </div>

    <div v-show="showLiabilities" class="mt-4">
      <template
        v-for="liabilityAccount in activityLiabilitiesByAccount"
        :key="liabilityAccount.account"
      >
        <hr class="border-t border-t-gray-200 -mx-2 px-2" />

        <div
          class="h-10 flex items-center justify-center text-sm hover:bg-slate-50 -mx-2 px-2 rounded"
        >
          <AccountLabel :account-id="liabilityAccount.account" class="pl-1" />
          <div class="flex-1" />
          <div
            class="whitespace-nowrap mr-10"
            :class="
              liabilityAccount.total !== liabilityAccount.necessary
                ? 'text-red-400'
                : 'text-slate-500'
            "
          >
            <i
              v-if="liabilityAccount.total !== liabilityAccount.necessary"
              class="mdi mdi-alert"
            />
            {{ getCurrencyFormatter().format(liabilityAccount.total) }}
            /
            {{ getCurrencyFormatter().format(liabilityAccount.necessary) }}
          </div>
        </div>
      </template>
      <hr class="border-t border-t-gray-200 -mx-2 px-2" />
    </div>
  </div>
</template>
