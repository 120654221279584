<script setup lang="ts">
import { ref } from "vue";

import AddActivityModal from "./AddActivityModal.vue";

import { useHotkey } from "@/hooks/use-hotkey";

import type { Movement } from "@/types/movements";

const props = withDefaults(
  defineProps<{
    movement?: Movement;
    large?: boolean;
    primary?: boolean;
  }>(),
  { large: false, movement: undefined, primary: false }
);

const show = ref(false);

useHotkey(["c"], () => {
  show.value = true;
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <button
    v-bind="$attrs"
    type="button"
    class="inline-flex items-center justify-center transition border border-gray-300 rounded flex-shrink-0"
    :class="[
      large ? 'px-3.5 h-8' : 'w-7 sm:w-auto sm:px-2.5 h-7',
      primary
        ? 'text-white bg-indigo-400 hover:bg-indigo-300'
        : 'text-slate-600 hover:text-slate-900 bg-white hover:bg-slate-100',
    ]"
    @click="show = true"
  >
    <i class="mdi mdi-plus text-base" />
    <span
      class="ml-2 font-medium"
      :class="large ? 'text-sm' : 'text-xs hidden sm:block'"
    >
      New activity
    </span>
  </button>

  <AddActivityModal v-model="show" :movement="movement" />
</template>
