import { defineStore } from "pinia";
import { ref } from "vue";

import {
  addRecord,
  deleteRecord,
  deleteRecordLocally,
  fetchRecords,
} from "@/features/storage";
import { TABLES } from "@/features/indexeddb";

import type { Budget } from "@/types/budgets";
import type { ActivityType } from "@/types/activities";

import { useAuthStore } from "./auth";

export const useBudgetsStore = defineStore("budgets", () => {
  const budgets = ref<Budget[]>([]);
  const planning = ref(false);
  const focusedBudget = ref<number | null>(null);

  const load = async () => {
    await fetchRecords(budgets, TABLES.BUDGETS);
  };

  const addCategoryBudget = async (
    category: number,
    value: number,
    month: number,
    year: number,
    accumulating: boolean
  ): Promise<Budget | undefined> => {
    const { user } = useAuthStore();

    const newBudget: Budget = await addRecord(
      {
        user: user!.id,
        category,
        type: null,
        value,
        month,
        year,
        accumulating,
      },
      TABLES.BUDGETS
    );

    budgets.value.push(newBudget);
    return newBudget;
  };

  const addActivityTypeBudget = async (
    type: ActivityType,
    value: number,
    month: number,
    year: number,
    accumulating: boolean
  ): Promise<Budget | undefined> => {
    const { user } = useAuthStore();

    const newBudget: Budget = await addRecord(
      {
        user: user!.id,
        category: null,
        type,
        value,
        month,
        year,
        accumulating,
      },
      TABLES.BUDGETS
    );

    budgets.value.push(newBudget);
    return newBudget;
  };

  const deleteBudget = async (budgetId: number) => {
    const budget = budgets.value.find((b) => b.id === budgetId);
    if (!budget) return;

    budgets.value.splice(budgets.value.indexOf(budget), 1);
    await deleteRecord(budget.id, TABLES.BUDGETS);
  };

  const deleteAllCategoryBudgets = async (
    category: number,
    localOnly: boolean
  ) => {
    await Promise.all(
      budgets.value
        .filter((b) => b.category === category)
        .map((budget) => {
          budgets.value.splice(budgets.value.indexOf(budget), 1);
          if (!localOnly) {
            return deleteRecord(budget.id, TABLES.BUDGETS);
          } else {
            return deleteRecordLocally(budget.id, TABLES.BUDGETS);
          }
        })
    );
  };

  const deleteAllActivityTypeBudgets = async (type: ActivityType) => {
    await Promise.all(
      budgets.value
        .filter((b) => b.type === type)
        .map((budget) => {
          budgets.value.splice(budgets.value.indexOf(budget), 1);
          return deleteRecord(budget.id, TABLES.BUDGETS);
        })
    );
  };

  return {
    load,

    budgets,
    planning,
    focusedBudget,

    addCategoryBudget,
    deleteAllCategoryBudgets,

    addActivityTypeBudget,
    deleteAllActivityTypeBudgets,

    deleteBudget,
  };
});
