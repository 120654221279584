<script setup lang="ts">
import { computed } from "vue";

import { ActivityType } from "@/types/activities";

const props = withDefaults(
  defineProps<{
    budget: number;
    value: number;
    activityType: ActivityType;
    huge?: boolean;
    minimize?: boolean;
  }>(),
  { huge: false, minimize: false }
);

const gaugeValue = computed<number>(() => {
  if (props.budget === 0) {
    return props.value > 0 ? 1 : 0;
  } else if (props.value < 0) {
    return 0;
  } else if (props.budget < 0) {
    return 1;
  } else {
    return props.value / props.budget;
  }
});
</script>

<template>
  <div
    class="group flex items-center overflow-hidden transition-all rounded"
    :class="[
      huge ? 'h-10' : 'h-5',
      minimize
        ? 'w-3 hover:w-16 hover:px-2 hover:bg-slate-100'
        : huge
        ? ''
        : 'w-14',
    ]"
  >
    <div
      v-if="huge"
      class="text-slate-600 mr-3 text-right flex-grow text-large font-bold"
    >
      {{
        gaugeValue.toLocaleString(undefined, {
          style: "percent",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      }}
    </div>

    <TProgressCircle
      class="flex-shrink-0"
      :class="huge ? 'h-7 w-7' : 'h-3 w-3'"
      :value="gaugeValue"
      :is-overflow-positive="
        [ActivityType.REVENUE, ActivityType.INVESTMENT].includes(activityType)
      "
    />

    <div
      v-if="!huge"
      class="ml-1.5 text-right flex-grow text-xs"
      :class="
        gaugeValue > 1 ? 'font-semibold text-slate-500' : 'text-slate-400'
      "
    >
      {{
        gaugeValue.toLocaleString(undefined, {
          style: "percent",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      }}
    </div>
  </div>
</template>
