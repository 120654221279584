<script setup lang="ts">
import { computed } from "vue";

import { stringify } from "csv-stringify/browser/esm/sync";

import { useViewsStore } from "@/stores/views";

import type { ActivityData } from "@/types/activities";
import { verifyActivityFilter } from "@/features/activities/filters";

const props = defineProps<{
  viewId: string;
  activitiesData: ActivityData[];
}>();

const activityView = computed(() =>
  useViewsStore().getActivityView(props.viewId)
);

const filteredActivities = computed(() => {
  return props.activitiesData.filter((activityData) => {
    if (activityView.value.filters.length === 0) return true;

    return activityView.value.filters
      .map((filter) => {
        return verifyActivityFilter(filter, activityData);
      })
      .every((f) => f);
  });
});

const exportActivities = () => {
  const csvFile = stringify([
    ["number", "date", "name", "amount"],
    ...filteredActivities.value.map((a) => [
      a.activity.number,
      a.activity.date.format("YYYY-MM-DD"),
      a.activity.name,
      a.amount,
    ]),
  ]);

  const url = window.URL.createObjectURL(new Blob([csvFile]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `activities_export.csv`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
</script>

<template>
  <TTooltip text="Export activities">
    <button
      type="button"
      class="inline-flex items-center justify-center transition text-slate-500 hover:text-slate-700 hover:bg-slate-100 rounded h-7 w-7 bg-white border border-dashed"
      @click="exportActivities"
    >
      <i class="mdi mdi-download mt-0.5" />
    </button>
  </TTooltip>
</template>
