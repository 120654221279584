<script setup lang="ts">
import { ref, watch } from "vue";
import { useResizeObserver } from "@vueuse/core";

const props = defineProps<{
  modelValue: string | undefined;
}>();
const emits = defineEmits(["update:modelValue"]);

const input = ref<HTMLInputElement>();
const value = ref(props.modelValue);

watch(
  () => props.modelValue,
  () => {
    value.value = props.modelValue;
  }
);

const handleInput = () => {
  if (input.value) {
    emits("update:modelValue", input.value.value);
    input.value.style.height = "";
    input.value.style.height = input.value.scrollHeight + "px";
  }
};

useResizeObserver(input, handleInput);
</script>

<template>
  <textarea
    ref="input"
    v-model="value"
    name="activity-name"
    rows="1"
    autocomplete="on"
    class="text-3xl text-slate-800 font-bold w-full break-words resize-none bg-transparent leading-snug"
    placeholder="Activity name"
    @input="handleInput"
  />
</template>
