<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import dayjs from "dayjs";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";

import AccountLabel from "@/components/AccountLabel.vue";
import AccountSelect from "@/components/accounts/AccountSelect.vue";
import ProjectSelect from "@/components/projects/ProjectSelect.vue";
import ActivityNameInput from "@/components/activities/ActivityNameInput.vue";

import { getActivitySharingLiabilitiesNeededByAccount } from "@/features/activities/reconciliation";

import {
  ACTIVITY_TYPES_COLOR,
  ACTIVITY_TYPES_NAME,
  useActivitiesStore,
} from "@/stores/activities";
import { useTransactionsStore } from "@/stores/transactions";
import { useMovementsStore } from "@/stores/movements";
import { useAccountsStore } from "@/stores/accounts";
import { useAuthStore } from "@/stores/auth";

import { getCurrencyFormatter } from "@/utils/currency";

import { ActivityType } from "@/types/activities";
import type { Movement } from "@/types/movements";
import { AccountType } from "@/types/accounts";

const activitiesStore = useActivitiesStore();
const { categories, subcategories } = storeToRefs(activitiesStore);

const transactionsStore = useTransactionsStore();
const movementsStore = useMovementsStore();
const accountsStore = useAccountsStore();
const { user } = useAuthStore();

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    movement?: Movement;
    name?: string;
    date?: dayjs.Dayjs;
    type?: ActivityType;
    activitySharingId?: number;
  }>(),
  {
    movement: undefined,
    name: undefined,
    date: undefined,
    amount: undefined,
    type: undefined,
    activitySharingId: undefined,
  }
);
const emit = defineEmits(["update:modelValue"]);

const nameInput = ref<HTMLInputElement | null>(null);

const addNewActivityDialog = ref({
  show: false,
  name: undefined as string | undefined,
  description: undefined as string | undefined,
  date: undefined as dayjs.Dayjs | undefined,
  type: undefined as ActivityType | undefined,
  category: null as number | null,
  subcategory: null as number | null,
  project: null as number | null,
  transactions: [] as {
    fromAccount: number | undefined;
    toAccount: number | undefined;
    amount: number;
  }[],
});

const resetAddNewActivityDialog = () => {
  addNewActivityDialog.value.show = false;

  setTimeout(() => {
    if (addNewActivityDialog.value.show) return;
    addNewActivityDialog.value = {
      show: false,
      name: undefined,
      description: undefined,
      date: undefined,
      type: undefined,
      category: null,
      subcategory: null,
      project: null,
      transactions: [],
    };
    emit("update:modelValue", false);
  }, 200);
};

const validForm = computed(() => {
  if (
    addNewActivityDialog.value.name === undefined ||
    addNewActivityDialog.value.name === ""
  )
    return false;
  if (addNewActivityDialog.value.date === undefined) return false;
  if (addNewActivityDialog.value.type === undefined) return false;
  return true;
});

watch(validForm, () => {
  if (validForm.value) {
    if (addNewActivityDialog.value.transactions.length === 0) {
      addTransaction();
    }
  }
});

const addNewActivity = async () => {
  if (!validForm.value) return;

  const newActivity = await activitiesStore.addNewActivity(
    addNewActivityDialog.value.name!,
    addNewActivityDialog.value.description ?? null,
    addNewActivityDialog.value.date!,
    addNewActivityDialog.value.type!,
    addNewActivityDialog.value.category,
    addNewActivityDialog.value.subcategory,
    addNewActivityDialog.value.project
  );

  addNewActivityDialog.value.transactions
    .filter((t) => t.fromAccount !== undefined && t.toAccount !== undefined)
    .forEach((t) => {
      transactionsStore.addNewTransaction(
        t.amount,
        t.fromAccount!,
        t.toAccount!,
        newActivity.id
      );
    });

  if (props.movement) {
    movementsStore.addNewMovementActivity(
      newActivity.id,
      props.movement.id,
      props.movement.amount
    );
  }

  if (props.activitySharingId !== undefined) {
    const activitySharing = activitiesStore.activitiesSharing.find(
      (as) => as.sharing === props.activitySharingId && as.user === user!.id
    );
    if (activitySharing) activitySharing.activity = newActivity.id;
  }

  resetAddNewActivityDialog();
};

const openDialog = () => {
  addNewActivityDialog.value.show = true;
  addNewActivityDialog.value.date = dayjs();

  if (props.movement) {
    addNewActivityDialog.value.name = props.movement.name;
    addNewActivityDialog.value.date = props.movement.date;
    addNewActivityDialog.value.type =
      props.movement.amount < 0 ? ActivityType.EXPENSE : ActivityType.REVENUE;

    addNewActivityDialog.value.transactions = [];
  } else {
    addNewActivityDialog.value.name = props.name;
    addNewActivityDialog.value.type = props.type;

    if (props.date) {
      addNewActivityDialog.value.date = props.date;
    }
  }

  if (props.activitySharingId !== undefined) {
    addNewActivityDialog.value.transactions = [];
    getActivitySharingLiabilitiesNeededByAccount(
      props.activitySharingId
    ).forEach((asln) => {
      if (addNewActivityDialog.value.type === ActivityType.EXPENSE) {
        addNewActivityDialog.value.transactions.push({
          fromAccount: asln.account,
          toAccount: accountsStore.accounts.find(
            (a) => a.type === AccountType.EXPENSE
          )?.id,
          amount: asln.necessary * -1,
        });
      } else if (addNewActivityDialog.value.type === ActivityType.REVENUE) {
        addNewActivityDialog.value.transactions.push({
          fromAccount: accountsStore.accounts.find(
            (a) => a.type === AccountType.REVENUE
          )?.id,
          toAccount: asln.account,
          amount: asln.necessary,
        });
      }
    });
  }

  nextTick(() => {
    if (nameInput.value) nameInput.value.focus();
  });
};

const guessBestTransaction = () => {
  let fromAccount;
  let toAccount;

  if (addNewActivityDialog.value.type === ActivityType.EXPENSE) {
    fromAccount = props.movement
      ? props.movement.account
      : accountsStore.accounts.find((a) => a.type === AccountType.BANK_ACCOUNT)
          ?.id;
    toAccount = accountsStore.accounts.find(
      (a) => a.type === AccountType.EXPENSE
    )?.id;
  } else if (addNewActivityDialog.value.type === ActivityType.REVENUE) {
    fromAccount = accountsStore.accounts.find(
      (a) => a.type === AccountType.REVENUE
    )?.id;
    toAccount = props.movement
      ? props.movement.account
      : accountsStore.accounts.find((a) => a.type === AccountType.BANK_ACCOUNT)
          ?.id;
  } else if (addNewActivityDialog.value.type === ActivityType.INVESTMENT) {
    fromAccount = accountsStore.accounts.find(
      (a) => a.type === AccountType.BANK_ACCOUNT
    )?.id;
    toAccount = accountsStore.accounts.find(
      (a) => a.type === AccountType.INVESTMENT_ACCOUNT
    )?.id;
  }

  return { fromAccount, toAccount };
};

const addTransaction = () => {
  const { fromAccount, toAccount } = guessBestTransaction();

  addNewActivityDialog.value.transactions.push({
    fromAccount,
    toAccount,
    amount: props.movement ? Math.abs(props.movement.amount) : 0,
  });
};

const transactionsSum = computed(() => {
  return addNewActivityDialog.value.transactions.reduce(
    (s, t) => s + t.amount,
    0
  );
});

watch(
  () => props.modelValue,
  (show: boolean) => {
    if (show) {
      openDialog();
    } else {
      resetAddNewActivityDialog();
    }
  },
  { immediate: true }
);
</script>

<template>
  <TransitionRoot appear :show="addNewActivityDialog.show" as="template">
    <Dialog as="div" class="relative z-50" @close="resetAddNewActivityDialog">
      <TransitionChild
        as="template"
        enter="duration-100 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-100 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 backdrop-blur-sm bg-slate-100/20" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-start sm:items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-100 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-2xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-lg transition-all border"
            >
              <div class="px-4 sm:px-8 pt-4 flex items-center">
                <div class="flex min-w-0">
                  <div
                    class="-mx-2 text-sm bg-slate-100 px-2 h-6 flex items-center text-slate-500 rounded min-w-0"
                  >
                    <template v-if="movement">
                      <AccountLabel :account-id="movement.account" />
                      <span class="px-2">-</span>
                      <span class="truncate min-w-0">
                        {{ movement.name }}
                      </span>
                    </template>
                    <template v-else> New activity </template>
                  </div>
                </div>

                <div class="flex-1" />
                <button
                  class="inline-flex items-center justify-center w-6 h-6 transition text-slate-500 hover:text-slate-900 min-w-6 shrink-0"
                  @click="resetAddNewActivityDialog"
                >
                  <i class="mdi mdi-close text-lg" />
                </button>
              </div>

              <div class="px-4 sm:px-8 pt-3 pb-3">
                <TDatePicker
                  v-model="addNewActivityDialog.date"
                  borderless
                  class="font-semibold text-slate-500 text-sm"
                />
                <div class="flex items-center">
                  <ActivityNameInput v-model="addNewActivityDialog.name" />
                </div>
                <textarea
                  v-model="addNewActivityDialog.description"
                  name="activity-description"
                  class="mt-3 text-sm border-none w-full text-slate-800 break-words resize-none bg-transparent"
                  placeholder="Description (optional)"
                />
              </div>

              <div class="px-4 sm:px-8 pb-4 flex items-center gap-2 flex-wrap">
                <TSelect
                  v-model="addNewActivityDialog.type"
                  :items="[
                    { id: ActivityType.REVENUE, name: 'Revenue' },
                    { id: ActivityType.EXPENSE, name: 'Expense' },
                    { id: ActivityType.INVESTMENT, name: 'Investment' },
                    { id: ActivityType.NEUTRAL, name: 'Neutral' },
                  ]"
                  item-value="id"
                  item-text="name"
                  placeholder="Activity type"
                  class="h-8"
                  @update:model-value="addNewActivityDialog!.category = null"
                >
                  <template #selected="{ item }">
                    <div class="flex items-center">
                      <div
                        class="h-3 w-3 rounded-xl shrink-0 mr-3"
                        :class="`bg-${ACTIVITY_TYPES_COLOR[item.id as ActivityType]}-300`"
                      />
                      <span class="text-sm text-slate-900">
                        {{ ACTIVITY_TYPES_NAME[item.id as ActivityType] }}
                      </span>
                    </div>
                  </template>

                  <template #item="{ item, selected }">
                    <div class="flex items-center">
                      <div
                        class="h-3 w-3 rounded-xl shrink-0 mr-3"
                        :class="`bg-${ACTIVITY_TYPES_COLOR[item.id as ActivityType]}-300`"
                      />
                      <span
                        class="text-sm text-slate-600 mb-[1px]"
                        :class="selected ? 'font-medium' : ''"
                      >
                        {{ ACTIVITY_TYPES_NAME[item.id as ActivityType] }}
                      </span>
                    </div>
                  </template>
                </TSelect>

                <TSelect
                  v-model="addNewActivityDialog.category"
                  :disabled="addNewActivityDialog.type === undefined"
                  :items="
                    categories.filter(
                      (c) => c.type === addNewActivityDialog.type
                    )
                  "
                  item-value="id"
                  item-text="name"
                  placeholder="Category"
                  class="h-8"
                />

                <TSelect
                  v-model="addNewActivityDialog.subcategory"
                  :disabled="addNewActivityDialog.category === null"
                  :items="
                    subcategories.filter(
                      (sc) => sc.category === addNewActivityDialog.category
                    )
                  "
                  item-value="id"
                  item-text="name"
                  placeholder="Subcategory"
                  class="h-8"
                />

                <ProjectSelect
                  v-model="addNewActivityDialog.project"
                  class="h-8"
                />
              </div>

              <div
                class="px-4 sm:px-6 py-2 overflow-auto flex-1 bg-slate-100 border-t pb-8"
              >
                <div
                  class="text-sm font-medium text-slate-500 px-2 py-2 border-b flex items-center"
                >
                  Transactions

                  <div class="flex-1" />

                  <div
                    v-if="addNewActivityDialog.transactions.length > 0"
                    class="flex items-center justify-end text-sm text-slate-400 mr-2.5"
                  >
                    {{ getCurrencyFormatter().format(transactionsSum) }}
                  </div>
                  <button
                    class="w-7 h-7 hover:bg-slate-200 flex items-center justify-center rounded hover:text-slate-800"
                    @click="addTransaction"
                  >
                    <i class="mdi mdi-plus" />
                  </button>
                </div>

                <div
                  v-for="(
                    transaction, index
                  ) in addNewActivityDialog.transactions"
                  :key="index"
                  class="w-full flex items-center text-sm shrink-0 h-10 rounded pr-2.5 border-b"
                >
                  <AccountSelect v-model="transaction.fromAccount" borderless />
                  <div class="mx-2 text-center text-slate-600">to</div>
                  <AccountSelect v-model="transaction.toAccount" borderless />

                  <div class="flex-1" />

                  <TAmountInput v-model="transaction.amount" borderless />
                  <button
                    class="ml-3 w-6 h-6 hover:bg-slate-200 flex items-center justify-center rounded text-slate-500 hover:text-slate-800"
                    @click="addNewActivityDialog.transactions.splice(index, 1)"
                  >
                    <i class="mdi mdi-delete" />
                  </button>
                </div>
              </div>

              <div class="border-t px-4 sm:px-8 py-4 flex justify-end">
                <TBtn outlined class="mr-3" @click="resetAddNewActivityDialog">
                  Cancel
                </TBtn>
                <TBtn :disabled="!validForm" @click="addNewActivity">
                  Create activity
                </TBtn>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
