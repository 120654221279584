<script setup lang="ts">
import { computed, ref } from "vue";

import { useTransactionsStore } from "@/stores/transactions";

import type { ActivityData } from "@/types/activities";
import type { Transaction } from "@/types/transactions";

import AddTransactionButton from "@/containers/activities/AddTransactionButton.vue";

import TAmountInput from "@/components/designSystem/TAmountInput.vue";

import { getCurrencyFormatter } from "@/utils/currency";

import AccountSelect from "@/components/accounts/AccountSelect.vue";

const transactionsStore = useTransactionsStore();

const props = defineProps<{
  activityData: ActivityData;
}>();

const showTransactions = ref(true);

const transactionsSum = computed(() => {
  return props.activityData.transactions.reduce((s, t) => s + t.amount, 0);
});

const handleTransactionMenuClick = (
  transaction: Transaction,
  event: string
) => {
  if (event === "delete") {
    transactionsStore.deleteTransaction(transaction.id, false);
  }
};
</script>

<template>
  <div class="border-b py-6 px-4 sm:px-8">
    <div class="flex items-center">
      <div
        class="-ml-2 text-sm font-medium text-slate-800 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
        @click="showTransactions = !showTransactions"
      >
        Transactions
        <i
          class="mdi ml-2"
          :class="showTransactions ? 'mdi-menu-down' : 'mdi-menu-up'"
        />
      </div>

      <div class="flex-1" />

      <template v-if="showTransactions">
        <div
          v-if="transactionsSum !== undefined"
          class="text-xs whitespace-nowrap mr-4 text-slate-400"
        >
          {{ getCurrencyFormatter().format(transactionsSum) }}
        </div>
        <AddTransactionButton :activity-data="activityData" />
      </template>
    </div>

    <div v-show="showTransactions" class="mt-4 mb-2">
      <template
        v-for="transaction in activityData.transactions"
        :key="transaction.id"
      >
        <hr class="border-t border-t-gray-200 -mx-2 px-2" />

        <div
          class="h-10 flex items-center justify-center text-sm hover:bg-slate-50 -mx-2 px-2 rounded"
        >
          <AccountSelect
            v-model="transaction.fromAccount"
            borderless
            class="pl-0"
          />
          <div class="mx-2 text-center text-slate-400">to</div>
          <AccountSelect v-model="transaction.toAccount" borderless />

          <div class="flex-1" />

          <TAmountInput v-model="transaction.amount" borderless class="mr-4" />

          <TMenu
            :items="[{ value: 'delete', text: 'Delete', icon: 'delete' }]"
            @click:item="handleTransactionMenuClick(transaction, $event)"
          />
        </div>
      </template>
      <div
        v-if="activityData.transactions!.length === 0"
        class="text-sm text-slate-400"
      >
        No transaction added for this activity.
      </div>
      <hr v-else class="border-t border-t-gray-200 -mx-2 px-2" />
    </div>
  </div>
</template>
