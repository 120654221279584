<script setup lang="ts">
import { storeToRefs } from "pinia";

import AddBudgetButton from "@/containers/budget/AddBudgetButton.vue";
import BudgetsTable from "@/containers/budget/BudgetsTable.vue";

import TogglePlanningButton from "@/components/budget/TogglePlanningButton.vue";

import { useBudgetsStore } from "@/stores/budgets";
import { useHeadStore } from "@/stores/head";

const budgetStore = useBudgetsStore();
const { budgets } = storeToRefs(budgetStore);

const headStore = useHeadStore();
headStore.updateTitle("Budgets");
</script>

<template>
  <div class="flex flex-1 flex-col h-full">
    <header
      class="h-14 border-b border-b-gray-200 pl-12 pr-4 lg:pl-6 flex items-center"
    >
      <div class="text-sm font-medium px-2">All budgets</div>

      <div class="flex-1" />

      <TogglePlanningButton class="mr-3" />

      <AddBudgetButton />
    </header>

    <BudgetsTable :budgets="budgets" />
  </div>
</template>
