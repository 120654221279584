<template>
  <svg
    width="436"
    height="144"
    viewBox="0 0 436 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1079_40)">
      <path
        d="M45.2801 29.9201C56.2667 29.9201 65.6534 33.9734 73.4401 42.0801C81.2268 50.1868 85.1201 60.1601 85.1201 72.0001C85.1201 83.7334 81.2268 93.7068 73.4401 101.92C65.6534 110.027 56.2667 114.08 45.2801 114.08C33.12 114.08 23.7867 109.547 17.2801 100.48V112H0V0H17.2801V43.36C23.7867 34.4 33.12 29.9201 45.2801 29.9201ZM24.4801 90.4C29.2801 95.2 35.3067 97.6 42.56 97.6C49.8134 97.6 55.8401 95.2 60.64 90.4C65.44 85.4934 67.84 79.3601 67.84 72.0001C67.84 64.6401 65.44 58.5601 60.64 53.7601C55.8401 48.8534 49.8134 46.4 42.56 46.4C35.3067 46.4 29.2801 48.8534 24.4801 53.7601C19.6801 58.5601 17.2801 64.6401 17.2801 72.0001C17.2801 79.3601 19.6801 85.4934 24.4801 90.4Z"
        fill="#2E3440"
      />
      <path
        d="M164.16 32.0001H181.44V112H164.16V100.48C157.653 109.547 148.32 114.08 136.16 114.08C125.173 114.08 115.787 110.027 108 101.92C100.213 93.7068 96.3201 83.7334 96.3201 72.0001C96.3201 60.16 100.213 50.1867 108 42.0801C115.787 33.9734 125.173 29.9201 136.16 29.9201C148.32 29.9201 157.653 34.4 164.16 43.36V32.0001ZM120.8 90.4C125.6 95.2 131.627 97.6 138.88 97.6C146.133 97.6 152.16 95.2 156.96 90.4C161.76 85.4934 164.16 79.3601 164.16 72.0001C164.16 64.6401 161.76 58.5601 156.96 53.7601C152.16 48.8534 146.133 46.4 138.88 46.4C131.627 46.4 125.6 48.8534 120.8 53.7601C116 58.5601 113.6 64.6401 113.6 72.0001C113.6 79.3601 116 85.4934 120.8 90.4Z"
        fill="#2E3440"
      />
      <path
        d="M243.68 29.9201C252.853 29.9201 260.266 32.8534 265.92 38.72C271.573 44.5867 274.4 52.64 274.4 62.88V112H257.12V64.64C257.12 58.6666 255.52 54.08 252.32 50.8801C249.12 47.6801 244.746 46.08 239.2 46.08C233.12 46.08 228.213 48 224.48 51.84C220.746 55.5734 218.88 61.3334 218.88 69.1201V112H201.6V32.0001H218.88V42.2401C224.106 34.0267 232.373 29.9201 243.68 29.9201Z"
        fill="#2E3440"
      />
      <path
        d="M356.32 32.0001H373.6V144H356.32V100.64C349.813 109.6 340.48 114.08 328.32 114.08C317.333 114.08 307.947 110.027 300.16 101.92C292.373 93.8135 288.48 83.8401 288.48 72.0001C288.48 60.2667 292.373 50.3467 300.16 42.2401C307.947 34.0267 317.333 29.9201 328.32 29.9201C340.48 29.9201 349.813 34.4533 356.32 43.52V32.0001ZM312.96 90.4C317.76 95.2 323.786 97.6 331.04 97.6C338.293 97.6 344.32 95.2 349.12 90.4C353.92 85.4934 356.32 79.3601 356.32 72.0001C356.32 64.6401 353.92 58.5601 349.12 53.7601C344.32 48.8534 338.293 46.4 331.04 46.4C323.786 46.4 317.76 48.8534 312.96 53.7601C308.16 58.5601 305.76 64.6401 305.76 72.0001C305.76 79.3601 308.16 85.4934 312.96 90.4Z"
        fill="#2E3440"
      />
      <path
        d="M411.04 45.44C415.413 35.52 423.626 30.56 435.68 30.56V49.28C429.066 48.8533 423.306 50.4533 418.399 54.08C413.493 57.6 411.04 63.4667 411.04 71.68V112H393.76V32H411.04V45.44Z"
        fill="#2E3440"
      />
    </g>
    <defs>
      <clipPath id="clip0_1079_40">
        <rect width="435.68" height="144" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
