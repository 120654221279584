<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useBudgetsStore } from "@/stores/budgets";
import { usePeriodsStore } from "@/stores/periods";

import BudgetGauge from "@/components/budget/BudgetGauge.vue";

import { getCurrencyFormatter } from "@/utils/currency";

import type { ActivityCategory } from "@/types/activities";
import type { Budget } from "@/types/budgets";

const props = defineProps<{
  category: ActivityCategory;
  month: number;
  year: number;
}>();

const budgetStore = useBudgetsStore();
const { budgets, planning, focusedBudget } = storeToRefs(budgetStore);

const periodsStore = usePeriodsStore();
const { periodsActivityData, periodsBudgetData } = storeToRefs(periodsStore);

const budget = computed<Budget | undefined>(() => {
  return budgets.value.find(
    (b) =>
      b.category === props.category.id &&
      b.year === props.year &&
      b.month === props.month
  );
});

const periodActivityCategoryValue = computed<number>(() => {
  const periodData = periodsActivityData.value.find(
    (p) => p.month === props.month && p.year === props.year
  );
  if (!periodData) return 0;
  return (
    periodData.categories.find((c) => c.category === props.category.id)
      ?.value ?? 0
  );
});

const periodBudgetCategory = computed(() => {
  if (!planning.value) {
    const periodData = periodsBudgetData.value.find(
      (p) => p.month === props.month && p.year === props.year
    );
    if (!periodData) return 0;
    return (
      periodData.categories.find((c) => c.category === props.category.id)
        ?.value ?? 0
    );
  } else {
    if (budget.value) return budget.value.value;
    else return 0;
  }
});
</script>

<template>
  <div
    class="h-10 flex items-center justify-end gap-2 text-sm pr-2 sm:pr-6 border-b transition-colors"
    :class="[
      focusedBudget === budget?.id
        ? 'bg-indigo-50 border-l-4 border-l-indigo-400 pl-4 sm:pl-6'
        : 'hover:bg-slate-50 pl-5 sm:pl-7',
    ]"
    @click="() => (budget ? (focusedBudget = budget.id) : undefined)"
  >
    <i class="mdi mdi-tag text-slate-300 ml-6" />
    <div
      class="flex items-center text-slate-500 whitespace-nowrap text-ellipsis min-w-0"
    >
      {{ category.name }}
    </div>

    <div class="flex-1" />

    <div
      class="whitespace-nowrap text-sm text-right w-24 px-4 text-slate-500 h-full flex items-center justify-end"
    >
      {{ getCurrencyFormatter().format(periodActivityCategoryValue) }}
    </div>

    <div class="w-[1px] bg-slate-200 h-7" />

    <div
      class="whitespace-nowrap text-sm text-right w-24 rounded px-2 text-slate-700 mr-3"
    >
      {{ getCurrencyFormatter().format(periodBudgetCategory) }}
    </div>

    <BudgetGauge
      :budget="periodBudgetCategory"
      :value="periodActivityCategoryValue"
      :activity-type="category.type"
    />
  </div>
</template>
