import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

import type { ActivityFilter } from "@/types/activities";

type ActivityView = {
  id: string;
  showTransactions: boolean;
  filters: ActivityFilter[];
};

export const useViewsStore = defineStore("views", () => {
  const activityViews = useLocalStorage<Record<string, ActivityView>>(
    "activityViews",
    {}
  );

  const getActivityView = (viewId: string) => {
    if (!activityViews.value[viewId]) {
      activityViews.value[viewId] = {
        id: viewId,
        showTransactions: false,
        filters: [] as ActivityFilter[],
      };
    }

    return activityViews.value[viewId];
  };

  const deleteCategory = (categoryId: number) => {
    Object.values(activityViews.value).forEach((view) => {
      view.filters.forEach((filter) => {
        if (filter.field === "category" && filter.value !== undefined) {
          filter.value = filter.value.filter((v) => v !== categoryId);
        }
      });
    });
  };

  const deleteSubcategory = (subcategoryId: number) => {
    Object.values(activityViews.value).forEach((view) => {
      view.filters.forEach((filter) => {
        if (filter.field === "subcategory" && filter.value !== undefined) {
          filter.value = filter.value.filter((v) => v !== subcategoryId);
        }
      });
    });
  };

  return {
    getActivityView,

    deleteCategory,
    deleteSubcategory,
  };
});
