<script setup lang="ts">
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { RouterLink } from "vue-router";

import AccountLabel from "@/components/AccountLabel.vue";

import { ACTIVITY_TYPES_COLOR, useActivitiesStore } from "@/stores/activities";
import { useProjectsStore } from "@/stores/projects";
import { useContactsStore } from "@/stores/contacts";

import type { ActivityData } from "@/types/activities";

import { getCurrencyFormatter } from "@/utils/currency";

const activitiesStore = useActivitiesStore();
const { categories, subcategories, showTransactions, focusedActivity } =
  storeToRefs(activitiesStore);

const projectsStore = useProjectsStore();
const contactsStore = useContactsStore();

const props = withDefaults(
  defineProps<{
    activityData: ActivityData;
    accountFilter?: number | null;
    hideProject?: boolean;
  }>(),
  { accountFilter: null, hideProject: false }
);

const transactions = computed(() => {
  return props.activityData.transactions.filter((t) =>
    props.accountFilter !== null
      ? t.fromAccount === props.accountFilter ||
        t.toAccount === props.accountFilter
      : true
  );
});

const activitySharing = computed(() => {
  if (!props.activityData) return;
  return activitiesStore.getActivitySharing(props.activityData.id);
});
</script>

<template>
  <div
    class="@container block overflow-hidden border-b border-gray-200 flex-shrink-0 group transition-colors"
    :class="[
      focusedActivity === activityData.id
        ? 'bg-indigo-50 border-l-4 border-l-indigo-400'
        : 'hover:bg-slate-50 pl-1',
    ]"
    :style="`height: ${
      showTransactions ? 40 * (1 + transactions.length) + 'px' : '40px'
    }`"
  >
    <div
      class="h-10 flex items-center gap-2 pl-3 pr-2 @lg:pr-6 @lg:pl-1 text-sm"
    >
      <div class="hidden @lg:block text-slate-500 w-20 shrink-0 ml-4">
        {{ dayjs(activityData.activity.date).format("DD/MM/YYYY") }}
      </div>
      <div class="@lg:hidden text-slate-500 w-10 shrink-0">
        {{ dayjs(activityData.activity.date).format("DD/MM") }}
      </div>

      <i
        v-if="activityData.status === 'scheduled'"
        class="mdi mdi-progress-clock text-lg text-slate-300"
      />
      <i
        v-else-if="activityData.status === 'incomplete'"
        class="mdi mdi-progress-helper text-lg text-orange-300"
      />
      <i v-else class="mdi mdi-check-circle-outline text-lg text-emerald-300" />

      <div
        class="mr-1 text-slate-700 font-medium text-ellipsis overflow-hidden whitespace-nowrap min-w-0"
      >
        {{ activityData.activity.name }}
      </div>

      <div class="flex-1" />

      <TTooltip
        v-if="activitySharing!.length > 0"
        :text="`Shared with ${activitySharing!.map((as) =>
          contactsStore.getUserName(as)
        ).join(', ')}`"
      >
        <i class="hidden sm:block mr-3 mdi mdi-share-variant text-slate-500" />
      </TTooltip>

      <div class="items-center mr-2 min-w-0 hidden @lg:flex">
        <RouterLink
          v-if="activityData.activity.project !== null && !hideProject"
          :to="{
            name: 'project',
            params: { id: activityData.activity.project },
          }"
          class="border rounded-xl h-6 flex items-center px-2 mr-4 text-slate-700 text-xs tracking-wide hover:bg-slate-100 hover:border-gray-300 transition-colors min-w-0"
          @click.stop=""
        >
          <span class="mr-2">
            {{
              projectsStore.getProjectById(activityData.activity.project)!.emoji
            }}
          </span>
          <span class="truncate">
            {{
              projectsStore.getProjectById(activityData.activity.project)!.name
            }}
          </span>
        </RouterLink>

        <div
          v-if="activityData.activity.type"
          class="h-2 w-2 rounded-xl shrink-0"
          :class="`bg-${ACTIVITY_TYPES_COLOR[activityData.activity.type]}-300`"
        />

        <template v-if="activityData.activity.category !== null">
          <i class="mdi mdi-chevron-right text-slate-500 mx-1" />
          <RouterLink
            class="text-slate-600 text-ellipsis overflow-hidden whitespace-nowrap hover:text-slate-900"
            :to="{
              name: 'category',
              params: {
                name: categories
                  .find((c) => c.id === activityData.activity.category)
                  ?.name.toLowerCase(),
              },
            }"
            @click.stop=""
          >
            {{
              categories.find((c) => c.id === activityData.activity.category)
                ?.name
            }}
          </RouterLink>
        </template>
        <template v-if="activityData.activity.subcategory !== null">
          <i class="mdi mdi-chevron-right text-slate-500 mx-1" />
          <div
            class="text-slate-600 text-ellipsis overflow-hidden whitespace-nowrap"
          >
            {{
              subcategories.find(
                (c) => c.id === activityData.activity.subcategory
              )?.name
            }}
          </div>
        </template>
      </div>

      <div
        class="@lg:w-20 text-right whitespace-nowrap"
        :class="accountFilter !== null ? 'text-slate-400' : 'text-slate-800'"
      >
        {{ getCurrencyFormatter().format(activityData.amount) }}
      </div>
    </div>

    <template v-if="showTransactions">
      <div
        v-for="transaction in transactions"
        :key="transaction.id"
        class="h-10 flex items-center gap-2 text-sm border-t border-t-gray-100 pl-3 pr-4 @lg:pr-6 ml:pl-1"
      >
        <div class="w-4 hidden @lg:block" />
        <div class="w-7 hidden @lg:block shrink-0" />
        <div class="@lg:w-20 shrink-0" />

        <div
          class="flex items-center border-l-2 border-l-gray-100 gap-2 h-10 flex-grow"
        >
          <AccountLabel :account-id="transaction.fromAccount" class="ml-6" />
          <div class="mx-2 text-center text-slate-400">to</div>
          <AccountLabel :account-id="transaction.toAccount" />

          <div class="flex-1" />

          <TAmountInput
            v-model="transaction.amount"
            borderless
            class="text-slate-600 text-xs"
            :class="accountFilter ? 'text-slate-800' : 'text-slate-600'"
          />
        </div>
      </div>
    </template>
  </div>
</template>
