<script setup lang="ts">
import { useBudgetsStore } from "@/stores/budgets";
import { storeToRefs } from "pinia";

const budgetsStore = useBudgetsStore();
const { planning } = storeToRefs(budgetsStore);
</script>

<template>
  <TTooltip text="Only show planned amounts">
    <button
      type="button"
      class="inline-flex items-center justify-center transition text-slate-600 hover:text-slate-700 hover:bg-slate-100 rounded h-7 w-7 border"
      :class="planning ? 'bg-slate-100 text-slate-900' : 'bg-white'"
      @click="planning = !planning"
    >
      <i class="mdi mdi-map" />
    </button>
  </TTooltip>
</template>
