<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useMotion } from "@vueuse/motion";

import {
  ACTIVITY_TYPES_COLOR,
  ACTIVITY_TYPES_NAME,
  useActivitiesStore,
} from "@/stores/activities";
import { useContactsStore } from "@/stores/contacts";

import { ActivityType } from "@/types/activities";

import ActivityTransactions from "@/containers/activities/ActivityTransactions.vue";
import ActivityMovements from "@/containers/activities/ActivityMovements.vue";
import ActivityLiabilities from "@/containers/activities/ActivityLiabilities.vue";
import SplitActivityModal from "@/containers/activities/SplitActivityModal.vue";

import ActivityNameInput from "@/components/activities/ActivityNameInput.vue";
import ProjectSelect from "@/components/projects/ProjectSelect.vue";

import { getCurrencyFormatter } from "@/utils/currency";

const activitiesStore = useActivitiesStore();
const { categories, subcategories, focusedActivity } =
  storeToRefs(activitiesStore);

const contactsStore = useContactsStore();
const { contacts } = storeToRefs(contactsStore);

const mainElement = ref<HTMLElement>();
const showDeleteModal = ref(false);
const showSplitModal = ref(false);
const showActivity = ref(focusedActivity.value !== null);
const showProperties = ref(true);

watch(focusedActivity, () => {
  if (focusedActivity.value !== null) {
    if (showActivity.value) return;

    showActivity.value = true;
    useMotion(mainElement, {
      initial: {
        marginLeft: window.innerWidth > 640 ? -576 : 0,
        x: 576,
      },
      enter: {
        marginLeft: 0,
        x: 0,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });
  } else {
    useMotion(mainElement, {
      initial: {
        marginLeft: 0,
        x: 0,
      },
      enter: {
        marginLeft: window.innerWidth > 640 ? -576 : 0,
        x: 576,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });

    setTimeout(() => {
      showActivity.value = false;
    }, 100);
  }
});

const activityData = computed(() => {
  if (focusedActivity.value === null) return;
  return activitiesStore.getActivityDataById(focusedActivity.value);
});

const activitySharing = computed(() => {
  if (!activityData.value) return;
  return activitiesStore.getActivitySharing(activityData.value.id);
});

const handleActivityMenuClick = (event: string) => {
  if (event === "delete") {
    showDeleteModal.value = true;
  } else if (event === "split") {
    showSplitModal.value = true;
  }
};

const deleteActivity = () => {
  if (!activityData.value) return;
  activitiesStore.deleteActivity(activityData.value.id);
  focusedActivity.value = null;
};

const handleActivitySharingUpdate = (newActivityUsers: string[]) => {
  if (!activityData.value) return;
  else if (!activitySharing.value) return;

  newActivityUsers.forEach((user) => {
    if (!activitySharing.value!.includes(user)) {
      activitiesStore.shareActivity(activityData.value!.id, user);
    }
  });

  activitySharing.value.forEach((user) => {
    if (!activityData.value) return;
    else if (!activitySharing.value) return;

    if (!newActivityUsers.includes(user)) {
      activitiesStore.unshareActivity(activityData.value.id, user);
    }
  });
};

const close = () => {
  focusedActivity.value = null;
};
</script>

<template>
  <div
    v-if="showActivity"
    ref="mainElement"
    class="absolute top-0 left-0 sm:relative flex flex-col w-full sm:w-[575px] max-w-full min-h-full overflow-hidden bg-white border-l shadow-xl"
  >
    <template v-if="activityData">
      <div
        class="flex items-center px-4 sm:px-6 border-b w-full flex-shrink-0 h-14 bg-slate-50"
      >
        <button
          type="button"
          class="inline-flex items-center justify-center w-6 h-8 ml-8 sm:ml-0 mr-4"
          @click="close"
        >
          <i
            class="mdi mdi-chevron-right mdi-24px transition text-slate-400 hover:text-slate-900"
          />
        </button>
        <div class="text-slate-700 text-sm font-medium">
          Activity #{{ activityData.activity.number }}
        </div>

        <div class="flex-1 block sm:hidden" />

        <TMenu
          class="mx-0.5 mt-1 sm:hidden"
          :items="[
            { value: 'split', text: 'Split', icon: 'content-cut' },
            { value: 'delete', text: 'Delete', icon: 'delete' },
          ]"
          @click:item="handleActivityMenuClick"
        />

        <div class="flex-1 hidden sm:flex items-center justify-end gap-3 mr-3">
          <button
            type="button"
            class="inline-flex items-center justify-center w-6 h-8"
            @click="showSplitModal = true"
          >
            <i
              class="mdi mdi-content-cut transition text-slate-400 hover:text-slate-900"
            />
          </button>

          <TDeleteConfirmation
            v-model="showDeleteModal"
            description="Are you sure you want to delete this activity?"
            @confirm="deleteActivity"
          >
            <template #default="{ open }">
              <button
                type="button"
                class="inline-flex items-center justify-center w-6 h-8"
                @click="open"
              >
                <i
                  class="mdi mdi-delete transition text-slate-400 hover:text-slate-900"
                />
              </button>
            </template>
          </TDeleteConfirmation>
        </div>
      </div>

      <div class="flex-1 overflow-y-auto pb-20">
        <div class="py-8 px-4 sm:px-8 border-b">
          <div
            class="flex items-center px-3 rounded-md w-fit h-7 -ml-2"
            :class="{
              'bg-slate-100': activityData.status === 'scheduled',
              'bg-orange-100': activityData.status === 'incomplete',
              'bg-emerald-100': activityData.status === 'completed',
            }"
          >
            <span class="capitalize text-sm font-medium text-slate-800">
              {{ activityData.status }}
            </span>
          </div>

          <div class="flex items-start mt-4">
            <div class="flex-1">
              <TDatePicker
                v-model="activityData.activity.date"
                borderless
                class="font-semibold text-slate-500 text-sm h-8"
              />
              <div class="flex items-start">
                <ActivityNameInput v-model="activityData.activity.name" />
                <div class="flex-1" />
                <div
                  class="font-semibold text-slate-700 text-right text-3xl pl-4 leading-snug whitespace-nowrap"
                >
                  {{ getCurrencyFormatter().format(activityData.amount) }}
                </div>
              </div>

              <textarea
                v-model="activityData.activity.description as string"
                name="activity-description"
                class="mt-2 text-sm border-none w-full text-slate-800 break-words resize-none bg-transparent"
                placeholder="Add a description ..."
              />
            </div>
          </div>
        </div>

        <div class="py-6 px-4 sm:px-8 border-b">
          <div class="flex">
            <div
              class="-ml-2 text-sm font-medium text-slate-800 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
              @click="showProperties = !showProperties"
            >
              Properties
              <i
                class="mdi ml-2"
                :class="showProperties ? 'mdi-menu-down' : 'mdi-menu-up'"
              />
            </div>
          </div>

          <div v-show="showProperties" class="pt-4">
            <div class="flex justify-between items-center mb-2">
              <div class="text-sm text-slate-400">Activity type</div>

              <TSelect
                v-model="activityData.activity.type"
                :items="[
                  { id: ActivityType.REVENUE, name: 'Revenue' },
                  { id: ActivityType.EXPENSE, name: 'Expense' },
                  { id: ActivityType.INVESTMENT, name: 'Investment' },
                  { id: ActivityType.NEUTRAL, name: 'Neutral' },
                ]"
                item-value="id"
                item-text="name"
                class="h-8 border-none -mr-2"
                @update:model-value="
                  activityData!.activity.category = null;
                  activityData!.activity.subcategory = null;
                "
              >
                <template #selected="{ item }">
                  <div class="flex items-center">
                    <div
                      class="h-3 w-3 rounded-xl shrink-0 mr-3"
                      :class="`bg-${ACTIVITY_TYPES_COLOR[item.id as ActivityType]}-300`"
                    />
                    <span class="text-sm text-slate-900">
                      {{ ACTIVITY_TYPES_NAME[item.id as ActivityType] }}
                    </span>
                  </div>
                </template>

                <template #item="{ item, selected }">
                  <div class="flex items-center">
                    <div
                      class="h-3 w-3 rounded-xl shrink-0 mr-3"
                      :class="`bg-${ACTIVITY_TYPES_COLOR[item.id as ActivityType]}-300`"
                    />
                    <span
                      class="text-sm text-slate-600 mb-[1px]"
                      :class="selected ? 'font-medium' : ''"
                    >
                      {{ ACTIVITY_TYPES_NAME[item.id as ActivityType] }}
                    </span>
                  </div>
                </template>
              </TSelect>
            </div>

            <div class="flex justify-between items-center mb-2">
              <div class="text-sm text-slate-400">Category</div>

              <TSelect
                v-model="activityData.activity.category"
                :items="
                  categories.filter(
                    (c) => c.type === activityData!.activity.type
                  )
                "
                item-value="id"
                item-text="name"
                placeholder="Category"
                :disabled="!activityData.activity.type"
                class="h-8 border-none -mr-2"
                @update:model-value="activityData!.activity.subcategory = null"
              />
            </div>

            <div class="flex justify-between items-center mb-2">
              <div class="text-sm text-slate-400">Subcategory</div>

              <TSelect
                v-model="activityData.activity.subcategory"
                :items="
                  subcategories.filter(
                    (sc) => sc.category === activityData!.activity.category
                  )
                "
                item-value="id"
                item-text="name"
                placeholder="Subcategory"
                class="h-8 border-none -mr-2"
                :disabled="activityData.activity.category === null"
              />
            </div>

            <div class="flex justify-between items-center mb-2">
              <div class="text-sm text-slate-400">Project</div>

              <ProjectSelect
                v-model="activityData.activity.project"
                class="h-8 border-none -mr-2"
              />
            </div>

            <div
              v-if="contacts.length > 0"
              class="flex justify-between items-center mb-2"
            >
              <div class="text-sm text-slate-400">Shared with</div>

              <TSelect
                :model-value="activitySharing"
                :items="contacts"
                item-value="contactId"
                item-text="contactName"
                multiple
                @update:model-value="handleActivitySharingUpdate"
              >
                <template #default>
                  <div
                    class="rounded hover:bg-slate-100 flex items-center h-8 px-2 -mr-2"
                  >
                    <template v-if="activitySharing!.length > 0">
                      <img
                        v-for="(au, index) in activitySharing"
                        :key="au"
                        class="w-6 h-6 rounded-full shrink-0 border-2 border-white"
                        :class="{ '-ml-3': index > 0 }"
                        :src="contactsStore.getUserAvatar(au) ?? undefined"
                        alt=""
                      />
                    </template>
                    <div v-else class="text-sm text-slate-400 mr-2">
                      No sharing yet
                    </div>

                    <i
                      class="mdi mdi-dots-horizontal mt-0.5 ml-2 text-slate-500"
                    />
                  </div>
                </template>

                <template #item="{ item, selected }">
                  <img
                    v-if="item.contactAvatar"
                    class="w-5 h-5 rounded-full shrink-0"
                    :src="item.contactAvatar"
                    alt=""
                  />
                  <div
                    v-else
                    class="w-5 h-5 rounded-full shrink-0 bg-indigo-200"
                  />
                  <div
                    class="text-sm ml-2"
                    :class="selected ? 'font-medium' : ''"
                  >
                    {{ item.contactName }}
                  </div>
                </template>
              </TSelect>
            </div>
          </div>
        </div>

        <ActivityTransactions :activity-data="activityData" />

        <ActivityMovements :activity-data="activityData" />

        <ActivityLiabilities :activity-data="activityData" />
      </div>

      <SplitActivityModal
        v-model="showSplitModal"
        :activity-id="activityData.id"
      />
    </template>
  </div>
</template>
