import _ from "lodash";
import dayjs from "dayjs";
import { type Ref, ref, toRaw, watch } from "vue";

import { supabase } from "@/lib/supabase";

import { deleteDb, db, TABLES } from "@/features/indexeddb";

type RawRecord = Record<string, any>;
type SerializedRecord = Record<string, any>;

const serializeRecord = (record: RawRecord): SerializedRecord => {
  const recordSerialized: SerializedRecord = { id: record.id };
  Object.keys(record).forEach((key) => {
    if (
      ["date", "start_date", "end_date"].includes(key) &&
      record[key] !== null
    ) {
      recordSerialized[key] = (record[key] as dayjs.Dayjs).format("YYYY-MM-DD");
    } else recordSerialized[key] = toRaw(record[key]);
  });

  return recordSerialized;
};

const parseRecord = (record: SerializedRecord): RawRecord => {
  const recordParsed: RawRecord = {};
  Object.keys(record).forEach((key) => {
    if (
      ["date", "start_date", "end_date"].includes(key) &&
      record[key] !== null
    )
      recordParsed[key] = dayjs(record[key]);
    else recordParsed[key] = record[key];
  });

  return recordParsed;
};

const updateRecordInLocalDb = async (record: RawRecord, table: TABLES) => {
  const trans = db!.transaction([table], "readwrite");
  const store = trans.objectStore(table);
  const recordSerialized = serializeRecord(record);
  store.put(recordSerialized);
};

const deleteRecordInLocalDb = async (recordId: number, table: TABLES) => {
  const trans = db!.transaction([table], "readwrite");
  const store = trans.objectStore(table);
  store.delete(recordId);
};

const registerWatcherRecord = (recordRef: Ref<RawRecord>, table: TABLES) => {
  const debounceUpdate = _.debounce(async (record) => {
    updateRecordInLocalDb(record, table);

    const recordSerialized = serializeRecord(record);
    await supabase.from(table).update(recordSerialized).eq("id", record.id);
  }, 500);

  watch(
    recordRef,
    (record) => {
      debounceUpdate(record);
    },
    { deep: true }
  );
};

export const ingestRecords = async (
  recordsRef: Ref<RawRecord[]>,
  records: SerializedRecord[],
  table: TABLES
) => {
  const trans = db!.transaction([table], "readwrite");
  const store = trans.objectStore(table);

  const localRecordsId = recordsRef.value.map((r) => r.id);

  records!.forEach((d: SerializedRecord) => {
    store.put(d);

    const record = ref(parseRecord(d));
    registerWatcherRecord(record, table);

    if (!localRecordsId.includes(d.id)) {
      recordsRef.value.push(record.value);
    } else {
      recordsRef.value.splice(
        recordsRef.value.findIndex((r) => r.id === d.id),
        1,
        record.value
      );
    }
  });
};

export const fetchRecords = async (
  recordsRef: Ref<RawRecord[]>,
  table: TABLES
) => {
  // Fetching data from local index

  const readLocalRecords = async (): Promise<RawRecord[]> => {
    const localRecords: RawRecord[] = [];
    const readTrans = db!.transaction([table], "readonly");
    const readStore = readTrans.objectStore(table);

    return new Promise((resolve) => {
      readStore.openCursor().onsuccess = (e) => {
        const cursor = (e.target as IDBRequest).result;
        if (cursor) {
          const record = ref(parseRecord(cursor.value));

          registerWatcherRecord(record, table);
          localRecords.push(record.value);
          cursor.continue();
        } else {
          resolve(localRecords);
        }
      };
    });
  };
  recordsRef.value = await readLocalRecords();

  // Fetching data from API
  const fetchRecordsFromAPI = async () => {
    const { data, error } = await supabase.from(table).select();
    if (error) return;

    await ingestRecords(recordsRef, data, table);

    const trans = db!.transaction([table], "readwrite");
    const store = trans.objectStore(table);

    const recordId = data!.map((d: SerializedRecord) => d.id);
    store.openCursor().onsuccess = (e) => {
      const cursor = (e.target as IDBRequest).result;
      if (cursor) {
        if (!recordId.includes(cursor.key)) {
          store.delete(cursor.key);

          recordsRef.value.splice(
            recordsRef.value.findIndex((r) => r.id === cursor.key),
            1
          );
        }
        cursor.continue();
      }
    };
  };

  fetchRecordsFromAPI();
};

export const addRecord = async <T extends RawRecord>(
  record: T,
  table: TABLES
): Promise<T & { id: number }> => {
  const recordSerialized = serializeRecord(record);

  const { data } = await supabase.from(table).insert(recordSerialized).select();

  const trans = db!.transaction([table], "readwrite");
  const store = trans.objectStore(table);
  store.put(data![0]);

  const newRecord = ref(parseRecord(data![0]) as T & { id: number });
  registerWatcherRecord(newRecord, table);

  return newRecord.value as T & { id: number };
};

export const updateRecordLocally = async <T extends RawRecord>(
  record: T,
  table: TABLES,
  updateFunction: (record: T) => T
): Promise<T> => {
  const updatedRecord = updateFunction(toRaw(record));
  await updateRecordInLocalDb(updatedRecord, table);

  return updatedRecord;
};

export const deleteRecord = async (recordId: number, table: TABLES) => {
  await deleteRecordInLocalDb(recordId, table);
  await supabase.from(table).delete().eq("id", recordId);
};

export const deleteRecordLocally = async (recordId: number, table: TABLES) => {
  await deleteRecordInLocalDb(recordId, table);
};

export const clearStorage = async () => {
  await deleteDb();
};
