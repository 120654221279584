<script setup lang="ts">
import { storeToRefs } from "pinia";
import _ from "lodash";
import { computed } from "vue";

import { useBudgetsStore } from "@/stores/budgets";
import { usePeriodsStore } from "@/stores/periods";
import { useActivitiesStore } from "@/stores/activities";

import BudgetGauge from "@/components/budget/BudgetGauge.vue";

import { getCurrencyFormatter } from "@/utils/currency";

import { ActivityType } from "@/types/activities";
import type { Budget } from "@/types/budgets";

const props = defineProps<{
  activityType: ActivityType;
  month: number;
  year: number;
}>();

const budgetStore = useBudgetsStore();
const { budgets, planning } = storeToRefs(budgetStore);

const periodsStore = usePeriodsStore();
const { periodsActivityData, periodsBudgetData } = storeToRefs(periodsStore);

const activitiesStore = useActivitiesStore();
const { categories } = storeToRefs(activitiesStore);

const activityTypeBudget = computed<Budget | undefined>(() => {
  return budgets.value.find(
    (b) =>
      b.type === props.activityType &&
      b.year === props.year &&
      b.month === props.month
  );
});

const periodBudgetData = computed(() => {
  return periodsBudgetData.value!.find(
    (p) => p.month === props.month && p.year === props.year
  )!;
});

const categoriesWithBudget = computed(() => {
  return _.sortBy(
    categories.value
      .filter((c) => c.type === props.activityType)
      .filter((c) => {
        return (
          budgets.value.find(
            (b) => b.category === c.id && b.year === props.year
          ) !== undefined
        );
      }),
    "name"
  );
});

const categoriesWithoutBudget = computed(() => {
  return _.sortBy(
    categories.value
      .filter((c) => c.type === props.activityType)
      .filter((c) => {
        return (
          budgets.value.find(
            (b) => b.category === c.id && b.year === props.year
          ) === undefined
        );
      }),
    "name"
  );
});

const restTotal = computed(() => {
  return periodsActivityData.value
    .find((p) => p.month === props.month && p.year === props.year)!
    .categories.filter((cb) =>
      categoriesWithoutBudget.value.map((c) => c.id).includes(cb.category)
    )
    .reduce((s, cb) => s + cb.value, 0);
});

const restInitialBudget = computed(() => {
  if (!activityTypeBudget.value) return 0;

  return (
    activityTypeBudget.value.value -
    categories.value
      .filter((c) => c.type === props.activityType)
      .reduce((sum, category) => {
        return (
          sum +
          (budgets.value.find(
            (b) =>
              b.category === category.id &&
              b.month === props.month &&
              b.year === props.year
          )?.value ?? 0)
        );
      }, 0)
  );
});

const restBudget = computed(() => {
  if (planning.value) return restInitialBudget.value;

  let totalBudget: number;
  if (props.activityType === ActivityType.REVENUE)
    totalBudget = periodBudgetData.value.revenue;
  else if (props.activityType === ActivityType.EXPENSE)
    totalBudget = periodBudgetData.value.expense;
  else totalBudget = periodBudgetData.value.investment;

  return (
    totalBudget -
    periodBudgetData.value.categories
      .filter((cb) =>
        categoriesWithBudget.value.map((c) => c.id).includes(cb.category)
      )
      .reduce((s, cb) => s + Math.max(cb.value, 0), 0)
  );
});
</script>

<template>
  <div
    v-if="activityTypeBudget"
    class="h-10 flex items-center justify-end gap-2 text-sm pl-5 pr-2 sm:pr-6 sm:pl-7 border-b"
  >
    <div
      class="flex items-center text-slate-500 whitespace-nowrap text-ellipsis min-w-0 ml-6 italic"
    >
      Rest
    </div>

    <div class="flex-1" />

    <div
      class="whitespace-nowrap text-sm text-right w-24 px-4 text-slate-500 h-full flex items-center justify-end"
    >
      {{ getCurrencyFormatter().format(restTotal) }}
    </div>

    <div class="w-[1px] bg-slate-200 h-7" />

    <div
      class="whitespace-nowrap text-sm text-right w-24 px-2 text-slate-600 mr-3"
    >
      {{ getCurrencyFormatter().format(restBudget) }}
    </div>

    <BudgetGauge
      :budget="restBudget"
      :value="restTotal"
      :activity-type="activityType"
    />
  </div>
</template>
