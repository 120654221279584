<script setup lang="ts">
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { computed, ref } from "vue";

import { Menu, MenuButton, MenuItems, MenuItem, Portal } from "@headlessui/vue";

import AddActivityModal from "./AddActivityModal.vue";

import { usePopper } from "@/hooks/use-popper";

import { getActivitySharingLiabilitiesNeededByAccount } from "@/features/activities/reconciliation";

import { useContactsStore } from "@/stores/contacts";
import {
  useActivitiesStore,
  ACTIVITY_TYPES_COLOR,
  ACTIVITY_TYPES_NAME,
} from "@/stores/activities";

import { getCurrencyFormatter } from "@/utils/currency";

import { ActivityType } from "@/types/activities";
import type { ActivitySharing } from "@/types/activities";
import { storeToRefs } from "pinia";

dayjs.extend(relativeTime);

defineOptions({
  inheritAttrs: false,
});

const [trigger, container] = usePopper({
  placement: "bottom-end",
  modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
});

const contactsStore = useContactsStore();
const { contacts } = storeToRefs(contactsStore);

const activitiesStore = useActivitiesStore();

const activitySharingWithoutActivity = computed(() =>
  activitiesStore.getActivitySharingWithoutActivity()
);

const getAmountActivitySharingData = (activitySharing: ActivitySharing) => {
  return getActivitySharingLiabilitiesNeededByAccount(activitySharing.sharing)
    .map((l) =>
      activitySharing.sharing_data.type === ActivityType.EXPENSE
        ? l.necessary * -1
        : l.necessary
    )
    .reduce((s, a) => s + a, 0);
};

const showAddActivity = ref<
  | {
      name: string;
      date: dayjs.Dayjs;
      type: ActivityType;
      amount: number;
      sharing: number;
    }
  | undefined
>();

const createActivity = (activitySharing: ActivitySharing) => {
  showAddActivity.value = {
    name: activitySharing.sharing_data.name,
    type: activitySharing.sharing_data.type,
    date: dayjs(activitySharing.sharing_data.date),
    amount: getAmountActivitySharingData(activitySharing),
    sharing: activitySharing.sharing,
  };
};
</script>

<template>
  <Menu v-if="contacts.length > 0" v-slot="{ open }">
    <MenuButton
      ref="trigger"
      class="inline-flex items-center justify-center transition border border-gray-300 rounded flex-shrink-0 w-7 sm:w-auto sm:px-2.5 h-7 hover:text-slate-800 bg-white hover:bg-slate-100 relative"
      :class="
        activitySharingWithoutActivity.length > 0
          ? 'text-slate-600'
          : 'text-slate-400'
      "
      v-bind="$attrs"
    >
      <i class="mdi mdi-inbox-arrow-down text-base" />
      <span class="ml-2 font-medium text-xs hidden sm:block">
        Sharing inbox
      </span>

      <div
        v-if="activitySharingWithoutActivity.length > 0"
        class="h-2.5 w-2.5 bg-primary-400 rounded-full absolute -top-1 -right-1"
      />
    </MenuButton>

    <Portal v-if="open">
      <MenuItems
        ref="container"
        class="w-full max-w-md divide-y divide-gray-100 max-h-[520px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-10 focus:outline-none z-20 flex flex-col"
      >
        <div class="flex items-center h-10 px-5 shrink-0 shadow">
          <div class="text-sm font-medium text-slate-800">Sharing inbox</div>
          <div class="flex-1" />
          <div
            v-if="activitySharingWithoutActivity.length > 0"
            class="text-sm text-slate-400 ml-4"
          >
            {{ activitySharingWithoutActivity.length }} new sharing demands
          </div>
        </div>

        <div class="px-4 py-4 overflow-y-auto flex-1">
          <MenuItem
            v-for="activitySharing in activitySharingWithoutActivity"
            :key="activitySharing.id"
            v-slot="{ active }"
            @click="createActivity(activitySharing)"
          >
            <button
              :class="[
                active ? 'bg-slate-100 text-slate-900' : 'text-slate-700',
                'w-full rounded px-4 py-1 border relative mb-3',
              ]"
            >
              <div class="flex items-center border-b py-4">
                <template v-if="activitySharing.author">
                  <img
                    class="w-4 h-4 rounded-full shrink-0"
                    :src="
                      contactsStore.getUserAvatar(activitySharing.author) ??
                      undefined
                    "
                    alt=""
                  />
                  <div class="text-xs text-slate-600 ml-1.5 font-medium">
                    {{ contactsStore.getUserName(activitySharing.author) }}
                  </div>
                </template>
                <div class="text-xs text-slate-400 ml-1">
                  shared with you
                  <span class="font-medium">
                    {{ dayjs(activitySharing.created_at).fromNow() }}
                  </span>
                </div>
              </div>

              <div class="py-4">
                <div class="flex items-center">
                  <div
                    class="h-2 w-2 rounded-xl shrink-0 mr-2"
                    :class="`bg-${
                      ACTIVITY_TYPES_COLOR[activitySharing.sharing_data.type]
                    }-300`"
                  />
                  <div class="text-xs text-slate-500 font-medium">
                    {{ ACTIVITY_TYPES_NAME[activitySharing.sharing_data.type] }}
                  </div>

                  <div class="flex-1" />
                  <div class="text-slate-600 text-left text-sm">
                    {{
                      dayjs(activitySharing.sharing_data.date).format(
                        "DD/MM/YYYY"
                      )
                    }}
                  </div>
                </div>

                <div class="flex items-center mt-2">
                  <div class="font-bold text-slate-700 text-left text-xl">
                    {{ activitySharing.sharing_data.name }}
                  </div>

                  <div class="flex-1" />

                  <div
                    class="font-semibold text-slate-700 text-right pl-4 leading-snug"
                  >
                    {{
                      getCurrencyFormatter().format(
                        getAmountActivitySharingData(activitySharing)
                      )
                    }}
                  </div>
                </div>
              </div>
            </button>
          </MenuItem>

          <div
            v-if="activitySharingWithoutActivity.length === 0"
            class="text-sm flex items-center justify-center text-slate-400"
          >
            No sharing demand yet.
          </div>
        </div>
      </MenuItems>
    </Portal>
  </Menu>

  <AddActivityModal
    v-if="showAddActivity !== undefined"
    :model-value="true"
    :name="showAddActivity.name"
    :date="showAddActivity.date"
    :type="showAddActivity.type"
    :amount="showAddActivity.amount"
    :activity-sharing-id="showAddActivity.sharing"
    @update:model-value="showAddActivity = undefined"
  />
</template>
