<script setup lang="ts">
import type { Dayjs } from "dayjs";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { useActivitiesStore } from "@/stores/activities";
import { useMovementsStore } from "@/stores/movements";
import { useSettingsStore } from "@/stores/settings";
import { useAccountsStore } from "@/stores/accounts";

import ActivityTransactions from "@/containers/activities/ActivityTransactions.vue";
import ActivityMovements from "@/containers/activities/ActivityMovements.vue";
import ImportMovementsButton from "@/containers/movements/ImportMovementsButton.vue";

import Logo from "@/components/Logo.vue";

import { getActivityMovementsReconciliated } from "@/features/activities/reconciliation";

import { ActivityType } from "@/types/activities";
import type { Movement } from "@/types/movements";
import { AccountType } from "@/types/accounts";

const router = useRouter();

const activitiesStore = useActivitiesStore();
const movementsStore = useMovementsStore();
const accountsStore = useAccountsStore();

const settingsStore = useSettingsStore();
const { settings } = storeToRefs(settingsStore);

const step = ref(0);

const newActivity = ref({
  name: undefined as string | undefined,
  date: undefined as Dayjs | undefined,
  type: undefined as ActivityType | undefined,
});
const activityCreatedId = ref<number | undefined>();
const movementCreated = ref<Movement | undefined>();

const createActivity = async () => {
  activityCreatedId.value = (
    await activitiesStore.addNewActivity(
      newActivity.value.name!,
      null,
      newActivity.value.date!,
      newActivity.value.type!,
      null,
      null,
      null
    )
  ).id;
  step.value += 1;
};

const activityCreated = computed(() => {
  if (activityCreatedId.value === undefined) return;
  return activitiesStore.getActivityDataById(activityCreatedId.value);
});

const createFakeMovement = async () => {
  if (!activityCreated.value) return;

  const bankAccount = accountsStore.accounts.find(
    (a) => a.type === AccountType.BANK_ACCOUNT
  );
  if (!bankAccount) return;

  movementCreated.value = await movementsStore.addNewMovement(
    activityCreated.value.activity.date,
    -1 * activityCreated.value.amount,
    bankAccount.id,
    "RESTAURANT CARD *3210"
  );
  step.value += 1;
};

const skip = async () => {
  settings.value.onboarded = true;
  router.push({ name: "activities" });
};

const finish = async () => {
  settings.value.onboarded = true;
  router.push({ name: "movements" });
};
</script>

<template>
  <div class="h-full w-full overflow-y-auto min-h-0">
    <div
      class="max-w-xl w-full min-h-full px-4 py-20 flex flex-col justify-center mx-auto"
    >
      <div v-if="step === 0" class="flex flex-col">
        <Logo class="h-6 w-6" />
        <div class="font-medium mt-4 text-slate-700 text-3xl">
          Welcome to Banqr!
        </div>

        <div class="mt-10 text-slate-500">
          We are really happy to have you here! Let's setup your account and
          show you how to use Banqr.
        </div>

        <div class="mt-10 text-slate-400">
          First, select your main currency.
        </div>
        <TSelect
          v-model="settings.currency"
          :items="
            // @ts-ignore
            Intl.supportedValuesOf('currency').map((c) => ({
              value: c,
              text: c,
            }))
          "
          class="w-full mt-3 max-w-md"
        />

        <div class="flex items-center mt-10 w-full">
          <div class="flex-1" />
          <TBtn @click="step += 1">Continue</TBtn>
        </div>
      </div>

      <template v-if="step >= 1">
        <Logo class="h-6 w-6" />
        <div class="font-medium mt-4 text-slate-700 text-3xl">
          <template v-if="step < 3"> Create your first activity </template>
          <template v-else-if="step < 5"> Reconciliate this activity </template>
          <template v-else> Hoora! 🎉</template>
        </div>

        <template v-if="step === 1">
          <div class="mt-8 text-slate-500">
            An activity in Banqr is something that you do that involves money.
            For example, going to restaurant, shopping some groceries, buying a
            concert ticket, paying your rent, ...
          </div>

          <div class="mt-8 text-slate-500">
            Let's try to create your first one.
          </div>

          <div class="mt-8 text-slate-600 font-medium">
            Let's say you were at a restaurant with a friend yesterday.
          </div>

          <div class="mt-8 text-slate-400 font-medium text-sm">
            Complete the title of this activity.
          </div>
          <TTextField
            v-model="newActivity.name"
            placeholder="Restaurant"
            autofocus
            class="w-full mt-2"
          />

          <div class="flex items-center mt-8">
            <TBtn outlined @click="skip">Skip</TBtn>
            <div class="flex-1" />
            <TBtn :disabled="!newActivity.name" @click="step += 1">Next</TBtn>
          </div>
        </template>
        <template v-else-if="step === 2">
          <div class="mt-8 text-slate-500">
            Good! Now let's add a date and a type.
          </div>

          <div class="mt-8 flex flex-col text-sm">
            <div class="text-slate-400 font-medium text-sm">
              Complete the date of this activity.
            </div>
            <div class="flex-1" />
            <TDatePicker v-model="newActivity.date" class="w-full mt-2" />
          </div>

          <div class="mt-4 flex flex-col text-sm">
            <div class="text-slate-400 font-medium text-sm">
              Complete the type of this activity.
            </div>
            <div class="flex-1" />
            <TSelect
              v-model="newActivity.type"
              :items="[
                { id: ActivityType.REVENUE, name: 'Revenue' },
                { id: ActivityType.EXPENSE, name: 'Expense' },
                { id: ActivityType.INVESTMENT, name: 'Investment' },
                { id: ActivityType.NEUTRAL, name: 'Neutral' },
              ]"
              item-value="id"
              item-text="name"
              class="w-full mt-2"
            />
          </div>

          <div class="flex items-center mt-8">
            <TBtn outlined @click="step -= 1">Back</TBtn>
            <div class="flex-1" />
            <TBtn
              :disabled="
                newActivity.date === undefined ||
                newActivity.type !== ActivityType.EXPENSE
              "
              @click="createActivity"
            >
              Create activity
            </TBtn>
          </div>
        </template>
        <template v-else-if="step === 3">
          <div class="mt-8 text-slate-500">
            Great! This is only what is needed to create an activity. Now to add
            some numbers, you need to add a transaction to explain how the money
            moved.
          </div>

          <div class="mt-8 text-slate-600">
            You paid 26,5€ with your Credit card, linked to your Bank account.
            The destination for this money is the general account "Expense".
          </div>

          <div class="mt-8 text-slate-400 font-medium text-sm">
            Create a transaction to explain how the money has moved.
          </div>

          <ActivityTransactions
            :activity-data="activityCreated!"
            class="mt-4 border sm:-mx-16"
          />

          <div class="flex items-center mt-8">
            <TBtn outlined @click="skip">Skip</TBtn>
            <div class="flex-1" />
            <TBtn @click="createFakeMovement"> Next </TBtn>
          </div>
        </template>
        <template v-else-if="step === 4">
          <div class="mt-8 text-slate-600">
            Great! Finally, thanks to your bank account movements import, you
            can reconciliate your amount spent by card.
          </div>

          <div class="mt-8 text-slate-400 font-medium text-sm">
            Associate a movement to confirm that the number is matching with the
            one on your bank account.
          </div>

          <ActivityMovements
            :activity-data="activityCreated!"
            class="mt-4 border sm:-mx-16"
          />

          <div class="flex items-center mt-8">
            <TBtn outlined @click="skip">Skip</TBtn>
            <div class="flex-1" />
            <TBtn
              :disabled="!getActivityMovementsReconciliated(activityCreated!.activity)"
              @click="step += 1"
            >
              Next
            </TBtn>
          </div>
        </template>
        <template v-else-if="step === 5">
          <div class="mt-8 text-slate-700">
            Congrats! You just created your first reconciliated activity in
            Banqr!
          </div>

          <div class="mt-8 text-slate-500">
            By reconciliating and explaining where the money goes, you stay
            assured that everything is taken care of.
          </div>

          <div class="mt-8 text-slate-700 font-medium">
            Activities can also be created directly from movements. Import your
            bank account movements of this month to create activities
            retro-actively.
          </div>

          <div class="flex items-center mt-8">
            <TBtn outlined @click="skip">Skip and go the app</TBtn>
            <div class="flex-1" />
            <ImportMovementsButton
              large
              class="ml-2"
              primary
              @imported="finish"
            />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
