import type dayjs from "dayjs";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { supabase } from "@/lib/supabase";

import {
  addRecord,
  deleteRecord,
  deleteRecordLocally,
  fetchRecords,
  ingestRecords,
  updateRecordLocally,
} from "@/features/storage";
import { TABLES } from "@/features/indexeddb";
import {
  getActivityStatus,
  getActivityTransactionsReconciliationSum,
} from "@/features/activities/reconciliation";

import {
  type Activity,
  type ActivityCategory,
  type ActivitySubCategory,
  ActivityType,
  type ActivitySharing,
  type ActivityData,
} from "@/types/activities";

import { useMovementsStore } from "./movements";
import { useTransactionsStore } from "./transactions";
import { useBudgetsStore } from "./budgets";
import { useViewsStore } from "./views";
import { useAuthStore } from "./auth";

export const ACTIVITY_TYPES_COLOR = {
  [ActivityType.EXPENSE]: "red", // "bg-red-300"
  [ActivityType.REVENUE]: "green", // "bg-green-300"
  [ActivityType.INVESTMENT]: "orange", // "bg-orange-300"
  [ActivityType.NEUTRAL]: "slate", // "bg-slate-300"
};

export const ACTIVITY_TYPES_NAME = {
  [ActivityType.EXPENSE]: "Expense",
  [ActivityType.REVENUE]: "Revenue",
  [ActivityType.INVESTMENT]: "Investment",
  [ActivityType.NEUTRAL]: "Neutral",
};

export const useActivitiesStore = defineStore("activities", () => {
  const activities = ref<Activity[]>([]);
  const activitiesSharing = ref<ActivitySharing[]>([]);
  const categories = ref<ActivityCategory[]>([]);
  const subcategories = ref<ActivitySubCategory[]>([]);

  const showTransactions = ref(false);

  const focusedActivity = ref<number | null>(null);

  const load = async () => {
    await Promise.all([
      fetchRecords(categories, TABLES.ACTIVITY_CATEGORIES),
      fetchRecords(subcategories, TABLES.ACTIVITY_SUBCATEGORIES),
      fetchRecords(activities, TABLES.ACTIVITIES),
      fetchRecords(activitiesSharing, TABLES.ACTIVITIES_SHARING),
    ]);
  };

  const activitiesData = computed<ActivityData[]>(() => {
    const transactionsStore = useTransactionsStore();

    return activities.value.map((a) => {
      const transactions = transactionsStore.getTransactionsByActivity(a.id);
      return {
        id: a.id,
        activity: a,
        amount: getActivityTransactionsReconciliationSum(a.type, transactions),
        status: getActivityStatus(a),
        transactions,
      };
    });
  });

  const getActivityDataById = (
    activityId: number
  ): ActivityData | undefined => {
    return activitiesData.value.find((a) => a.activity.id === activityId);
  };

  const getActivityByNumber = (
    activityNumber: number
  ): Activity | undefined => {
    return activities.value.find((a) => a.number === activityNumber);
  };

  const getActivitySharing = (activityId: number): string[] => {
    const { user } = useAuthStore();

    const mySharing = activitiesSharing.value.find(
      (as) => as.activity === activityId && as.user === user!.id
    );
    if (!mySharing) return [];

    const allSharings = activitiesSharing.value.filter(
      (as) => as.sharing === mySharing.sharing
    );

    return allSharings.filter((a) => a.user !== user!.id).map((s) => s.user);
  };

  const getActivitySharingWithoutActivity = (): ActivitySharing[] => {
    const { user } = useAuthStore();

    return activitiesSharing.value.filter(
      (as) => as.user === user!.id && as.activity === null
    );
  };

  const addNewActivity = async (
    name: string,
    description: string | null,
    date: dayjs.Dayjs,
    type: ActivityType,
    category: number | null,
    subcategory: number | null,
    project: number | null
  ): Promise<Activity> => {
    const { user } = useAuthStore();

    const newActivityNumber =
      Math.max(...activities.value.map((a) => a.number), 0) + 1;

    const newActivity: Activity = await addRecord(
      {
        user: user!.id,
        number: newActivityNumber,
        name,
        description,
        date,
        type,
        category,
        subcategory,
        project,
      },
      TABLES.ACTIVITIES
    );

    activities.value.push(newActivity);
    return newActivity;
  };

  const deleteActivity = async (activityId: number) => {
    const activity = activities.value.find((a) => a.id === activityId);
    if (!activity) return;

    const movementStore = useMovementsStore();
    movementStore
      .getMovementsActivitiesByActivity(activityId)
      .forEach((movementActivity) => {
        movementStore.deleteMovementActivity(movementActivity.id, true);
      });
    useTransactionsStore().deleteTransactionsFromActivity(activityId, true);

    activities.value.splice(activities.value.indexOf(activity), 1);
    await deleteRecord(activityId, TABLES.ACTIVITIES);
  };

  // Activity sharing

  const shareActivity = async (activityId: number, toUserId: string) => {
    const shareActivityRequest = await supabase.rpc("share_activity", {
      activity_id: activityId,
      to_user_id: toUserId,
    });
    if (shareActivityRequest.error) return;

    const fetchActivitySharingRequest = await supabase
      .from(TABLES.ACTIVITIES_SHARING)
      .select()
      .eq("sharing", shareActivityRequest.data);
    if (shareActivityRequest.error) return;

    await ingestRecords(
      activitiesSharing,
      fetchActivitySharingRequest.data!,
      TABLES.ACTIVITIES_SHARING
    );
  };

  const unshareActivity = async (activityId: number, fromUserId: string) => {
    const { user } = useAuthStore();
    const mySharing = activitiesSharing.value.find(
      (as) => as.activity === activityId && as.user === user!.id
    );
    if (!mySharing) return;

    const otherSharing = activitiesSharing.value.find(
      (as) => as.sharing === mySharing.sharing && as.user === fromUserId
    );
    if (!otherSharing) return;

    const { transactions } = useTransactionsStore();
    await Promise.all(
      transactions
        .filter((t) => t.activity === otherSharing?.activity)
        .map((t) => {
          return deleteRecordLocally(t.id, TABLES.TRANSACTIONS);
        })
    );

    activitiesSharing.value.splice(
      activitiesSharing.value.indexOf(otherSharing),
      1
    );
    await deleteRecord(otherSharing.id, TABLES.ACTIVITIES_SHARING);
  };

  // Categories

  const addNewCategory = async (
    name: string,
    type: ActivityType
  ): Promise<ActivityCategory> => {
    const { user } = useAuthStore();

    const newCategory: ActivityCategory = await addRecord(
      {
        user: user!.id,
        name,
        type,
      },
      TABLES.ACTIVITY_CATEGORIES
    );

    categories.value.push(newCategory);
    return newCategory;
  };

  const addNewSubCategory = async (
    name: string,
    category: number
  ): Promise<ActivitySubCategory> => {
    const { user } = useAuthStore();

    const newSubCategory: ActivitySubCategory = await addRecord(
      {
        user: user!.id,
        name,
        category,
      },
      TABLES.ACTIVITY_SUBCATEGORIES
    );

    subcategories.value.push(newSubCategory);
    return newSubCategory;
  };

  const deleteCategory = async (categoryId: number) => {
    const category = categories.value.find((c) => c.id === categoryId);
    if (!category) return;

    await Promise.all(
      activities.value
        .filter((a) => a.category === categoryId)
        .map((a) => {
          return updateRecordLocally(a, TABLES.ACTIVITIES, (a) => {
            a.category = null;
            a.subcategory = null;
            return a;
          });
        })
    );
    await Promise.all(
      subcategories.value
        .filter((sc) => sc.category === categoryId)
        .map((sc) => {
          return deleteRecordLocally(sc.id, TABLES.ACTIVITY_SUBCATEGORIES);
        })
    );

    await useBudgetsStore().deleteAllCategoryBudgets(categoryId, true);

    useViewsStore().deleteCategory(categoryId);

    categories.value.splice(categories.value.indexOf(category), 1);
    await deleteRecord(categoryId, TABLES.ACTIVITY_CATEGORIES);
  };

  const deleteSubCategory = async (subcategoryId: number) => {
    const subcategory = subcategories.value.find(
      (sc) => sc.id === subcategoryId
    );
    if (!subcategory) return;

    await Promise.all(
      activities.value
        .filter((a) => a.subcategory === subcategoryId)
        .map((a) => {
          return updateRecordLocally(a, TABLES.ACTIVITIES, (a) => {
            a.subcategory = null;
            return a;
          });
        })
    );

    useViewsStore().deleteSubcategory(subcategoryId);

    subcategories.value.splice(subcategories.value.indexOf(subcategory), 1);
    await deleteRecord(subcategoryId, TABLES.ACTIVITY_SUBCATEGORIES);
  };

  return {
    load,

    activities,
    categories,
    subcategories,
    activitiesData,
    showTransactions,

    getActivityByNumber,
    getActivityDataById,
    getActivitySharing,
    getActivitySharingWithoutActivity,

    addNewActivity,
    deleteActivity,

    focusedActivity,

    activitiesSharing,
    shareActivity,
    unshareActivity,

    addNewCategory,
    addNewSubCategory,
    deleteCategory,
    deleteSubCategory,
  };
});
