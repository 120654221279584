import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

import { supabase } from "@/lib/supabase";

import type { Contact } from "@/types/contacts";

import { useAuthStore } from "./auth";

export const useContactsStore = defineStore("contacts", () => {
  const contacts = useLocalStorage<Contact[]>("contacts", []);

  const load = async () => {
    const { data, error } = await supabase
      .from("contacts")
      .select(
        `
        id,
        user,
        contact ( id, name, avatar ),
        liability_to_account,
        liability_from_account
        `
      )
      .returns<
        {
          id: number;
          user: string;
          contact: { id: string; name: string; avatar: string | null };
          liability_to_account: number | null;
          liability_from_account: number | null;
        }[]
      >();
    if (!data) return;

    contacts.value = data.map((d) => ({
      id: d.id,
      user: d.user,
      contactId: d.contact.id,
      contactName: d.contact.name,
      contactAvatar: d.contact.avatar,
      liabilityToAccount: d.liability_to_account,
      liabilityFromAccount: d.liability_from_account,
    }));
  };

  const getUserName = (userId: string): string | undefined => {
    const { user } = useAuthStore();

    if (userId === user!.id) return user!.user_metadata.name;
    return contacts.value.find((c) => c.contactId === userId)?.contactName;
  };

  const getUserAvatar = (userId: string): string | null | undefined => {
    const { user } = useAuthStore();

    if (userId === user!.id) return user!.user_metadata.avatar_url;
    return contacts.value.find((c) => c.contactId === userId)?.contactAvatar;
  };

  return {
    load,

    contacts,

    getUserName,
    getUserAvatar,
  };
});
