<script setup lang="ts">
import { supabase } from "@/lib/supabase";

import Logo from "@/components/Logo.vue";

const signIn = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: "google",
    options: {
      redirectTo: `${window.location.origin}/callback`,
    },
  });
};
</script>

<template>
  <div class="h-full w-full flex items-center justify-center">
    <div class="flex flex-col items-center w-full">
      <Logo class="h-20 w-20" />
      <div class="font-medium mt-8 text-slate-700 text-lg">
        Create your Banqr account
      </div>
      <div class="mt-8 px-4 w-full sm:max-w-[400px]">
        <TBtn class="w-full" large @click="signIn">Continue with Google</TBtn>
      </div>
    </div>
  </div>
</template>
