<script setup lang="ts">
import { usePeriodsStore } from "@/stores/periods";
import type { Period } from "@/types/periods";
import { computed } from "vue";

const props = defineProps<{
  period: Period;
}>();

const label = computed(() => usePeriodsStore().getPeriodLabel(props.period));

const chipClass = computed(() => {
  if (label.value === "Completed") return "bg-slate-100 text-slate-600 border";
  else if (label.value === "Current") return "bg-indigo-400 text-white";
  return "bg-stone-100 text-slate-700 border";
});
</script>

<template>
  <span class="px-2 py-0.5 text-xs rounded" :class="chipClass">
    {{ label }}
  </span>
</template>
