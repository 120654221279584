import dayjs from "dayjs";
import { defineStore } from "pinia";
import { ref } from "vue";

import {
  addRecord,
  deleteRecord,
  fetchRecords,
  updateRecordLocally,
} from "@/features/storage";
import { TABLES } from "@/features/indexeddb";

import type { Project, ProjectStatus } from "@/types/projects";
import type { ActivityType } from "@/types/activities";

import { useActivitiesStore } from "./activities";
import { useAuthStore } from "./auth";

export const useProjectsStore = defineStore("projects", () => {
  const projects = ref<Project[]>([]);

  const viewFilters = ref({
    category: null as number | null,
    subcategory: null as number | null,
    activityType: null as ActivityType | null,
  });

  const load = async () => {
    await fetchRecords(projects, TABLES.PROJECTS);
  };

  const getProjectStatus = (project: Project): ProjectStatus => {
    const now = dayjs();
    if (
      project.start_date !== null &&
      project.start_date.startOf("day") < now &&
      project.end_date === null
    ) {
      return "in progress";
    } else if (
      project.end_date !== null &&
      now > project.end_date.endOf("day")
    ) {
      return "completed";
    }
    return "scheduled";
  };

  const getProjectById = (projectId: number): Project | undefined => {
    return projects.value.find((p) => p.id === projectId);
  };

  const addNewProject = async (
    name: string,
    emoji: string | null,
    start_date: dayjs.Dayjs | null,
    end_date: dayjs.Dayjs | null
  ): Promise<Project> => {
    const { user } = useAuthStore();

    const newProject: Project = await addRecord(
      {
        user: user!.id,
        name,
        emoji,
        start_date,
        end_date,
      },
      TABLES.PROJECTS
    );

    projects.value.push(newProject);
    return newProject;
  };

  const deleteProject = async (projectId: number) => {
    const project = getProjectById(projectId);
    if (!project) return;

    useActivitiesStore()
      .activities.filter((activity) => activity.project === project.id)
      .forEach((activity) =>
        updateRecordLocally(activity, TABLES.ACTIVITIES, (a) => {
          a.project = null;
          return a;
        })
      );

    projects.value.splice(projects.value.indexOf(project), 1);
    await deleteRecord(projectId, TABLES.PROJECTS);
  };

  return {
    load,

    projects,
    viewFilters,

    getProjectStatus,

    getProjectById,

    addNewProject,
    deleteProject,
  };
});
