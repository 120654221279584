<script setup lang="ts">
import dayjs from "dayjs";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useMotion } from "@vueuse/motion";

import { useBudgetsStore } from "@/stores/budgets";
import {
  ACTIVITY_TYPES_COLOR,
  ACTIVITY_TYPES_NAME,
  useActivitiesStore,
} from "@/stores/activities";

import BudgetGauge from "@/components/budget/BudgetGauge.vue";
import { usePeriodsStore } from "@/stores/periods";
import { ActivityType } from "@/types/activities";
import { getCurrencyFormatter } from "@/utils/currency";

const budgetStore = useBudgetsStore();
const { focusedBudget, budgets } = storeToRefs(budgetStore);

const activitiesStore = useActivitiesStore();
const { categories } = storeToRefs(activitiesStore);

const periodsStore = usePeriodsStore();
const { periodsActivityData, periodsBudgetData } = storeToRefs(periodsStore);

const mainElement = ref<HTMLElement>();
const showDeleteModal = ref(false);
const showBudget = ref(focusedBudget.value !== null);
const showConfiguration = ref(true);
const showYearValues = ref(true);

watch(focusedBudget, () => {
  if (focusedBudget.value !== null) {
    if (showBudget.value) return;

    showBudget.value = true;
    useMotion(mainElement, {
      initial: {
        marginLeft: window.innerWidth > 640 ? -576 : 0,
        x: 576,
      },
      enter: {
        marginLeft: 0,
        x: 0,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });
  } else {
    useMotion(mainElement, {
      initial: {
        marginLeft: 0,
        x: 0,
      },
      enter: {
        marginLeft: window.innerWidth > 640 ? -576 : 0,
        x: 576,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });

    setTimeout(() => {
      showBudget.value = false;
    }, 100);
  }
});

const budget = computed(() => {
  if (focusedBudget.value === null) return;
  return budgets.value.find((b) => b.id === focusedBudget.value);
});

const handleBudgetMenuClick = (event: string) => {
  if (event === "delete") {
    showDeleteModal.value = true;
  }
};

const deleteBudget = () => {
  if (!budget.value) return;
  budgetStore.deleteBudget(budget.value.id);
  focusedBudget.value = null;
};

const close = () => {
  focusedBudget.value = null;
};

// Budget of activity type

const activityTypeKey = computed<
  "revenue" | "expense" | "investment" | undefined
>(() => {
  if (!budget.value) return;
  else if (!budget.value.type) return;

  if (budget.value.type === ActivityType.REVENUE) return "revenue";
  else if (budget.value.type === ActivityType.EXPENSE) return "expense";
  else return "investment";
});

const activityTypeValue = computed<number | undefined>(() => {
  if (!budget.value) return;
  else if (!activityTypeKey.value) return;

  const periodData = periodsActivityData.value.find(
    (p) => p.month === budget.value!.month && p.year === budget.value!.year
  );
  if (!periodData) return;

  return periodData[activityTypeKey.value]!;
});

const activityTypeBudget = computed<number | undefined>(() => {
  if (!budget.value) return;
  else if (!activityTypeKey.value) return;

  const periodData = periodsBudgetData.value.find(
    (p) => p.month === budget.value!.month && p.year === budget.value!.year
  );
  if (!periodData) return;

  return periodData[activityTypeKey.value]!;
});

const previousActivityTypeValue = computed<number | undefined>(() => {
  if (!budget.value) return;
  else if (!activityTypeKey.value) return;

  const previousPeriodDate = dayjs()
    .month(budget.value.month)
    .year(budget.value.year)
    .subtract(1, "month");

  const previousPeriodActivityData = periodsActivityData.value.find(
    (p) =>
      p.month === previousPeriodDate.month() &&
      p.year === previousPeriodDate.year()
  );
  if (!previousPeriodActivityData) return;

  return previousPeriodActivityData[activityTypeKey.value];
});

const previousActivityTypeBudget = computed<number | undefined>(() => {
  if (!budget.value) return;
  else if (!activityTypeKey.value) return;

  const previousPeriodDate = dayjs()
    .month(budget.value.month)
    .year(budget.value.year)
    .subtract(1, "month");

  const previousPeriodBudgetData = periodsBudgetData.value!.find(
    (p) =>
      p.month === previousPeriodDate.month() &&
      p.year === previousPeriodDate.year()
  );
  if (!previousPeriodBudgetData) return;

  return previousPeriodBudgetData[activityTypeKey.value];
});

const activityTypeAcculumating = computed(() => {
  if (previousActivityTypeBudget.value === undefined) return 0;
  else if (previousActivityTypeValue.value === undefined) return 0;

  return _.round(
    previousActivityTypeBudget.value - previousActivityTypeValue.value,
    2
  );
});

// Budget of category

const activityCategoryValue = computed<number | undefined>(() => {
  if (!budget.value) return;
  else if (budget.value.category === undefined) return;

  const periodData = periodsActivityData.value.find(
    (p) => p.month === budget.value!.month && p.year === budget.value!.year
  );
  if (!periodData) return;

  return (
    periodData.categories.find((c) => c.category === budget.value!.category)
      ?.value ?? 0
  );
});

const activityCategoryBudget = computed(() => {
  if (!budget.value) return;
  else if (budget.value.category === undefined) return;

  const periodData = periodsBudgetData.value.find(
    (p) => p.month === budget.value!.month && p.year === budget.value!.year
  );
  if (!periodData) return;

  return periodData.categories.find(
    (c) => c.category === budget.value!.category
  );
});

const previousActivityCategoryValue = computed<number | undefined>(() => {
  if (!budget.value) return;
  else if (budget.value.category === undefined) return;

  const previousPeriodDate = dayjs()
    .month(budget.value.month)
    .year(budget.value.year)
    .subtract(1, "month");

  return (
    periodsActivityData.value
      .find(
        (p) =>
          p.month === previousPeriodDate.month() &&
          p.year === previousPeriodDate.year()
      )
      ?.categories.find((c) => c.category === budget.value!.category)?.value ??
    0
  );
});

const previousActivityCategoryBudget = computed(() => {
  if (!budget.value) return;
  else if (budget.value.category === undefined) return;

  const previousPeriodDate = dayjs()
    .month(budget.value.month)
    .year(budget.value.year)
    .subtract(1, "month");

  return periodsBudgetData.value
    .find(
      (p) =>
        p.month === previousPeriodDate.month() &&
        p.year === previousPeriodDate.year()
    )
    ?.categories.find((c) => c.category === budget.value!.category);
});

const activityCategoryAcculumating = computed(() => {
  if (previousActivityCategoryBudget.value === undefined) return 0;
  else if (previousActivityCategoryValue.value === undefined) return 0;

  return _.round(
    previousActivityCategoryBudget.value.value -
      previousActivityCategoryValue.value,
    2
  );
});
</script>

<template>
  <div
    v-if="showBudget"
    ref="mainElement"
    class="absolute top-0 left-0 sm:relative flex flex-col w-full sm:w-[525px] max-w-full min-h-full overflow-hidden bg-white border-l shadow-xl"
  >
    <template v-if="budget">
      <div
        class="flex items-center px-4 sm:px-6 border-b w-full flex-shrink-0 h-14 bg-slate-50"
      >
        <button
          type="button"
          class="inline-flex items-center justify-center w-6 h-8 ml-8 sm:ml-0 mr-4"
          @click="close"
        >
          <i
            class="mdi mdi-chevron-right mdi-24px transition text-slate-400 hover:text-slate-900"
          />
        </button>

        <div class="flex-1 block sm:hidden" />

        <TMenu
          class="mx-0.5 mt-1 sm:hidden"
          :items="[{ value: 'delete', text: 'Delete', icon: 'delete' }]"
          @click:item="handleBudgetMenuClick"
        />

        <div class="flex-1 hidden sm:flex items-center justify-end gap-3 mr-3">
          <TDeleteConfirmation
            v-model="showDeleteModal"
            description="Are you sure you want to delete this budget?"
            @confirm="deleteBudget"
          >
            <template #default="{ open }">
              <button
                type="button"
                class="inline-flex items-center justify-center w-6 h-8"
                @click="open"
              >
                <i
                  class="mdi mdi-delete transition text-slate-400 hover:text-slate-900"
                />
              </button>
            </template>
          </TDeleteConfirmation>
        </div>
      </div>

      <div class="flex-1 overflow-y-auto pb-20">
        <div class="px-4 sm:px-8 py-6 border-b">
          <div class="flex items-center justify-between">
            <div>
              <div
                class="mb-1 text-sm flex items-center text-slate-500 font-medium"
              >
                {{
                  dayjs()
                    .month(budget.month)
                    .year(budget.year)
                    .format("MMMM YYYY")
                }}
              </div>

              <div
                class="text-slate-600 text-ellipsis overflow-hidden whitespace-nowrap min-w-0 font-bold"
              >
                <div v-if="budget.type" class="flex items-center">
                  <div
                    class="h-3 w-3 rounded-xl shrink-0 mr-3"
                    :class="`bg-${ACTIVITY_TYPES_COLOR[budget.type]}-300`"
                  />
                  <span class="font-bold text-slate-700">
                    {{ ACTIVITY_TYPES_NAME[budget.type] }}
                  </span>
                </div>
                <div v-else class="flex items-center">
                  <div
                    class="h-3 w-3 rounded-xl shrink-0 mr-3"
                    :class="`bg-${ACTIVITY_TYPES_COLOR[categories.find((c) => c.id === budget!.category)!.type]}-300`"
                  />
                  <span class="font-bold text-slate-400">
                    {{
                      ACTIVITY_TYPES_NAME[
                        categories.find((c) => c.id === budget!.category)!.type
                      ]
                    }}
                  </span>

                  <i class="mdi mdi-chevron-right text-slate-700 mx-2" />

                  <i class="mdi mdi-tag text-slate-700" />
                  <span class="font-bold text-slate-700 ml-2">
                    {{
                      categories.find((c) => c.id === budget!.category)!.name
                    }}
                  </span>
                </div>
              </div>
            </div>

            <BudgetGauge
              v-if="budget.type"
              :budget="activityTypeBudget!"
              :value="activityTypeValue!"
              :activity-type="budget.type"
              huge
            />
            <BudgetGauge
              v-else
              :budget="
                activityCategoryBudget ? activityCategoryBudget.value : 0
              "
              :value="activityCategoryValue!"
              :activity-type="categories.find((c) => c.id === budget!.category)!.type"
              huge
            />
          </div>

          <div class="flex items-center mt-8">
            <div class="font-medium text-slate-800 text-sm">Total budget</div>

            <div class="flex-1" />
            <div class="font-semibold text-slate-400">
              <template v-if="budget.type">
                {{ getCurrencyFormatter().format(activityTypeValue!) }}
              </template>
              <template v-else>
                {{ getCurrencyFormatter().format(activityCategoryValue!) }}
              </template>
            </div>
            <span class="text-slate-400 mx-2">/</span>
            <div class="font-semibold text-slate-700">
              <template v-if="budget.type">
                {{ getCurrencyFormatter().format(activityTypeBudget!) }}
              </template>
              <template v-else>
                {{
                  getCurrencyFormatter().format(
                    activityCategoryBudget ? activityCategoryBudget.value : 0
                  )
                }}
              </template>
            </div>
          </div>
        </div>

        <div class="py-6 px-4 sm:px-8 border-b">
          <div class="flex">
            <div
              class="-ml-2 text-sm font-medium text-slate-800 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
              @click="showConfiguration = !showConfiguration"
            >
              Configuration
              <i
                class="mdi ml-2"
                :class="showConfiguration ? 'mdi-menu-down' : 'mdi-menu-up'"
              />
            </div>
          </div>

          <div v-show="showConfiguration" class="pt-4">
            <div class="flex items-center justify-between mb-2">
              <div class="text-sm text-slate-400">Initial budget</div>

              <TAmountInput
                v-model="budget.value"
                borderless
                class="h-8 text-sm font-medium text-slate-800"
              />
            </div>

            <div class="h-[1px] bg-gray-200 my-4 -mx-3" />

            <div class="flex items-center">
              <div
                class="-ml-2 text-sm font-medium text-slate-600 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
                @click="budget.accumulating = !budget.accumulating"
              >
                <input
                  v-model="budget.accumulating"
                  name="budget-check-accumulating"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:outline-none mr-2"
                />
                Accumulation
              </div>

              <div class="flex-1" />
              <div v-if="budget.accumulating" class="items-center flex text-sm">
                <span
                  class="mr-2"
                  :class="
                    (budget.type
                      ? activityTypeAcculumating
                      : activityCategoryAcculumating) > 0
                      ? 'text-emerald-400'
                      : 'text-red-400'
                  "
                >
                  <i
                    class="mdi mdi-arrow-down"
                    :class="
                      (budget.type
                        ? activityTypeAcculumating
                        : activityCategoryAcculumating) > 0
                        ? 'mdi-plus-circle'
                        : 'mdi-minus-circle'
                    "
                  />
                </span>

                <span class="text-slate-800 font-medium">
                  {{
                    getCurrencyFormatter().format(
                      budget.type
                        ? activityTypeAcculumating
                        : activityCategoryAcculumating
                    )
                  }}
                </span>
              </div>
            </div>

            <div v-show="budget.accumulating" class="pt-4 text-sm">
              <div class="flex items-center justify-between mb-2 h-6">
                <div class="text-slate-400">Previous value</div>
                <div class="text-slate-500 text-right">
                  {{
                    getCurrencyFormatter().format(
                      (budget.type
                        ? previousActivityTypeValue
                        : previousActivityCategoryValue) ?? 0
                    )
                  }}
                </div>
              </div>

              <div class="flex items-center justify-between mb-2 h-6">
                <div class="text-slate-400">Previous budget</div>
                <div class="text-slate-500 text-right">
                  {{
                    getCurrencyFormatter().format(
                      (budget.type
                        ? previousActivityTypeBudget
                        : previousActivityCategoryBudget?.value) ?? 0
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="py-6 px-4 sm:px-8 border-b">
          <div class="flex">
            <div
              class="-ml-2 text-sm font-medium text-slate-800 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
              @click="showYearValues = !showYearValues"
            >
              Year values
              <i
                class="mdi ml-2"
                :class="showYearValues ? 'mdi-menu-down' : 'mdi-menu-up'"
              />
            </div>
          </div>

          <div v-show="showYearValues" class="pt-4">
            <div
              v-for="(period, index) in periodsActivityData.filter(
                (p) => p.year === budget!.year
              ).reverse()"
              :key="index"
              class="flex items-center text-sm h-10 border-b -mx-3 px-3"
              :class="[
                period.month === budget.month && period.year === budget.year
                  ? 'bg-indigo-50'
                  : 'hover:bg-slate-50',
                index === 0 ? 'border-t' : '',
              ]"
            >
              <div
                :class="
                  period.month === budget.month && period.year === budget.year
                    ? 'font-medium text-slate-600'
                    : 'text-slate-400'
                "
              >
                {{
                  dayjs()
                    .month(period.month)
                    .year(period.year)
                    .format("MMMM YYYY")
                }}
              </div>

              <div class="flex-1" />

              <div
                class="whitespace-nowrap text-slate-500 text-right w-24 px-4"
              >
                <template v-if="budget.type">
                  {{ getCurrencyFormatter().format(period[activityTypeKey!]) }}
                </template>
                <template
                  v-else-if="period.categories.find(
                        (c) => c.category === budget!.category
                      )"
                >
                  {{
                    getCurrencyFormatter().format(
                      period.categories.find(
                        (c) => c.category === budget!.category
                      )!.value
                    )
                  }}
                </template>
              </div>

              <div class="text-slate-300 mx-2">/</div>

              <div
                class="whitespace-nowrap text-slate-700 text-right w-24 px-2 mr-2"
              >
                <template v-if="budget.type">
                  {{
                    getCurrencyFormatter().format(
                      periodsBudgetData
                        .filter((p) => p.year === budget!.year)
                        .reverse()[index][activityTypeKey!]
                    )
                  }}
                </template>
                <template
                  v-else-if="periodsBudgetData
                        .filter((p) => p.year === budget!.year).reverse()
                        [index].categories.find(
                          (c) => c.category === budget!.category
                        )"
                >
                  {{
                    getCurrencyFormatter().format(
                      periodsBudgetData
                        .filter((p) => p.year === budget!.year)
                        .reverse()
                        [index].categories.find(
                          (c) => c.category === budget!.category
                        )?.value ?? 0
                    )
                  }}
                </template>
              </div>

              <BudgetGauge
                v-if="budget.type"
                :budget="periodsBudgetData.reverse()[index][activityTypeKey!]"
                :value="period[activityTypeKey!]"
                :activity-type="budget.type"
                minimize
              />
              <BudgetGauge
                v-else-if="periodsBudgetData
                        .filter((p) => p.year === budget!.year).reverse()
                        [index].categories.find(
                          (c) => c.category === budget!.category
                        )"
                :budget="
                  periodsBudgetData
                    .filter((p) => p.year === budget!.year).reverse()
                    [index].categories.find(
                      (c) => c.category === budget!.category
                    )?.value ?? 0"
                :value="
                  period.categories.find(
                    (c) => c.category === budget!.category
                  )?.value ?? 0"
                :activity-type="
                  categories.find((c) => c.id === budget?.category)!.type
                "
                minimize
              />
              <div v-else class="w-3" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
