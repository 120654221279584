<script setup lang="ts">
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

import { Menu, MenuButton, MenuItems, MenuItem, Portal } from "@headlessui/vue";

import { useAuthStore } from "@/stores/auth";
import { useActivitiesStore } from "@/stores/activities";
import { useMovementsStore } from "@/stores/movements";
import { usePeriodsStore } from "@/stores/periods";
import { useSettingsStore } from "@/stores/settings";

import { usePopper } from "@/hooks/use-popper";

import Logo from "@/components/Logo.vue";
import Title from "@/components/Title.vue";

const props = defineProps<{
  openMobile: boolean;
}>();
const emit = defineEmits(["close", "routeClick"]);

const route = useRoute();

const { user, logout } = useAuthStore();

const activitiesStore = useActivitiesStore();
const movementsStore = useMovementsStore();
const periodsStore = usePeriodsStore();
const settingsStore = useSettingsStore();

const { activitiesData } = storeToRefs(activitiesStore);
const { movements } = storeToRefs(movementsStore);
const { periodsAvailable } = storeToRefs(periodsStore);
const { settings } = storeToRefs(settingsStore);

const [trigger, container] = usePopper({
  placement: "bottom-end",
  modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
});

const activitiesReconcilateNumber = computed(() => {
  return activitiesData.value.reduce((acc, activityData) => {
    if (activityData.status === "incomplete") return acc + 1;
    return acc;
  }, 0);
});

const movementsToLinkNumber = computed(() => {
  return movements.value.reduce((acc, movement) => {
    if (
      movementsStore.getMovementReconciliationStatus(movement.id) ===
      "incomplete"
    )
      return acc + 1;
    return acc;
  }, 0);
});

const pastPeriodAvailable = computed(() => {
  const pastMonth = dayjs().subtract(1, "month");
  return periodsAvailable.value.find(
    (p) => p.month === pastMonth.month() && p.year === pastMonth.year()
  );
});
</script>

<template>
  <div class="flex items-center mt-12 lg:mt-0 h-14 justify-between border-b">
    <div class="flex items-center pl-1">
      <Logo class="h-[22px] w-[22px]" />
      <Title class="h-4 w-auto ml-2" />
    </div>

    <div class="pr-2">
      <Menu v-slot="{ open }">
        <MenuButton
          ref="trigger"
          class="inline-flex items-center justify-center w-8 h-8 transition-colors hover:bg-slate-100 shrink-0 rounded"
          :class="{ 'bg-slate-100': open }"
        >
          <img
            class="w-5 h-5 rounded-full"
            :src="user!.user_metadata.avatar_url"
            alt=""
          />
        </MenuButton>

        <Portal v-if="open">
          <MenuItems
            ref="container"
            class="w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-10 focus:outline-none z-20"
          >
            <div class="px-1 py-1">
              <MenuItem v-slot="{ active }" @click="logout">
                <button
                  class="text-sm"
                  :class="[
                    active ? 'bg-slate-100 text-slate-900' : 'text-slate-700',
                    'flex w-full items-center rounded-md px-2 py-1.5',
                  ]"
                >
                  Log out
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Portal>
      </Menu>
    </div>
  </div>

  <div class="mt-4">
    <RouterLink
      class="flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors my-1"
      :to="{ name: 'dashboard' }"
      :class="{
        'bg-slate-100': route.name === 'dashboard',
      }"
      @click="emit('routeClick')"
    >
      <i
        class="mdi mdi-view-dashboard text-slate-500 group-hover:text-slate-700 transition-colors"
      />
      <span class="text-sm ml-2 text-slate-700 font-medium">Dashboard</span>
    </RouterLink>

    <RouterLink
      class="flex items-center hover:bg-slate-100 rounded px-2 h-8 transition-colors my-1 group"
      :to="{ name: 'periods' }"
      :class="{
        'bg-slate-100': route.name === 'periods',
      }"
      @click="emit('routeClick')"
    >
      <i
        class="mdi mdi-calendar-blank text-slate-500 group-hover:text-slate-700 transition-colors"
      />
      <span class="text-sm ml-2 text-slate-700 font-medium">Periods</span>
    </RouterLink>
    <div class="ml-4 border-l pl-2">
      <RouterLink
        class="flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors my-1 text-sm text-slate-700 font-medium"
        :to="{ name: 'period', params: { period: 'current' } }"
        :class="{
          'bg-slate-100':
            route.name === 'period' && route.params.period === 'current',
        }"
        @click="emit('routeClick')"
      >
        Current
      </RouterLink>
      <RouterLink
        v-if="pastPeriodAvailable"
        class="flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors my-1 text-sm text-slate-700 font-medium"
        :to="{ name: 'period', params: { period: 'past' } }"
        :class="{
          'bg-slate-100':
            route.name === 'period' && route.params.period === 'past',
        }"
        @click="emit('routeClick')"
      >
        Past
      </RouterLink>
    </div>

    <RouterLink
      class="my-1 flex items-center hover:bg-slate-100 rounded px-2 h-8 transition-colors group"
      :to="{ name: 'projects' }"
      :class="{
        'bg-slate-100': route.name === 'projects' || route.name === 'project',
      }"
      @click="emit('routeClick')"
    >
      <i
        class="mdi mdi-book-multiple text-slate-500 group-hover:text-slate-700 transition-colors"
      />
      <span class="text-sm ml-2 text-slate-700 font-medium">Projects</span>
    </RouterLink>

    <RouterLink
      class="mt-6 flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors mb-1"
      :to="{ name: 'activities' }"
      :class="{
        'bg-slate-100':
          route.name === 'activities' && route.params.id !== 'reconciliation',
      }"
      @click="emit('routeClick')"
    >
      <i
        class="mdi mdi-book text-slate-500 group-hover:text-slate-700 transition-colors"
      />
      <span class="text-sm ml-2 text-slate-700 font-medium">Activities</span>
    </RouterLink>
    <div class="ml-4 border-l pl-2">
      <RouterLink
        class="flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors my-1 text-sm text-slate-700 font-medium"
        :to="{ name: 'activities', params: { id: 'reconciliation' } }"
        :class="{
          'bg-slate-100':
            route.name === 'activities' && route.params.id === 'reconciliation',
        }"
        @click="emit('routeClick')"
      >
        To reconciliate

        <div class="flex-1" />
        <div
          v-if="activitiesReconcilateNumber > 0"
          class="rounded bg-indigo-50 px-1.5 py-0.5 text-xs text-indigo-600 font-normal"
        >
          {{ activitiesReconcilateNumber }}
        </div>
      </RouterLink>
    </div>

    <RouterLink
      class="my-1 flex items-center hover:bg-slate-100 rounded px-2 h-8 transition-colors group"
      :to="{ name: 'budgets' }"
      :class="{
        'bg-slate-100': route.name === 'budgets',
      }"
      @click="emit('routeClick')"
    >
      <i
        class="mdi mdi-gauge text-slate-500 group-hover:text-slate-700 transition-colors"
      />
      <span class="text-sm ml-2 text-slate-700 font-medium">Budgets</span>
    </RouterLink>

    <RouterLink
      class="flex items-center hover:bg-slate-100 rounded px-2 h-8 transition-colors my-1 group"
      :to="{ name: 'movements' }"
      :class="{
        'bg-slate-100':
          route.name === 'movements' && route.params.id !== 'tolink',
      }"
      @click="emit('routeClick')"
    >
      <i
        class="mdi mdi-cash-fast text-slate-500 group-hover:text-slate-700 transition-colors"
      />
      <span class="text-sm ml-2 text-slate-700 font-medium">Movements</span>
    </RouterLink>
    <div class="ml-4 border-l pl-2">
      <RouterLink
        class="flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors my-1 text-sm text-slate-700 font-medium"
        :to="{ name: 'movements', params: { id: 'tolink' } }"
        :class="{
          'bg-slate-100':
            route.name === 'movements' && route.params.id === 'tolink',
        }"
        @click="emit('routeClick')"
      >
        To link

        <div class="flex-1" />
        <div
          v-if="movementsToLinkNumber > 0"
          class="rounded bg-indigo-50 px-1.5 py-0.5 text-xs text-indigo-600"
        >
          {{ movementsToLinkNumber }}
        </div>
      </RouterLink>
    </div>
  </div>

  <div class="flex-grow" />

  <div class="border-t pt-2 pb-3">
    <a
      href="/docs"
      target="_blank"
      class="flex items-center px-2 h-6 group transition-colors my-3"
    >
      <i
        class="mdi mdi-book-open-page-variant text-slate-400 group-hover:text-slate-700 text-sm transition-colors"
      />
      <span
        class="text-xs ml-2 text-slate-400 group-hover:text-slate-700 font-medium transition-colors"
      >
        Documentation
      </span>
    </a>
    <RouterLink
      class="flex items-center px-2 h-6 group transition-colors my-3"
      :to="{ name: 'settings' }"
      @click="emit('routeClick')"
    >
      <i
        class="mdi mdi-cog text-slate-400 group-hover:text-slate-700 text-sm transition-colors"
      />
      <span
        class="text-xs ml-2 text-slate-400 group-hover:text-slate-700 font-medium transition-colors"
      >
        Settings
      </span>
    </RouterLink>

    <a
      href="mailto:support@banqr.app"
      class="rounded border bg-slate-50 w-full px-2 py-1.5 flex items-start gap-2 hover:bg-slate-100 hover:border-gray-300 transition-colors"
    >
      <i class="mdi mdi-comment-text-outline text-slate-500" />
      <div class="py-1">
        <div class="font-medium text-slate-600 text-sm">Contact support</div>
        <div class="text-slate-400 mt-2 text-xs">
          Ask questions, report problems or feedback
        </div>
      </div>
    </a>
  </div>
</template>
