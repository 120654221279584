<template>
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1079_48)">
      <path
        d="M40 96.619C40 90.5372 44.9554 87.2588 51.012 85.607L249.779 26.1422C261.892 21.7374 260.791 31.0724 260.791 37.1542V85.607C260.791 91.6888 256.386 94.4166 249.779 96.619L51.012 156.084C45.506 157.185 40 154.983 40 145.072V96.619Z"
        fill="url(#paint0_linear_1079_48)"
      />
    </g>
    <g filter="url(#filter1_d_1079_48)">
      <path
        d="M40 215.549C40 209.467 44.9554 206.188 51.012 204.537L249.779 145.072C261.892 140.667 260.791 150.002 260.791 156.084V204.537C260.791 210.618 256.386 213.346 249.779 215.549L51.012 275.014C45.506 276.115 40 273.912 40 264.002V215.549Z"
        fill="url(#paint1_linear_1079_48)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1079_48"
        x="36.6964"
        y="23.8988"
        width="234.031"
        height="144.556"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.3036" dy="5.506" />
        <feGaussianBlur stdDeviation="3.3036" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1079_48"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1079_48"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1079_48"
        x="36.6964"
        y="142.828"
        width="234.031"
        height="144.556"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.3036" dy="5.506" />
        <feGaussianBlur stdDeviation="3.3036" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1079_48"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1079_48"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1079_48"
        x1="40"
        y1="29.4048"
        x2="246.778"
        y2="284.045"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9A8CFE" />
        <stop offset="1" stop-color="#7764FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1079_48"
        x1="40"
        y1="31.0566"
        x2="246.935"
        y2="282.953"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9A8CFE" />
        <stop offset="1" stop-color="#7764FF" />
      </linearGradient>
    </defs>
  </svg>
</template>
