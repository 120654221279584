import dayjs from "dayjs";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import type { RouteLocationNormalized } from "vue-router";
import { useLocalStorage } from "@vueuse/core";

import { supabase } from "@/lib/supabase";

import type { Settings, SettingsAPI } from "@/types/settings";

import { useAuthStore } from "./auth";

export const useSettingsStore = defineStore("settings", () => {
  const settings = useLocalStorage<Settings>("settings", {
    onboarded: false,
    startingPeriod: dayjs().format("YYYY-MM"),
    currency: "EUR",
  });
  const previousRoute = ref<RouteLocationNormalized | undefined>();

  const load = async () => {
    let { data, error } = await supabase.from("settings").select().single();
    if (!error) {
      if (!data) {
        data = await createInitialSettings();
      }

      settings.value.onboarded = data.onboarded;
      settings.value.startingPeriod = data.starting_period;
      settings.value.currency = data.currency;
    }
    watch(settings.value, updateSettings);
  };

  const createInitialSettings = async (): Promise<SettingsAPI> => {
    const { user } = useAuthStore();
    const { data } = await supabase
      .from("settings")
      .insert({
        user: user!.id,
        ...settings.value,
      })
      .select()
      .single();

    return data! as SettingsAPI;
  };

  const updateSettings = async () => {
    console.log("test");
    const { user } = useAuthStore();

    await supabase
      .from("settings")
      .update({
        onboarded: settings.value.onboarded,
        starting_period: settings.value.startingPeriod,
        currency: settings.value.currency,
      })
      .eq("user", user!.id);
  };

  return {
    load,
    createInitialSettings,

    settings,
    previousRoute,
  };
});
