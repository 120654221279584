<script setup lang="ts">
import dayjs from "dayjs";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import BudgetActivityTypeLine from "./BudgetActivityTypeLine.vue";
import BudgetCategoryLine from "./BudgetCategoryLine.vue";
import BudgetRestLine from "./BudgetRestLine.vue";
import BudgetVue from "./Budget.vue";

import { ActivityType } from "@/types/activities";
import { useActivitiesStore } from "@/stores/activities";
import type { Budget } from "@/types/budgets";

const activitiesStore = useActivitiesStore();
const { categories } = storeToRefs(activitiesStore);

const props = withDefaults(
  defineProps<{
    budgets: Budget[];
    hidePeriods?: boolean;
    categoryFilter?: number | null;
    activityTypeFilter?: ActivityType | null;
  }>(),
  {
    hidePeriods: false,
    categoryFilter: null,
    activityTypeFilter: null,
  }
);

type Periods = {
  id: string;
  month: number;
  year: number;
};

const periods = computed<Periods[]>(() => {
  const periods = props.budgets.reduce((periods, b) => {
    let period = periods.find((p) => p.month === b.month && p.year === b.year);

    if (!period) {
      period = {
        id: `${b.month}-${b.year}`,
        month: b.month,
        year: b.year,
      };
      periods.push(period);
    }

    return periods;
  }, [] as Periods[]);

  return _.orderBy(periods, ["year", "month"], ["desc", "desc"]);
});

const periodFormatter = (month: number, year: number): string => {
  return dayjs().month(month).year(year).format("MMMM YYYY");
};
</script>

<template>
  <div class="flex flex-1 flex-col min-h-0">
    <div class="flex flex-1 h-full overflow-x-hidden">
      <div class="flex flex-1 flex-col min-h-0 min-w-0">
        <div
          v-if="budgets.length !== 0"
          class="flex flex-col sm:min-w-[575px] overflow-x-hidden"
        >
          <div class="pb-40">
            <template v-for="group in periods" :key="group.id">
              <div
                v-if="!hidePeriods"
                class="bg-slate-100 h-10 flex items-center gap-2 pl-5 sm:pl-7 pr-2 sm:pr-6 border-b border-gray-200 flex-shrink-0"
              >
                <i
                  class="mdi mdi-calendar-blank text-slate-600 mdi-16px mt-0.5"
                />
                <div class="text-sm font-medium">
                  {{ periodFormatter(group.month, group.year) }}
                </div>
              </div>

              <template
                v-for="activityType in [
                  ActivityType.REVENUE,
                  ActivityType.EXPENSE,
                  ActivityType.INVESTMENT,
                ].filter(
                  (at) =>
                    activityTypeFilter === null || activityTypeFilter === at
                )"
                :key="activityType"
              >
                <BudgetActivityTypeLine
                  :activity-type="activityType"
                  :month="group.month"
                  :year="group.year"
                />

                <template
                  v-for="category in _.sortBy(
                    categories
                      .filter((c) => c.type === activityType)
                      .filter(
                        (c) =>
                          categoryFilter === null || categoryFilter === c.id
                      )
                      .filter((c) => {
                        return (
                          budgets.find(
                            (b) =>
                              b.category === c.id &&
                              b.year === group.year &&
                              b.month === group.month
                          ) !== undefined
                        );
                      }),
                    'name'
                  )"
                  :key="category.id"
                >
                  <BudgetCategoryLine
                    :category="category"
                    :month="group.month"
                    :year="group.year"
                  />
                </template>

                <BudgetRestLine
                  v-if="
                    categories
                      .filter((c) => c.type === activityType)
                      .filter((c) => {
                        return (
                          budgets.find(
                            (b) =>
                              b.category === c.id &&
                              b.year === group.year &&
                              b.month === group.month
                          ) !== undefined
                        );
                      }).length > 0 &&
                    categories
                      .filter((c) => c.type === activityType)
                      .filter((c) => {
                        return (
                          budgets.find(
                            (b) =>
                              b.category === c.id &&
                              b.year === group.year &&
                              b.month === group.month
                          ) === undefined
                        );
                      }).length > 0
                  "
                  :month="group.month"
                  :year="group.year"
                  :activity-type="activityType"
                />
              </template>
            </template>
          </div>
        </div>
        <div
          v-else
          class="flex flex-1 items-center justify-center overflow-hidden"
        >
          <div class="text-slate-400">No budget found</div>
        </div>
      </div>

      <BudgetVue />
    </div>
  </div>
</template>
