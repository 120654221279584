<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";

import { useSettingsStore } from "@/stores/settings";

const { previousRoute } = storeToRefs(useSettingsStore());

const props = defineProps<{
  openMobile: boolean;
}>();
const emit = defineEmits(["close"]);

const route = useRoute();
const router = useRouter();

const goBack = () => {
  if (previousRoute.value) {
    router.push(previousRoute.value);
    previousRoute.value = undefined;
  } else {
    router.push("/");
  }
};
</script>

<template>
  <div class="flex items-center mt-12 lg:mt-2 h-14">
    <div
      type="button"
      class="inline-flex items-center justify-center w-6 h-8 mr-3"
      @click="goBack"
    >
      <i
        class="mdi mdi-chevron-left mdi-24px transition text-slate-400 hover:text-slate-900"
      />
    </div>

    <div class="text-slate-700 font-medium text-lg">Settings</div>
  </div>

  <div class="mt-4">
    <RouterLink
      class="flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors my-1 mb-4"
      :to="{ name: 'settings' }"
      :class="{
        'bg-slate-100': route.name === 'settings',
      }"
      @click="emit('close')"
    >
      <span class="text-sm ml-7 text-slate-700 font-medium">Overview</span>
    </RouterLink>

    <RouterLink
      class="flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors my-1"
      :to="{ name: 'settings_accounts' }"
      :class="{
        'bg-slate-100': route.name === 'settings_accounts',
      }"
      @click="emit('close')"
    >
      <i
        class="mdi mdi-bank text-slate-500 group-hover:text-slate-700 transition-colors"
      />
      <span class="text-sm ml-3 text-slate-700 font-medium">Accounts</span>
    </RouterLink>
    <RouterLink
      class="flex items-center hover:bg-slate-100 rounded px-2 h-8 group transition-colors my-1"
      :to="{ name: 'settings_categories' }"
      :class="{
        'bg-slate-100': route.name === 'settings_categories',
      }"
      @click="emit('close')"
    >
      <i
        class="mdi mdi-tag text-slate-500 group-hover:text-slate-700 transition-colors"
      />
      <span class="text-sm ml-3 text-slate-700 font-medium">Categories</span>
    </RouterLink>
  </div>
</template>
