<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useActivitiesStore } from "@/stores/activities";

const activitiesStore = useActivitiesStore();
const { showTransactions } = storeToRefs(activitiesStore);
</script>

<template>
  <TTooltip text="Show transactions">
    <button
      type="button"
      class="inline-flex items-center justify-center transition text-slate-600 hover:text-slate-700 hover:bg-slate-100 rounded h-7 w-7 border"
      :class="showTransactions ? 'bg-slate-100 text-slate-900' : 'bg-white'"
      @click="showTransactions = !showTransactions"
    >
      <i class="mdi mdi-list-box" />
    </button>
  </TTooltip>
</template>
