<script setup lang="ts">
import type { Dayjs } from "dayjs";
import Color from "colorjs.io";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import {
  useActivitiesStore,
  ACTIVITY_TYPES_NAME,
  ACTIVITY_TYPES_COLOR,
} from "@/stores/activities";
import { usePeriodsStore } from "@/stores/periods";

import PeriodActivityCategoryLine from "@/containers/periods/PeriodActivityCategoryLine.vue";

import { getCurrencyFormatter } from "@/utils/currency";

import { ActivityType } from "@/types/activities";
import type { PeriodActivityData } from "@/types/periods";
import BudgetGauge from "@/components/budget/BudgetGauge.vue";

const activitiesStore = useActivitiesStore();
const { categories } = storeToRefs(activitiesStore);

const periodsStore = usePeriodsStore();
const { viewFilters, periodsActivityData, periodsBudgetData } =
  storeToRefs(periodsStore);

const props = defineProps<{
  periodDate: Dayjs;
}>();

const periodActivityData = computed<PeriodActivityData>(() => {
  return periodsActivityData.value.find(
    (p) =>
      p.month === props.periodDate.month() && p.year === props.periodDate.year()
  )!;
});

const periodBudgetData = computed<PeriodActivityData | undefined>(() => {
  return periodsBudgetData.value?.find(
    (p) =>
      p.month === props.periodDate.month() && p.year === props.periodDate.year()
  )!;
});

const getCategoriesWithBudget = (activityType: ActivityType) => {
  return _.sortBy(
    categories.value
      .filter((c) => c.type === activityType)
      .filter((c) => {
        if (!periodBudgetData.value) return true;
        return (
          periodBudgetData.value.categories.find((b) => b.category === c.id) !==
          undefined
        );
      }),
    "name"
  );
};

const getCategoriesWithoutBudget = (activityType: ActivityType) => {
  return _.sortBy(
    categories.value
      .filter((c) => c.type === activityType)
      .filter((c) => {
        if (!periodBudgetData.value) return false;
        return (
          periodBudgetData.value.categories.find((b) => b.category === c.id) ===
          undefined
        );
      }),
    "name"
  );
};

const getRestBudget = (activityType: ActivityType, totalBudget: number) => {
  if (!periodBudgetData.value) return undefined;

  const categoriesWithBudget = getCategoriesWithBudget(activityType);
  return (
    totalBudget -
    periodBudgetData.value.categories
      .filter((cb) =>
        categoriesWithBudget.map((c) => c.id).includes(cb.category)
      )
      .reduce((s, cb) => s + Math.max(cb.value, 0), 0)
  );
};

const getRestTotal = (activityType: ActivityType) => {
  if (!periodBudgetData.value) return undefined;

  const categoriesWithoutBudget = getCategoriesWithoutBudget(activityType);
  return periodActivityData.value.categories
    .filter((cb) =>
      categoriesWithoutBudget.map((c) => c.id).includes(cb.category)
    )
    .reduce((s, cb) => s + cb.value, 0);
};

const selectActivityTypeToFilterActivities = (activityType: ActivityType) => {
  viewFilters.value.category = null;
  viewFilters.value.subcategory = null;
  if (viewFilters.value.activityType !== activityType) {
    viewFilters.value.activityType = activityType;
  } else {
    viewFilters.value.activityType = null;
  }
};

const getProgressBarColor = (index: number, activityType: ActivityType) => {
  const color = new Color(
    {
      [ActivityType.REVENUE]: "#4ade80",
      [ActivityType.EXPENSE]: "#f87171",
      [ActivityType.INVESTMENT]: "#fb923c",
      [ActivityType.NEUTRAL]: "#9ca3af",
    }[activityType]
  );
  color.lch.l =
    80 +
    (index / categories.value.filter((c) => c.type === activityType).length) *
      -50;
  return color;
};
</script>

<template>
  <div
    v-for="activityType in [
      {
        type: ActivityType.REVENUE,
        value: periodActivityData.revenue,
        budget: periodBudgetData?.revenue,
      },
      {
        type: ActivityType.EXPENSE,
        value: periodActivityData.expense,
        budget: periodBudgetData?.expense,
      },
      {
        type: ActivityType.INVESTMENT,
        value: periodActivityData.investment,
        budget: periodBudgetData?.investment,
      },
    ]"
    :key="activityType.type"
    class="border-b py-3 px-3 w-full"
  >
    <div
      class="flex items-center justify-between px-3 rounded hover:bg-slate-50 group h-9"
      :class="
        viewFilters.activityType === activityType.type
          ? 'bg-slate-50'
          : 'hover:bg-slate-50'
      "
      @click="selectActivityTypeToFilterActivities(activityType.type)"
    >
      <div class="flex items-center">
        <div
          class="h-3 w-3 rounded-xl shrink-0 mr-3"
          :class="`bg-${ACTIVITY_TYPES_COLOR[activityType.type]}-300`"
        />
        <span class="text-sm text-slate-800 font-medium">
          {{ ACTIVITY_TYPES_NAME[activityType.type] }}
        </span>
      </div>

      <div class="flex items-center">
        <div
          class="mr-4 text-slate-700 text-sm"
          :class="
            viewFilters.activityType === activityType.type
              ? ''
              : 'hidden group-hover:block'
          "
        >
          <template v-if="viewFilters.activityType === activityType.type">
            Clear filter
          </template>
          <template v-else> Filter </template>
        </div>

        <div
          class="whitespace-nowrap text-right text-slate-700 text-sm font-medium"
        >
          {{ getCurrencyFormatter().format(activityType.value) }}
        </div>

        <template v-if="activityType.budget !== undefined">
          <div class="w-4 whitespace-nowrap text-slate-400 text-sm text-right">
            /
          </div>

          <div class="w-20 whitespace-nowrap text-slate-500 text-sm text-right">
            {{ getCurrencyFormatter().format(activityType.budget) }}
          </div>

          <BudgetGauge
            class="ml-2"
            :value="activityType.value"
            :budget="activityType.budget"
            :activity-type="activityType.type"
            minimize
          />
        </template>
      </div>
    </div>

    <div class="px-2 my-1">
      <div
        v-if="activityType.value !== 0"
        class="w-full h-2 hover:h-4 transition-all rounded-md flex items-center overflow-hidden bg-slate-100"
      >
        <TTooltip
          v-for="(category, index) in categories.filter(
            (c) => c.type === activityType.type
          )"
          :key="category.id"
          :text="`${category.name} (${_.round(
            ((periodActivityData.categories.find(
              (c) => c.category === category.id
            )?.value ?? 0) /
              activityType.value) *
              100,
            2
          )}%)`"
          placement="bottom"
          class="h-full transition-all hover:opacity-50"
          :style="`background-color: ${getProgressBarColor(
            index,
            activityType.type
          )}; width: ${
            (100 / activityType.value) *
            (periodActivityData.categories.find(
              (c) => c.category === category.id
            )?.value ?? 0)
          }%;`"
        />
      </div>
    </div>

    <PeriodActivityCategoryLine
      v-for="category in getCategoriesWithBudget(activityType.type)"
      :key="category.id"
      :period-date="periodDate"
      :category="category"
    />

    <template
      v-if="
        periodBudgetData &&
        getCategoriesWithoutBudget(activityType.type).length > 0
      "
    >
      <div
        v-if="getCategoriesWithBudget(activityType.type).length > 0"
        class="flex items-center px-3 mt-3 mb-1 h-9 border-b border-b-gray-100 justify-between group"
      >
        <div class="text-slate-600 text-xs pl-6">Rest</div>

        <div class="flex items-center">
          <div class="whitespace-nowrap text-slate-700 text-sm">
            {{
              getCurrencyFormatter().format(getRestTotal(activityType.type)!)
            }}
          </div>

          <template
            v-if="
              getRestBudget(activityType.type, activityType.budget!) !==
              undefined
            "
          >
            <div
              class="w-4 whitespace-nowrap text-slate-400 text-sm text-right"
            >
              /
            </div>
            <div
              class="w-20 whitespace-nowrap text-slate-500 text-sm text-right"
            >
              {{
                getCurrencyFormatter().format(
                  getRestBudget(activityType.type, activityType.budget!)!
                )
              }}
            </div>

            <BudgetGauge
              class="ml-2"
              :value="getRestTotal(activityType.type)!"
              :budget="getRestBudget(activityType.type, activityType.budget!)!"
              :activity-type="activityType.type"
              minimize
            />
          </template>
        </div>
      </div>

      <PeriodActivityCategoryLine
        v-for="category in getCategoriesWithoutBudget(activityType.type)"
        :key="category.id"
        :period-date="periodDate"
        :category="category"
      />
    </template>
  </div>
</template>
