<script setup lang="ts">
import dayjs from "dayjs";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

import { usePeriodsStore } from "@/stores/periods";
import { useHeadStore } from "@/stores/head";
import { useActivitiesStore } from "@/stores/activities";
import { useMovementsStore } from "@/stores/movements";

import ActivityLine from "@/containers/activities/ActivityLine.vue";
import AddActivityButton from "@/containers/activities/AddActivityButton.vue";
import MovementLine from "@/containers/movements/MovementLine.vue";
import ImportMovementsButton from "@/containers/movements/ImportMovementsButton.vue";
import PeriodAccountsSummary from "@/containers/periods/PeriodAccountsSummary.vue";

import { getCurrencyFormatter } from "@/utils/currency";

import type { PeriodActivityData } from "@/types/periods";

const periodsStore = usePeriodsStore();
const { periodsActivityData, periodsBudgetData } = storeToRefs(periodsStore);

const activitiesStore = useActivitiesStore();
const { activitiesData } = storeToRefs(activitiesStore);

const movementsStore = useMovementsStore();
const { movements } = storeToRefs(movementsStore);

const headStore = useHeadStore();
headStore.updateTitle("Dashboard");

const router = useRouter();

const now = dayjs();

const currentPeriodActivityData = computed<
  PeriodActivityData & { budget: PeriodActivityData | undefined }
>(() => {
  return {
    ...periodsActivityData.value.find(
      (pad) => pad.month === now.month() && pad.year === now.year()
    )!,
    budget: periodsBudgetData.value?.find(
      (pbd) => pbd.month === now.month() && pbd.year === now.year()
    )!,
  };
});

const lastActivitiesData = computed(() => {
  return _.orderBy(
    activitiesData.value,
    ["activity.date", "id"],
    ["desc", "desc"]
  ).slice(0, 10);
});

const lastMovements = computed(() => {
  return _.orderBy(movements.value, ["date", "id"], ["desc", "desc"]).slice(
    0,
    10
  );
});

const periodFormatter = (month: number, year: number): string => {
  return dayjs().month(month).year(year).format("MMMM YYYY");
};

const goToPeriod = () => {
  router.push({ name: "period", params: { period: "current" } });
};
</script>

<template>
  <header
    class="h-14 border-b border-b-gray-200 pl-14 pr-4 lg:pl-8 flex items-center flex-shrink-0"
  >
    <div class="text-sm font-medium">Dashboard</div>
  </header>

  <div class="flex-1 overflow-y-auto">
    <div
      class="py-6 lg:py-8 px-2 xs:px-4 sm:px-6 lg:px-8 bg-slate-50 border-b relative mb-20"
    >
      <div class="flex items-center pb-8 px-1 my-4">
        <div>
          <div class="text-slate-400 text-xs mb-2 lg:mb-3">Current period</div>
          <div class="text-slate-800 font-bold text-3xl lg:text-4xl">
            {{ periodFormatter(now.month(), now.year()) }}
          </div>
        </div>

        <div class="flex-1" />

        <TBtn outlined @click="goToPeriod">Go to period</TBtn>
      </div>

      <div
        class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 border rounded-md bg-white relative -mb-24"
      >
        <div
          v-for="(activityType, index) in [
            {
              name: 'Balance',
              color: 'bg-indigo-300',
              value: currentPeriodActivityData.balance,
              budget: currentPeriodActivityData.budget?.balance,
              isOverflowPositive: true,
            },
            {
              name: 'Revenue',
              color: 'bg-green-300',
              value: currentPeriodActivityData.revenue,
              budget: currentPeriodActivityData.budget?.revenue,
              isOverflowPositive: true,
            },
            {
              name: 'Expense',
              color: 'bg-red-300',
              value: currentPeriodActivityData.expense,
              budget: currentPeriodActivityData.budget?.expense,
              isOverflowPositive: false,
            },
            {
              name: 'Investment',
              color: 'bg-orange-300',
              value: currentPeriodActivityData.investment,
              budget: currentPeriodActivityData.budget?.investment,
              isOverflowPositive: true,
            },
          ]"
          :key="activityType.name"
          class="py-6 sm:py-8 px-6 border-b xl:border-b-0"
          :class="index === 0 ? '' : 'sm:border-l'"
        >
          <div class="flex items-center">
            <div
              class="h-2.5 w-2.5 rounded-full shrink-0 mr-2"
              :class="activityType.color"
            />
            <span class="text-slate-700 font-medium text-sm">
              {{ activityType.name }}
            </span>
          </div>

          <div class="flex items-end mt-4">
            <div
              class="whitespace-nowrap text-slate-700 font-semibold text-2xl lg:text-3xl"
            >
              {{ getCurrencyFormatter().format(activityType.value) }}
            </div>
            <template v-if="activityType.budget !== undefined">
              <div class="text-center text-slate-300 ml-3 px-2 text-sm">/</div>
              <div class="text-slate-400 text-right text-sm">
                {{ getCurrencyFormatter().format(activityType.budget) }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="py-6 lg:py-8 px-2 xs:px-4 sm:px-6 lg:px-8 flex">
      <div class="grid grid-cols-6 flex-1 gap-4">
        <div
          class="@container h-full border rounded-md col-span-6 md:col-span-3 xl:col-span-4 overflow-hidden"
        >
          <div
            class="h-14 pl-4 @lg:pl-7 pr-2 @lg:pr-5 bg-slate-50 border-b flex items-center"
          >
            <div class="font-semibold text-slate-700">Last activities</div>
            <div class="flex-1" />
            <AddActivityButton large />
          </div>

          <div class="h-[400px] border-b">
            <RouterLink
              v-for="activityData in lastActivitiesData"
              :key="activityData.id"
              :to="{
                name: 'activities',
                params: { id: activityData.activity.number },
              }"
            >
              <ActivityLine :activity-data="activityData" />
            </RouterLink>
          </div>
        </div>

        <div
          class="border rounded-md row-span-2 col-span-6 md:col-span-3 xl:col-span-2 overflow-hidden"
        >
          <div class="h-14 px-6 bg-slate-50 border-b flex items-center">
            <div class="font-semibold text-slate-700">Accounts summary</div>
          </div>

          <PeriodAccountsSummary :period-date="now" no-filter />
        </div>

        <div
          class="@container h-full border rounded-md col-span-6 md:col-span-3 xl:col-span-4 overflow-hidden"
        >
          <div
            class="h-14 pl-4 @lg:pl-7 pr-2 @lg:pr-5 bg-slate-50 border-b flex items-center"
          >
            <div class="font-semibold text-slate-700">Last movements</div>
            <div class="flex-1" />
            <ImportMovementsButton large />
          </div>

          <div class="h-[400px] border-b">
            <RouterLink
              v-for="movement in lastMovements"
              :key="movement.id"
              :to="{
                name: 'movements',
                params: { id: movement.id },
              }"
            >
              <MovementLine :movement="movement" />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
