<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useMotion } from "@vueuse/motion";

import { useActivitiesStore } from "@/stores/activities";
import { usePeriodsStore } from "@/stores/periods";
import { useMovementsStore } from "@/stores/movements";
import { useHeadStore } from "@/stores/head";
import { useViewsStore } from "@/stores/views";
import { useBudgetsStore } from "@/stores/budgets";

import ActivitiesTable from "@/containers/activities/ActivitiesTable.vue";
import MovementsTable from "@/containers/movements/MovementsTable.vue";
import PeriodAccountsSummary from "@/containers/periods/PeriodAccountsSummary.vue";
import PeriodActivitiesSummary from "@/containers/periods/PeriodActivitiesSummary.vue";
import PeriodsMenu from "@/containers/periods/PeriodsMenu.vue";
import SearchBar from "@/containers/SearchBar.vue";
import FilterActivitiesButton from "@/containers/activities/filters/FilterActivitiesButton.vue";
import ExportActivitiesButton from "@/containers/activities/ExportActivitiesButton.vue";
import BudgetsTable from "@/containers/budget/BudgetsTable.vue";

import ShowTransactionsButton from "@/components/activities/ShowTransactionsButton.vue";
import PeriodLabel from "@/components/periods/PeriodLabel.vue";

import { getCurrencyFormatter } from "@/utils/currency";

import type { PeriodActivityData } from "@/types/periods";

const router = useRouter();
const route = useRoute();

const viewsStore = useViewsStore();

const headStore = useHeadStore();

const activitiesStore = useActivitiesStore();
const { activitiesData } = storeToRefs(activitiesStore);

const movementsStore = useMovementsStore();
const { movements } = storeToRefs(movementsStore);

const budgetsStore = useBudgetsStore();
const { budgets } = storeToRefs(budgetsStore);

const periodsStore = usePeriodsStore();
const {
  viewFilters,
  periodsAvailable,
  periodsActivityData,
  periodsForecastData,
  periodsBudgetData,
} = storeToRefs(periodsStore);

const currentView = ref<"activities" | "movements" | "budgets">("activities");
const currentDrawerSummaryView = ref<"activities" | "accounts">("activities");

const periodDate = computed(() => {
  let periodDate: Dayjs;
  const period = route.params.period as string;
  if (period === "current") periodDate = dayjs();
  else if (period === "past") periodDate = dayjs().subtract(1, "month");
  else periodDate = dayjs(period);

  return periodDate;
});

watch(
  () => route.params.period,
  () => {
    if (route.name !== "period") return;

    if (route.params.period != "current" && route.params.period != "past") {
      if (!periodDate.value.isValid()) {
        router.replace({ name: "periods" });
        return;
      }

      const now = dayjs();
      if (
        periodDate.value.month() === now.month() &&
        periodDate.value.year() === now.year()
      ) {
        router.replace({ name: "period", params: { period: "current" } });
        return;
      }

      const pastPeriod = now.subtract(1, "month");
      if (
        periodDate.value.month() === pastPeriod.month() &&
        periodDate.value.year() === pastPeriod.year()
      ) {
        router.replace({ name: "period", params: { period: "past" } });
        return;
      }
    }

    if (
      !periodsAvailable.value.find(
        (p) =>
          p.month === periodDate.value.month() &&
          p.year === periodDate.value.year()
      )
    ) {
      router.replace({ name: "periods" });
      return;
    }

    headStore.updateTitle(periodDate.value.format("MMMM YYYY"));
  },
  { immediate: true }
);

const activityView = computed(() => {
  return viewsStore.getActivityView(`period-page`);
});

const label = computed(() =>
  periodsStore.getPeriodLabel({
    month: periodDate.value.month(),
    year: periodDate.value.year(),
  })
);

const periodActivitiesData = computed(() => {
  return activitiesData.value.filter(
    (activityData) =>
      activityData.activity.date.month() === periodDate.value.month() &&
      activityData.activity.date.year() === periodDate.value.year()
  );
});

const periodMovements = computed(() => {
  return movements.value.filter(
    (movement) =>
      movement.date.month() === periodDate.value.month() &&
      movement.date.year() === periodDate.value.year()
  );
});

const periodBudgets = computed(() => {
  return budgets.value.filter(
    (budget) =>
      budget.month === periodDate.value.month() &&
      budget.year === periodDate.value.year()
  );
});

const periodActivityData = computed<PeriodActivityData | undefined>(() => {
  return periodsActivityData.value.find(
    (p) =>
      p.month === periodDate.value.month() && p.year === periodDate.value.year()
  );
});

const periodForecastData = computed<PeriodActivityData | undefined>(() => {
  return periodsForecastData.value?.find(
    (p) =>
      p.month === periodDate.value.month() && p.year === periodDate.value.year()
  );
});

const periodBudgetData = computed<PeriodActivityData | undefined>(() => {
  return periodsBudgetData.value?.find(
    (p) =>
      p.month === periodDate.value.month() && p.year === periodDate.value.year()
  );
});

const previousPeriodActivityData = computed<PeriodActivityData | undefined>(
  () => {
    return periodsActivityData.value.find(
      (p) =>
        p.month === periodDate.value.subtract(1, "month").month() &&
        p.year === periodDate.value.subtract(1, "month").year()
    );
  }
);

const resetFilters = () => {
  viewFilters.value = {
    category: null,
    subcategory: null,
    activityType: null,
    account: null,
  };
};

onBeforeUnmount(() => {
  resetFilters();
});

// Drawer

const drawerElement = ref<HTMLElement>();
const isDrawerOpen = ref(window.innerWidth > 420);

const toggleDrawer = () => {
  if (isDrawerOpen.value) {
    useMotion(drawerElement, {
      initial: {
        marginLeft: 0,
        x: 0,
      },
      enter: {
        marginLeft: -460,
        x: 460,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });

    setTimeout(() => {
      isDrawerOpen.value = false;
    }, 100);
  } else {
    isDrawerOpen.value = true;
    useMotion(drawerElement, {
      initial: {
        marginLeft: -460,
        x: 460,
      },
      enter: {
        marginLeft: 0,
        x: 0,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });
  }
};
</script>

<template>
  <template v-if="periodDate.isValid() && periodActivityData">
    <header
      class="h-14 border-b border-b-gray-200 pl-8 pr-4 lg:pl-2 flex items-center shrink-0"
    >
      <div class="text-sm font-medium flex items-center px-2">
        <PeriodsMenu />
      </div>

      <div class="flex-1" />
      <SearchBar />

      <TTooltip text="Show period side panel">
        <button
          type="button"
          class="ml-2 sm:ml-0 inline-flex items-center justify-center transition text-slate-600 hover:text-slate-700 hover:bg-slate-100 rounded h-7 w-7"
          :class="isDrawerOpen ? 'bg-slate-100 text-slate-900' : 'bg-white'"
          @click="toggleDrawer"
        >
          <i class="mdi mdi-view-split-vertical text-lg" />
        </button>
      </TTooltip>
    </header>

    <div class="flex flex-1 w-full overflow-x-hidden">
      <div class="flex flex-1 flex-col min-w-0">
        <div class="flex items-center h-10 border-b px-4 sm:px-6">
          <div class="flex items-center bg-slate-100 rounded">
            <button
              type="button"
              class="inline-flex items-center justify-center transition hover:text-slate-800 rounded h-6 px-2 text-xs"
              :class="
                currentView === 'activities'
                  ? 'bg-white border shadow text-slate-800 font-medium'
                  : 'bg-transparent text-slate-500'
              "
              @click="currentView = 'activities'"
            >
              Activities
            </button>
            <button
              type="button"
              class="inline-flex items-center justify-center transition hover:text-slate-800 rounded h-6 px-2 text-xs"
              :class="
                currentView === 'movements'
                  ? 'bg-white border shadow text-slate-800 font-medium'
                  : 'bg-transparent text-slate-500 '
              "
              @click="currentView = 'movements'"
            >
              Movements
            </button>
            <button
              type="button"
              class="inline-flex items-center justify-center transition hover:text-slate-800 rounded h-6 px-2 text-xs"
              :class="
                currentView === 'budgets'
                  ? 'bg-white border shadow text-slate-800 font-medium'
                  : 'bg-transparent text-slate-500'
              "
              @click="currentView = 'budgets'"
            >
              Budgets
            </button>
          </div>

          <div class="flex-1" />

          <template
            v-if="currentView === 'activities' && activityView!.filters.length === 0"
          >
            <FilterActivitiesButton :view-id="activityView!.id" class="mx-2" />
            <ExportActivitiesButton
              class="mr-1"
              :view-id="activityView.id"
              :activities-data="periodActivitiesData"
            />
            <div class="mx-3 w-[1px] bg-gray-200 h-5" />
            <ShowTransactionsButton />
          </template>
        </div>

        <ActivitiesTable
          v-if="currentView === 'activities'"
          :activities-data="periodActivitiesData"
          :account-filter="viewFilters.account"
          :category-filter="viewFilters.category"
          :subcategory-filter="viewFilters.subcategory"
          :activity-type-filter="viewFilters.activityType"
          :view-id="activityView.id"
        />
        <MovementsTable
          v-else-if="currentView === 'movements'"
          :movements="periodMovements"
          :account-filter="viewFilters.account"
        />
        <BudgetsTable
          v-else-if="currentView === 'budgets'"
          :budgets="periodBudgets"
          :category-filter="viewFilters.category"
          :activity-type-filter="viewFilters.activityType"
          hide-periods
        />
      </div>

      <div
        v-if="isDrawerOpen"
        ref="drawerElement"
        class="relative flex flex-col items-center w-full shrink-0 sm:max-w-[460px] h-full border-l shadow-xl overflow-y-auto pb-10 bg-white"
      >
        <div class="border-b p-6 w-full flex items-center">
          <div class="text-lg text-slate-700 font-bold">
            {{ periodDate.format("MMMM YYYY") }}
          </div>
          <PeriodLabel
            class="ml-4"
            :period="{ month: periodDate.month(), year: periodDate.year() }"
          />

          <div class="flex-1" />
          <TMenu
            v-if="periodBudgetData"
            :items="[
              { value: 'open_budget', text: 'Open Budgets', icon: 'gauge' },
            ]"
            @click:item="
              router.push({
                name: 'budgets',
                params: { period: periodDate.format('YYYY-MM') },
              })
            "
          />
        </div>

        <div class="border-b p-6 pb-12 mb-6 w-full relative">
          <div class="flex items-center">
            <div class="text-slate-600 font-medium">Total</div>
            <div class="flex-1" />

            <template v-if="previousPeriodActivityData">
              <span class="text-slate-300 text-lg">
                {{
                  getCurrencyFormatter().format(
                    previousPeriodActivityData.balance
                  )
                }}
              </span>
              <i class="mdi mdi-arrow-right mx-2 text-slate-300" />
            </template>
            <span class="font-semibold text-slate-700 text-lg">
              {{ getCurrencyFormatter().format(periodActivityData.balance) }}
            </span>
          </div>

          <div class="flex items-center mt-3 text-sm">
            <div class="text-slate-500 font-medium">Earnings</div>
            <div class="flex-1" />
            <span class="font-medium text-slate-600">
              <i
                class="mdi mx-1"
                :class="
                  periodActivityData.revenue > periodActivityData.expense
                    ? 'mdi-plus-circle'
                    : 'mdi-minus-circle'
                "
              />
              {{
                getCurrencyFormatter().format(
                  periodActivityData.revenue - periodActivityData.expense
                )
              }}
            </span>
          </div>

          <div
            v-if="periodBudgetData !== undefined"
            class="flex items-center text-sm mt-6"
          >
            <div class="text-slate-400 font-medium">Target</div>
            <div class="flex-1" />

            <span class="text-slate-400 font-medium">
              {{ getCurrencyFormatter().format(periodBudgetData.balance) }}
            </span>
          </div>

          <div
            v-if="label !== 'Completed' && periodForecastData !== undefined"
            class="flex items-center text-sm mt-4"
          >
            <div class="text-slate-400 font-medium">Forecast</div>
            <div class="flex-1" />

            <span class="text-slate-400 font-medium">
              {{ getCurrencyFormatter().format(periodForecastData.balance) }}
            </span>
          </div>

          <div class="w-full px-8 absolute -bottom-4 left-0">
            <div class="flex items-center w-full bg-slate-100 rounded border">
              <button
                type="button"
                class="inline-flex items-center justify-center transition hover:text-slate-800 rounded h-8 px-2 text-xs flex-grow border-r"
                :class="[
                  currentDrawerSummaryView === 'activities'
                    ? 'bg-white text-slate-900 font-medium'
                    : 'bg-transparent text-slate-500',
                ]"
                @click="
                  currentDrawerSummaryView = 'activities';
                  resetFilters();
                "
              >
                Activities
              </button>
              <button
                type="button"
                class="inline-flex items-center justify-center transition hover:text-slate-800 rounded h-8 px-2 text-xs flex-grow"
                :class="
                  currentDrawerSummaryView === 'accounts'
                    ? 'bg-white text-slate-900 font-medium'
                    : 'bg-transparent text-slate-500'
                "
                @click="
                  currentDrawerSummaryView = 'accounts';
                  resetFilters();
                "
              >
                Accounts
              </button>
            </div>
          </div>
        </div>

        <PeriodActivitiesSummary
          v-if="currentDrawerSummaryView === 'activities'"
          :period-date="periodDate"
        />
        <PeriodAccountsSummary v-else :period-date="periodDate" />
      </div>
    </div>
  </template>
</template>
