<script lang="ts" setup>
import dayjs from "dayjs";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMotion } from "@vueuse/motion";

import AccountLabel from "@/components/AccountLabel.vue";

import AddActivityButton from "@/containers/activities/AddActivityButton.vue";

import { useMovementsStore } from "@/stores/movements";
import { useActivitiesStore } from "@/stores/activities";

import type { Movement } from "@/types/movements";

import { getCurrencyFormatter } from "@/utils/currency";

const movementsStore = useMovementsStore();
const { focusedMovement } = storeToRefs(movementsStore);

const activitiesStore = useActivitiesStore();
const { focusedActivity } = storeToRefs(activitiesStore);

const mainElement = ref<HTMLElement>();
const showMovement = ref(focusedMovement.value !== null);
const showProperties = ref(true);
const showActivities = ref(true);

watch(focusedMovement, () => {
  if (focusedMovement.value !== null) {
    if (showMovement.value) return;

    showMovement.value = true;
    useMotion(mainElement, {
      initial: {
        marginLeft: window.innerWidth > 640 ? -576 : 0,
        x: 576,
      },
      enter: {
        marginLeft: 0,
        x: 0,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });
  } else {
    useMotion(mainElement, {
      initial: {
        marginLeft: 0,
        x: 0,
      },
      enter: {
        marginLeft: window.innerWidth > 640 ? -576 : 0,
        x: 576,
        transition: {
          type: "spring",
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    });

    setTimeout(() => {
      showMovement.value = false;
    }, 100);
  }
});

const movement = computed<Movement | undefined>(() => {
  if (focusedMovement.value === null) return;
  return movementsStore.getMovementById(focusedMovement.value);
});

const movementActivities = computed(() => {
  if (focusedMovement.value === null) return;
  return movementsStore
    .getMovementsActivitiesByMovement(focusedMovement.value)
    .map((ma) => ({
      ...ma,
      activityData: activitiesStore.getActivityDataById(ma.activity)!,
    }));
});

const handleMovementMenuClick = (event: string) => {
  if (focusedMovement.value === null) return;

  if (event === "delete") {
    movementsStore.deleteMovement(focusedMovement.value);
    close();
  }
};

const focusActivity = (activityId: number) => {
  focusedActivity.value = activityId;
};

const close = () => {
  focusedActivity.value = null;
  focusedMovement.value = null;
};
</script>

<template>
  <div
    v-if="showMovement"
    ref="mainElement"
    class="absolute top-0 left-0 sm:relative flex flex-col w-full sm:w-[575px] max-w-full min-h-full overflow-x-hidden bg-white border-l shadow-xl"
  >
    <template v-if="movement">
      <div
        class="flex items-center px-4 sm:px-6 border-b w-full flex-shrink-0 h-14 bg-slate-50"
      >
        <button
          type="button"
          class="inline-flex items-center justify-center w-6 h-8 ml-8 sm:ml-0 mr-4"
          @click="close"
        >
          <i
            class="mdi mdi-chevron-right mdi-24px transition text-slate-400 hover:text-slate-900"
          />
        </button>
        <div class="text-slate-700 text-sm font-medium">
          Movement #{{ movement.id }}
        </div>

        <div class="flex-1" />
        <TMenu
          class="mr-2 sm:mr-0"
          :items="[{ value: 'delete', text: 'Delete', icon: 'delete' }]"
          @click:item="handleMovementMenuClick($event)"
        />
      </div>

      <div class="px-4 sm:px-8 pt-6 mb-4 flex items-center">
        <div class="flex">
          <div
            class="text-sm bg-slate-100 px-2 h-8 flex items-center text-slate-500 rounded -mx-2"
          >
            <AccountLabel :account-id="movement.account" />
          </div>
        </div>
      </div>

      <div
        class="px-4 sm:px-8 pb-6 border-b text-slate-600 font-mono min-w-0 font-bold"
      >
        {{ movement.name }}
      </div>

      <div class="py-6 px-4 sm:px-8 border-b">
        <div class="flex">
          <div
            class="-ml-2 text-sm font-medium text-slate-800 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
            @click="showProperties = !showProperties"
          >
            Properties
            <i
              class="mdi ml-2"
              :class="showProperties ? 'mdi-menu-down' : 'mdi-menu-up'"
            />
          </div>
        </div>

        <div v-show="showProperties" class="pt-4">
          <div class="flex items-center justify-between mb-2">
            <div class="text-sm text-slate-400">Date</div>

            <TDatePicker
              v-model="movement.date"
              borderless
              class="h-8 text-sm font-medium text-slate-800"
            />
          </div>

          <div class="flex items-center justify-between">
            <div class="text-sm text-slate-400">Amount</div>

            <TAmountInput
              v-model="movement.amount"
              borderless
              class="h-8 text-sm font-medium text-slate-800"
            />
          </div>
        </div>
      </div>

      <div class="py-6 px-4 sm:px-8">
        <div class="flex">
          <div
            class="-ml-2 text-sm font-medium text-slate-800 px-2 rounded h-7 hover:bg-slate-100 flex items-center"
            @click="showActivities = !showActivities"
          >
            Activities linked
            <i
              class="mdi ml-2"
              :class="showActivities ? 'mdi-menu-down' : 'mdi-menu-up'"
            />
          </div>

          <div class="flex-1" />

          <AddActivityButton
            v-if="showActivities"
            :movement="movement"
            class="-mr-2"
            @create="focusActivity"
          />
        </div>

        <div v-show="showActivities" class="mt-4">
          <template
            v-for="movementActivity in movementActivities"
            :key="movementActivity.id"
          >
            <hr class="border-t border-t-gray-200 -mx-4 px-4" />

            <div
              class="h-10 flex items-center justify-center text-sm -mx-4 pr-4 rounded"
              :class="
                focusedActivity === movementActivity.activityData.id
                  ? 'border-l-4 border-l-indigo-300 pl-3 bg-slate-100'
                  : 'pl-4 hover:bg-slate-100'
              "
              @click="focusActivity(movementActivity.activityData.id)"
            >
              <div class="w-8 text-slate-400 hidden sm:block shrink-0">
                #{{ movementActivity.activityData.activity.number }}
              </div>
              <div class="hidden sm:block text-slate-500 w-20 shrink-0 ml-2">
                {{
                  dayjs(movementActivity.activityData.activity.date).format(
                    "DD/MM/YYYY"
                  )
                }}
              </div>
              <div class="sm:hidden text-slate-500 w-10 shrink-0">
                {{
                  dayjs(movementActivity.activityData.activity.date).format(
                    "DD/MM"
                  )
                }}
              </div>

              <div
                class="ml-1 text-slate-700 text-ellipsis overflow-hidden whitespace-nowrap"
              >
                {{ movementActivity.activityData.activity.name }}
              </div>
              <div class="flex-1" />
              <div class="w-20 whitespace-nowrap text-right text-slate-800">
                {{ getCurrencyFormatter().format(movementActivity.amount) }}
              </div>
            </div>
          </template>

          <div
            v-if="movementActivities!.length === 0"
            class="flex items-center justify-center text-slate-500 text-xs"
          >
            No activity linked to this movement yet.
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
