const DB_NAME = "banqr";
const DB_VERSION = 7;

export enum TABLES {
  ACCOUNTS = "accounts",
  ACTIVITIES = "activities",
  ACTIVITIES_SHARING = "activities_sharing_user",
  ACTIVITY_CATEGORIES = "activity_categories",
  ACTIVITY_SUBCATEGORIES = "activity_subcategories",
  TRANSACTIONS = "transactions",
  MOVEMENTS = "movements",
  MOVEMENTS_ACTIVITIES = "movements_activities",
  BUDGETS = "budgets",
  PROJECTS = "projects",
}

export let db: IDBDatabase | undefined;

export const connectDb = async (): Promise<IDBDatabase> => {
  if (db) return db;
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (e) => {
      console.log("Error opening db", e);
      reject("Error");
    };

    request.onsuccess = () => {
      db = request.result;
      resolve(db);
    };

    request.onupgradeneeded = async () => {
      db = request.result;

      Object.values(TABLES).forEach((table) => {
        if (!db!.objectStoreNames.contains(table)) {
          db!.createObjectStore(table, {
            keyPath: "id",
          });
        }
      });
    };
  });
};

export const deleteDb = async (): Promise<undefined> => {
  if (db) {
    db.close();
    db = undefined;
  }

  return new Promise((resolve, reject) => {
    const DBDeleteRequest = window.indexedDB.deleteDatabase(DB_NAME);
    DBDeleteRequest.onerror = () => {
      console.error("Error deleting database.");
      reject("Error");
    };

    DBDeleteRequest.onblocked = () => {
      console.log("blocked");
    };

    DBDeleteRequest.onsuccess = () => {
      resolve(undefined);
    };
  });
};
