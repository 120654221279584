<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";

import { useHeadStore } from "@/stores/head";
import { useActivitiesStore } from "@/stores/activities";
import { useViewsStore } from "@/stores/views";

import ActivitiesTable from "@/containers/activities/ActivitiesTable.vue";
import SearchBar from "@/containers/SearchBar.vue";
import ShowTransactionsButton from "@/components/activities/ShowTransactionsButton.vue";
import AddActivityButton from "@/containers/activities/AddActivityButton.vue";
import FilterActivitiesButton from "@/containers/activities/filters/FilterActivitiesButton.vue";
import ExportActivitiesButton from "@/containers/activities/ExportActivitiesButton.vue";
import ActivitySharingMenu from "@/containers/activities/ActivitySharingMenu.vue";

const activitiesStore = useActivitiesStore();
const { activitiesData, focusedActivity } = storeToRefs(activitiesStore);

const viewsStore = useViewsStore();

const headStore = useHeadStore();

const route = useRoute();
const router = useRouter();

const viewLoaded = ref(false);

const loadRouteParams = async () => {
  await router.isReady();

  if (
    route.params.id !== null &&
    route.params.id !== undefined &&
    route.params.id !== "" &&
    route.params.id !== "reconciliation"
  ) {
    const activity = activitiesStore.getActivityByNumber(
      parseInt(route.params.id as string)
    );
    if (!activity) return;

    focusedActivity.value = activity.id;
  }

  if (route.params.id !== "reconciliation") {
    headStore.updateTitle("Activities");
  } else {
    headStore.updateTitle("Activities to reconciliate");
  }

  viewLoaded.value = true;
};

loadRouteParams();

watch(
  () => route.params.id,
  () => {
    if (route.name === "activities") loadRouteParams();
  }
);

const viewActivitiesData = computed(() => {
  if (!viewLoaded.value) return undefined;

  return activitiesData.value.filter((activityData) => {
    if (route.params.id === "reconciliation") {
      return activityData.status === "incomplete";
    }
    return true;
  });
});

const activityView = computed(() => {
  return viewsStore.getActivityView(
    route.params.id === "reconciliation"
      ? "activities-reconciliate-page"
      : "activities-page"
  );
});
</script>

<template>
  <template v-if="viewLoaded">
    <header
      class="h-14 border-b border-b-gray-200 pl-14 pr-4 lg:pl-8 flex items-center"
    >
      <div
        v-if="route.params.id === 'reconciliation'"
        class="text-sm font-medium truncate"
      >
        Activities to reconciliate
      </div>
      <div v-else class="text-sm font-medium truncate">All activities</div>

      <div class="flex-1 sm:hidden" />
      <template v-if="activityView.filters.length === 0">
        <FilterActivitiesButton
          :view-id="activityView.id"
          class="ml-4 sm:mr-2"
        />
        <ExportActivitiesButton
          class="hidden sm:block"
          :view-id="activityView.id"
          :activities-data="viewActivitiesData!"
        />
      </template>

      <div class="flex-1 hidden sm:block" />
      <SearchBar />

      <ActivitySharingMenu class="mx-3" />
      <AddActivityButton class="mr-3" />
      <ShowTransactionsButton />
    </header>

    <ActivitiesTable
      :view-id="activityView.id"
      :activities-data="viewActivitiesData!"
      grouping="period"
    />
  </template>
</template>
