import { ref } from "vue";
import { defineStore } from "pinia";

import type { Account } from "@/types/accounts";
import { AccountType } from "@/types/accounts";

import { addRecord, deleteRecord, fetchRecords } from "@/features/storage";
import { TABLES } from "@/features/indexeddb";
import { useAuthStore } from "./auth";

export const ACCOUNT_TYPES_COLOR = {
  [AccountType.BANK_ACCOUNT]: "bg-indigo-200",
  [AccountType.INVESTMENT_ACCOUNT]: "bg-orange-200",
  [AccountType.CASH]: "bg-stone-200",
  [AccountType.LIABILITIES]: "bg-sky-200",
  [AccountType.EXPENSE]: "bg-red-200",
  [AccountType.REVENUE]: "bg-green-200",
};

export const ACCOUNT_TYPES_NAME = {
  [AccountType.BANK_ACCOUNT]: "Bank accounts",
  [AccountType.INVESTMENT_ACCOUNT]: "Investments",
  [AccountType.CASH]: "Cash",
  [AccountType.LIABILITIES]: "Liabilities",
  [AccountType.EXPENSE]: "Expense",
  [AccountType.REVENUE]: "Revenue",
};

export const useAccountsStore = defineStore("accounts", () => {
  const accounts = ref<Account[]>([]);

  const load = async () => {
    await fetchRecords(accounts, TABLES.ACCOUNTS);
  };

  const getAccountById = (accountId: number): Account | undefined => {
    return accounts.value.find((a) => a.id === accountId);
  };

  const addNewAccount = async (
    name: string,
    type: AccountType
  ): Promise<Account> => {
    const { user } = useAuthStore();

    const newAccount: Account = await addRecord(
      {
        user: user!.id,
        name,
        type,
        default: false,
        startingBalance: null,
        startingCashBalance: null,
        movements: type === AccountType.BANK_ACCOUNT,
      },
      TABLES.ACCOUNTS
    );

    accounts.value.push(newAccount);
    return newAccount;
  };

  const deleteAccount = async (accountId: number) => {
    const account = getAccountById(accountId);
    if (!account) return;

    accounts.value.splice(accounts.value.indexOf(account), 1);
    await deleteRecord(accountId, TABLES.ACCOUNTS);
  };

  return {
    load,

    accounts,
    getAccountById,

    addNewAccount,
    deleteAccount,
  };
});
